<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
      <ion-toggle (ionChange)="viewChange($event)"></ion-toggle>
    </ion-buttons>
    <ion-title><span>Posts</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item *ngFor="let item of posts; let i = index" lines="full"
            [color]="!(item?.search?.length > 0) ? 'warning' :''">
            <ion-label>
              <h2><strong>{{ (i + 1)+'.- '+item?.title }}</strong></h2>
              <ion-text color="medium">
                <p>{{ item?.dateStr }}</p>
              </ion-text>
              <p> <strong>{{ item?.search | json }}</strong></p>
            </ion-label>
            <ion-buttons slot="end" *ngIf="!(item?.search?.length > 0)">
              <ion-button (click)="refreshSearch(item?.id,item?.title)">
                <ion-icon slot="icon-only" name="refresh"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>