import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EditPostComponent } from './edit-post/edit-post.component';
import { Files } from '@app/shared/models/files';
import { UploadFilesService } from '@app/shared/services/upload-files.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('MedicalForumComponent');

interface Comments {
  comment: string;
  userUid: string;
  userName: string;
  userLastName1: string;
  userLastName2: string;
  userEmail: string;
  createdAt: firebase.default.firestore.Timestamp;
  id?: string;
}
@Component({
  selector: 'app-medical-forum',
  templateUrl: './medical-forum.component.html',
  styleUrls: ['./medical-forum.component.scss']
})
export class MedicalForumComponent implements OnInit {
  featuredImage: string;
  private mainCollection: string = 'foro';
  public orderByDocs: string = 'createdAt';
  public orderByDirection: any = 'desc';
  private startAfter: any;
  private endBefore: any;
  private startAt: any;
  private perPageDocs: number = 10;
  public subscription: Subscription;
  public forward: boolean = false;
  public back: boolean = false;
  public isLoading: boolean = false;
  public searchStr: string;
  public arrayDocs: any[] = [];
  files: Files[] = [];

  constructor(
    private sanitization: DomSanitizer,
    private afs: AngularFirestore,
    private uploadFile: UploadFilesService,
    private modalController: ModalController,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.initializeApp2();
  }

  async initializeApp2(direction?: string) {
    this.isLoading = true;
    this.searchStr = undefined;
    try {
      const resp = await this.getDocs(direction);
      this.arrayDocs = [];
      this.arrayDocs = this.arrayDocs.concat(resp);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  private getDocs(direction?: string): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let collection: AngularFirestoreCollection<any>;
        let collRef: CollectionReference = this.afs.collection(this.mainCollection).ref;
        let query: Query;
        query = collRef.orderBy(this.orderByDocs, this.orderByDirection);
        if (this.startAfter && direction === 'forward') {
          query = query.startAfter(this.startAfter);
        } else if (this.startAt && this.endBefore && direction === 'back') {
          query = query.startAt(this.startAt).endBefore(this.endBefore);
        }
        collection = this.afs.collection(this.mainCollection, () => query.limit(this.perPageDocs));
        this.subscription = collection.snapshotChanges().subscribe(
          async snap => {
            this.startAfter = snap.length > 0 ? snap[snap.length - 1].payload.doc : undefined;
            this.endBefore = snap.length > 0 ? snap[0].payload.doc : undefined;
            if (this.startAfter) {
              this.forward = !(
                await this.afs
                  .collection(this.mainCollection)
                  .ref.orderBy(this.orderByDocs, this.orderByDirection)
                  .startAfter(this.startAfter)
                  .limit(this.perPageDocs)
                  .get()
              ).empty;
            } else {
              this.forward = false;
            }
            let back: any = {};
            if (this.endBefore) {
              back = await this.afs
                .collection(this.mainCollection)
                .ref.orderBy(this.orderByDocs, this.orderByDirection === 'asc' ? 'desc' : 'asc')
                .startAfter(this.endBefore)
                .limit(this.perPageDocs)
                .get();
            } else {
              back.empty = true;
            }
            this.back = !back.empty;
            if (this.back == true) {
              this.startAt = back.docs[back.docs.length - 1];
            }
            let arrayDocs: any[] = [];
            for (let element of snap) {
              const id: string = element.payload.doc.id;
              let data: any = element.payload.doc.data();
              data['id'] = id;
              data['featureImage'] = await this.getFiles(data['id']);
              if (data.featureImage) {
                data['image'] = data['featureImage'];
                data['imageBG'] = this.sanitization.bypassSecurityTrustUrl(data['image']);
              }
              data['comments'] = await this.getComments(data['id']);
              if (!data.defaultLikes) {
                data.defaultLikes = 0;
              }
              arrayDocs.push({ id, ...data });
            }
            resolve(arrayDocs);
          },
          error => {
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  async getFiles(_idPost: string) {
    const filesData: any = await this.afs
      .collection(`foro/${_idPost}/files`)
      .valueChanges()
      .pipe(take(1))
      .toPromise();

    if (filesData.length === 0) {
      return null;
      // return (this.featuredImage = './assets/img/no-img0.jpg');
    } else {
      for (const i of filesData) {
        if (i.type.split('/')[0] === 'image') {
          return (this.featuredImage = i.file);
        } else {
          return null;
          // return (this.featuredImage = './assets/img/no-img0.jpg');
        }
      }
    }
    return this.featuredImage;
  }

  async getComments(_idPost: string) {
    const commentsData = await this.afs
      .collection<Comments>(`foro/${_idPost}/comments`)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
    return commentsData.length;
  }

  async paginate(direction?: string) {
    this.isLoading = true;
    this.searchStr = undefined;
    try {
      const resp = await this.getDocs(direction);
      this.arrayDocs = this.arrayDocs.concat(resp);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async editPost(id: any) {
    const callback = () => {
      location.reload();
    };
    const modal = await this.modalController.create({
      component: EditPostComponent,
      cssClass: 'modal-class-foro',
      componentProps: { id, callback },
      backdropDismiss: false
    });
    await modal.present();
  }

  async delete(_idPost: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¡Atención!',
      message: '¿Deseas eliminar esta publicación?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Si',
          handler: async () => {
            if (this.files.length === 0) {
              this.afs
                .collection('foro')
                .doc(_idPost)
                .delete()
                .then(async () => {
                  const alertSuccess = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Información',
                    message: 'La publicación ha sido eliminada exitosamente',
                    buttons: [
                      {
                        text: 'Ok',
                        handler: () => {
                          location.reload();
                        }
                      }
                    ]
                  });
                  await alertSuccess.present();
                });
            } else {
              for (let i = 0; i < this.files.length; i++) {
                await this.deleteFile(this.files[i], _idPost);
                if (i === this.files.length - 1) {
                  this.afs
                    .collection('foro')
                    .doc(_idPost)
                    .delete()
                    .then(async () => {
                      const alertSuccess = await this.alertController.create({
                        cssClass: 'my-custom-class',
                        header: 'Información',
                        message: 'La publicación ha sido eliminada exitosamente',
                        buttons: [
                          {
                            text: 'Ok',
                            handler: () => {
                              location.reload();
                            }
                          }
                        ]
                      });
                      await alertSuccess.present();
                    });
                }
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async deleteFile(_item: Files, _idPost: string) {
    await this.uploadFile.deleteFile('foro', _idPost, 'Files', _item.name);
    this.afs
      .collection(`foro/${_idPost}/files`)
      .doc(_item.id)
      .delete();
  }

  async hidePost(_idPost: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¡Atención!',
      message: '¿Deseas ocultar esta publicación?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Si',
          handler: async () => {
            this.afs
              .collection('foro')
              .doc(_idPost)
              .update({
                status: 'inactive'
              })
              .then(async () => {
                const alertSuccess = await this.alertController.create({
                  cssClass: 'my-custom-class',
                  header: 'Información',
                  message: 'La publicación se ha ocultado exitosamente',
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        location.reload();
                      }
                    }
                  ]
                });
                await alertSuccess.present();
              });
          }
        }
      ]
    });
    await alert.present();
  }

  async showPost(_idPost: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¡Atención!',
      message: '¿Deseas mostrar esta publicación?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Si',
          handler: async () => {
            this.afs
              .collection('foro')
              .doc(_idPost)
              .update({
                status: 'active'
              })
              .then(async () => {
                const alertSuccess = await this.alertController.create({
                  cssClass: 'my-custom-class',
                  header: 'Información',
                  message: 'La publicación se ha mostrado exitosamente',
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        location.reload();
                      }
                    }
                  ]
                });
                await alertSuccess.present();
              });
          }
        }
      ]
    });
    await alert.present();
  }
}
