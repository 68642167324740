<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Importar posts desde WordPress</ion-title>
  </ion-toolbar>
  <ion-progress-bar *ngIf="isLoading === true" type="indeterminate"></ion-progress-bar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <h2>Importar</h2>
        <ion-button (click)="initTags()" expand="block" [disabled]="isLoading === true">
          Tags
        </ion-button>

        <ion-button (click)="initCategories()" expand="block" [disabled]="isLoading === true">
          Categorías
        </ion-button>

        <ion-button (click)="initEspecialties()" expand="block" [disabled]="isLoading === true">
          Especialidades
        </ion-button>

        <ion-button (click)="initBanners()" expand="block" [disabled]="isLoading === true">
          Banners
        </ion-button>

        <ion-button (click)="initCourseOrCongress()" expand="block" [disabled]="isLoading === true">
          Cursos y Congresos
        </ion-button>

        <ion-button (click)="initVideos()" expand="block" [disabled]="isLoading === true">
          Videos
        </ion-button>

        <ion-button (click)="initPosts()" expand="block" [disabled]="isLoading === true">
          Posts
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <hr />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <h2>Eliminar (Firebase)</h2>
        <ion-button (click)="initBannersFire()" expand="block" [disabled]="isLoading === true">
          Banners
        </ion-button>

        <ion-button (click)="initPostsFire()" expand="block" [disabled]="isLoading === true">
          Posts
        </ion-button>

        <ion-button (click)="initTagsFire()" expand="block" [disabled]="isLoading === true">
          Tags
        </ion-button>

        <ion-button (click)="initCategoriesFire()" expand="block" [disabled]="isLoading === true">
          Categorías
        </ion-button>

        <ion-button (click)="initCategoriesVideosFire()" expand="block" [disabled]="isLoading === true">
          Categorías Videos
        </ion-button>

        <ion-button (click)="initEspecialtiesFire()" expand="block" [disabled]="isLoading === true">
          Especialidades
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
