<ion-app>
  <ion-header>
    <ion-toolbar color="secondary">
      <ion-title>Buscar Tags</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="modalC.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="primary">
      <ion-searchbar
        placeholder="Buscar un Tag"
        inputmode="decimal"
        type="decimal"
        [(ngModel)]="search"
        (keyup.enter)="buscar()"
        [debounce]="0"
        showCancelButton="always"
        mode="md"
      ></ion-searchbar>
      <ion-buttons slot="end">
        <ion-button (click)="buscar()">
          <ion-icon slot="icon-only" name="search"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="read-content">
      <ion-item *ngFor="let tag of searchTags" color="secondary">
        <ion-label
          ><span
            ><b>{{ tag.count }} </b></span
          >{{ tag.name }}</ion-label
        >
        <ion-buttons slot="end">
          <ion-button (click)="tags.push(tag)" color="success">
            <ion-icon slot="icon-only" name="add"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </div>
  </ion-content>

  <ion-footer>
    <div style="height: 130px;">
      <ion-app>
        <ion-content color="light">
          <ion-chip color="primary" *ngFor="let tag of tags" mode="md">
            <ion-label>{{ tag.name }}</ion-label>
            <ion-icon name="close-circle" (click)="tags.splice(tags.indexOf(tag), 1)"></ion-icon>
          </ion-chip>
        </ion-content>
        <ion-footer>
          <ion-button (click)="generateData()" expand="full" fill="outline">
            Agregar Tags seleccionados
          </ion-button>
        </ion-footer>
      </ion-app>
    </div>
  </ion-footer>
</ion-app>
