import { Component, OnInit } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { APP_URL } from '../../environments/environment';
import { Logger } from '@app/core';
import { AlertController, LoadingController } from '@ionic/angular';
const log = new Logger('Active Rep');

@Component({
  selector: 'app-activate-rep',
  templateUrl: './activate-rep.component.html',
  styleUrls: ['./activate-rep.component.scss']
})
export class ActivateRepComponent implements OnInit {
  public arrayDocs: any[] = [];
  public orderBy: string = 'nameStr';
  public orderByDirection: any = 'asc';
  public forward: boolean = false;
  public searchMemberType: string;
  public isLoading: boolean = false;
  private mainCollection: string = 'users';
  private startAfter: any;
  private searchStr: string;
  private perPage: number = 35;

  constructor(
    private afs: AngularFirestore,
    private firebase: FirebaseService,
    private alertC: AlertController,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {
    this.initData();
  }

  async initData(next?: boolean) {
    try {
      let collRef: CollectionReference = this.afs.collection(this.mainCollection).ref;
      let query: Query;
      query = collRef.where('status', '==', 'activation-pending').orderBy(this.orderBy, this.orderByDirection);
      query = query.where('type', '==', 'representante-medico');
      if (this.searchStr) {
        query = query.where('search', 'array-contains', this.searchStr);
      }
      if (this.startAfter && next === true) {
        query = query.startAfter(this.startAfter);
      }
      const snap = await query.limit(this.perPage).get();
      this.startAfter = snap.empty == false ? snap.docs[snap.docs.length - 1] : undefined;
      if (this.startAfter) {
        query = collRef.where('status', '==', 'activation-pending').orderBy(this.orderBy, this.orderByDirection);
        query = query.where('type', '==', 'representante-medico');
        if (this.searchStr) {
          query = query.where('search', 'array-contains', this.searchStr);
        }
        if (this.startAfter) {
          query = query.startAfter(this.startAfter);
        }
        const snap2 = await query.limit(this.perPage).get();
        this.forward = !snap2.empty;
      } else {
        this.forward = false;
      }
      let myArrayData: any[] = [];
      myArrayData = snap.docs.map(element => {
        const data: any = element.data();
        const id: string = element.id;
        if (data.type === 'medico') {
          data.metaData = this.firebase.getDoctorData(id);
        } else {
          data.metaData = this.firebase.getRepData(id);
        }
        data.isFriend = this.firebase.related(id);
        return { id, ...data };
      });
      if (!(next === true)) {
        this.arrayDocs = [];
      }
      myArrayData = myArrayData.map(element => {
        let data = element;
        var str: string = String(
          element && element.avatar && element.avatar.list && element.avatar.list.url ? element.avatar.list.url : ''
        );
        var n = str.search('www.gravatar.com');
        if (n > -1) {
          element.avatar = undefined;
        }
        return data;
      });
      this.arrayDocs = this.arrayDocs.concat(myArrayData);
    } catch (error) {
      log.error(error);
    }
  }

  async nextPage() {
    this.searchStr = '';
    this.isLoading = true;
    try {
      await this.initData(true);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async doSearch(ev: any) {
    try {
      this.search(ev);
    } catch (error) {
      log.error(error);
    }
  }

  async search(ev: any) {
    this.isLoading = true;
    const searchStr: string = String(ev.target.value).toLocaleLowerCase();
    if (searchStr.length >= 3) {
      try {
        this.searchStr = searchStr;
        await this.initData();
      } catch (error) {
        log.error(error);
      }
    }
    this.isLoading = false;
  }

  private async activateRep(uid: string): Promise<void> {
    const loading = await this.loadingController.create({
      message: 'Por favor espera, estamos cargando tu archivo'
    });
    loading.present();
    try {
      const resp = await this.afs
        .collection('users')
        .doc(uid)
        .ref.get();
      const data:any = resp.data();
      await this.afs
        .collection('representante-meta')
        .doc(uid)
        .update({ status: 'active' });
      await this.afs
        .collection('users')
        .doc(uid)
        .update({ status: 'active' });
      await this.clearSearch();
      this.successAlert();
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
    return;
  }

  async clearSearch() {
    this.searchStr = '';
    this.arrayDocs = [];
    await this.initData();
  }

  async activateRepAlert(uid: string): Promise<void> {
    const alert = await this.alertC.create({
      header: 'Activar representante médico',
      message: `¿Seguro que desea activar al representante médico?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Activar',
          handler: () => {
            this.activateRep(uid);
          }
        }
      ]
    });
    return await alert.present();
  }

  async successAlert(): Promise<void> {
    const alert = await this.alertC.create({
      header: '¡Correcto!',
      message: `Representante activado correctamente`,
      buttons: [
        {
          text: 'Ok'
        }
      ]
    });
    return await alert.present();
  }
}
