<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center"> <ion-icon name="options"></ion-icon> Filtrar por empresa </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      class="ion-margin-top"
      type="text"
      debounce="1000"
      (ionChange)="doSearch($event)"
      (ionCancel)="clearSearch()"
      (ionClear)="clearSearch()"
      enterkeyhint="send"
      placeholder="Buscar"
      animated
    >
    </ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <ion-grid fixed>
    <ion-row *ngIf="arrayDocs && arrayDocs[0]">
      <ion-col size="12">
        <div *ngFor="let item of arrayDocs">
          <ion-card (click)="selectItem(item)">
            <ion-item [color]="item.isChecked == true ? 'tertiary' : 'secondary'" lines="none" detail="true">
              <ion-label>
                <h2>
                  {{ item.label }}
                </h2>
              </ion-label>
              <ion-badge slot="end" *ngIf="item?.count as count">{{ count }}</ion-badge>
            </ion-item>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-button fill="outline" expand="block" color="danger" (click)="clearFilter()">
          <ion-icon name="trash" slot="start"></ion-icon>
          Borrar filtro
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
