<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center">
      Envíos anteriores
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item button *ngFor="let item of items">
            <ion-icon slot="start" name="notifications"></ion-icon>
            <ion-label>
              <h3>{{ item?.settings?.title }}</h3>
              <p>{{ item?.settings?.body }}</p>
              <p>{{ item?.dateString }}</p>
              <ion-badge color="warning" *ngIf="item?.status === 'pending'">Pendiente</ion-badge>
              <ion-badge color="tertiary" *ngIf="item?.status === 'complete'">Completado</ion-badge>
              <ion-badge color="primary" *ngIf="item?.status === 'in_progress'">En progreso</ion-badge>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="alertConfirm(item?.id)">
                <ion-icon slot="icon-only" name="refresh"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
