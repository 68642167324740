<ion-item lines="none" [color]="color">
  <div *ngIf="item.isFB !== true">
    <ion-avatar slot="start">
      <img [src]="item?.avatar ? item.avatar : 'assets/img/default-avatar.svg'" />
    </ion-avatar>
    <ion-label>
      <h2>
        <span *ngIf="index">{{ index }} .- </span>
        <strong>{{ item.name }}</strong>
      </h2>
      <div *ngIf="item.type === 'medico'">
        <p *ngIf="item && item.especialidad1 as data"><ion-icon name="school"></ion-icon> {{ data }}</p>
        <p *ngIf="item && item.especialidad2 as data"><ion-icon name="school"></ion-icon> {{ data }}</p>
        <p *ngIf="item && item.estado1 && item.ciudad1">
          <b *ngIf="item.estado1 === 'Ciudad de México'">
            <ion-icon name="pin"></ion-icon> {{ item.estado1 + ', ' + item.colonia1 }}
          </b>
          <b *ngIf="item.estado1 !== 'Ciudad de México'">
            <ion-icon name="pin"></ion-icon> {{ item.estado1 + ', ' + item.ciudad1 }}
          </b>
        </p>
        <p *ngIf="item && item.estado2 && item.ciudad2">
          <b *ngIf="item.estado2 === 'Ciudad de México'">
            <ion-icon name="pin"></ion-icon> {{ item.estado2 + ', ' + item.colonia2 }}
          </b>
          <b *ngIf="item.estado2 !== 'Ciudad de México'">
            <ion-icon name="pin"></ion-icon> {{ item.estado2 + ', ' + item.ciudad2 }}
          </b>
        </p>
      </div>
      <div *ngIf="item.type === 'representante-medico'">
        <p *ngIf="item && item.empresa as data"><ion-icon name="briefcase"></ion-icon> {{ data }}</p>
        <p *ngIf="item && item.giro as data">
          <b> <ion-icon name="information-circle-outline"></ion-icon> {{ data }} </b>
        </p>
      </div>
    </ion-label>
  </div>
  <div *ngIf="item.isFB === true">
    <ion-avatar slot="start">
      <img [src]="item?.avatar ? item.avatar : 'assets/img/default-avatar.svg'" />
    </ion-avatar>
    <ion-label>
      <h2>
        <span *ngIf="index"> {{ index }} .- </span>
        <strong>{{ item.name }} {{ item.lastName1 }} {{ item.lastName2 }}</strong>
      </h2>
      <div *ngIf="item.type === 'medico'">
        <p *ngIf="item && item.metaData && item.metaData.specialty1 && item.metaData.specialty1.name as data">
          <ion-icon name="school"></ion-icon> {{ data }}
        </p>
        <p *ngIf="item && item.metaData && item.metaData.specialty2 && item.metaData.specialty2.name as data">
          <ion-icon name="school"></ion-icon> {{ data }}
        </p>
        <p *ngIf="item && item.metaData && item.metaData.address1 && item.metaData.address1.city">
          <b *ngIf="item.metaData.address1.state === 'Ciudad de México'">
            <ion-icon name="pin"></ion-icon> {{ item.metaData.address1.state + ', ' + item.metaData.address1.colony }}
          </b>
          <b *ngIf="item.metaData.address1.state !== 'Ciudad de México'">
            <ion-icon name="pin"></ion-icon> {{ item.metaData.address1.state + ', ' + item.metaData.address1.city }}
          </b>
        </p>
        <p
          *ngIf="
            item &&
            item.metaData &&
            item.metaData.address2 &&
            item.metaData.address2.state &&
            item.metaData.address2.city
          "
        >
          <b *ngIf="item.metaData.address2.state === 'Ciudad de México'">
            <ion-icon name="pin"></ion-icon> {{ item.metaData.address2.state + ', ' + item.metaData.address2.colony }}
          </b>
          <b *ngIf="item.metaData.address2.state !== 'Ciudad de México'">
            <ion-icon name="pin"></ion-icon> {{ item.metaData.address2.state + ', ' + item.metaData.address2.city }}
          </b>
        </p>
      </div>
      <div *ngIf="item.type === 'representante-medico'">
        <p *ngIf="item && item.metaData && item.metaData.company && item.metaData.company.name as data">
          <ion-icon name="briefcase"></ion-icon> {{ data }}
        </p>
        <p *ngIf="item && item.metaData && item.metaData.company && item.metaData.company.businessType as data">
          <b> <ion-icon name="information-circle-outline"></ion-icon> {{ data }} </b>
        </p>
      </div>
    </ion-label>
  </div>
</ion-item>
