import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ClickMeetingRoutingModule } from './click-meeting-routing';
import { ClickMeetingComponent } from './click-meeting.component';
import { ConferenceComponent } from './conference/conference.component';
import { CertificatesComponent } from './certificates/certificates.component';

@NgModule({
  imports: [CommonModule, IonicModule, ClickMeetingRoutingModule, ReactiveFormsModule, FormsModule],
  declarations: [ClickMeetingComponent, ConferenceComponent, CertificatesComponent]
})
export class ClickMeetingModule { }
