import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WP_JSON_URL } from '../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { Logger } from '@app/core';
const log = new Logger('Import products');

@Component({
  selector: 'app-import-products',
  templateUrl: './import-products.component.html',
  styleUrls: ['./import-products.component.scss']
})
export class ImportProductsComponent implements OnInit {
  public page = 1;
  public pages: number[] = [];
  private per_page = 100;
  public products: any[] = [];
  xWpTotal: number;
  xWpTotalPages: number;
  public isLoading = false;

  constructor(private http: HttpClient, private afs: AngularFirestore, private utilities: UtilitiesService) { }

  ngOnInit() {
    this.init();
  }

  async init(): Promise<void> {
    this.isLoading = true;
    try {
      await this.getData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  goToPage(page: number) {
    this.page = page;
    this.init();
  }

  async getData(): Promise<void> {
    try {
      const response = await this.http
        .get(`${WP_JSON_URL}/wp-json/wp/v2/medicines?per_page=${this.per_page}&page=${this.page}`, {
          observe: 'response'
        })
        .toPromise();
      this.xWpTotal = Number(response.headers.get('x-wp-total'));
      this.xWpTotalPages = Number(response.headers.get('x-wp-totalpages'));
      this.pages = [];
      for (let i = 0; i < this.xWpTotalPages; i++) {
        this.pages.push(+i + 1);
      }
      const body: any = response.body;
      let tempArray = Array.from(body).map((element: any) => {
        let data: any = {
          idProd: element.id,
          wp_id: element.author,
          title: element.marca,
          marca: element.marca,
          producto_sustancia_activa_1: element.producto_sustancia_activa_1,
          producto_sustancia_activa_2: element.producto_sustancia_activa_2,
          linea_terapeutica_1: element.linea_terapeutica_1,
          linea_terapeutica_2: element.linea_terapeutica_2,
          presentacion_1: element.presentacion_1,
          presentacion_2: element.presentacion_2,
          se_cuenta_con_muestra: element.se_cuenta_con_muestra,
          status: 'active'
        };
        if (element.featured_media && element.featured_media > 0) {
          data.featured_media = element.featured_media;
        }
        data.nameStr = '';
        data.search = [];
        return data;
      });
      for (let i = 0; i < tempArray.length; i++) {
        let user;
        try {
          user = await this.afs
            .collection('users')
            .ref.where('wp_id', '==', tempArray[i].wp_id)
            .get();
        } catch (error) {
          log.error(error);
        }
        if (user.docs.length > 0) {
          const dataUser: any = user.docs[0].data();
          if (
            dataUser.type === 'representante-medico' &&
            dataUser['filter-meta-data'] &&
            dataUser['filter-meta-data'][0]
          ) {
            const companyId = dataUser['filter-meta-data'][0];
            const ref = this.afs.collection('products').doc(companyId).ref;
            tempArray[i].uid = user.docs[0].id;
            tempArray[i].company = ref;
            if (tempArray[i].featured_media) {
              try {
                const media: any = await this.http
                  .get(`${WP_JSON_URL}/wp-json/wp/v2/media/${tempArray[i].featured_media}`, { observe: 'response' })
                  .toPromise();
                if (media && media.body && media.body['source_url']) {
                  tempArray[i].image = media.body['source_url'];
                }
              } catch (error) {
                log.error(error);
              }
            }
            const attachment = await this.http
              .get(`${WP_JSON_URL}/wp-json/wp/v2/media?parent=${tempArray[i].idProd}`, { observe: 'response' })
              .toPromise();
            if (attachment && attachment.body) {
              const array: any = attachment.body;
              const files = Array.from(array)
                .filter((element: any) => {
                  if (element && element.source_url) {
                    return element;
                  }
                })
                .map((e: any) => e.source_url);
              tempArray[i].documents = files;
            }
          }
        }
      }
      this.products = tempArray;
    } catch (error) {
      log.error(error);
    }
  }

  async imporProducts() {
    this.isLoading = true;
    try {
      const imports = this.products.filter(e => {
        if (e.uid) {
          return e;
        }
      });
      let promises: Promise<any>[] = [];
      for (let i = 0; i < imports.length; i++) {
        const response = await this.afs
          .collection('products')
          .ref.where('idProd', '==', imports[i].idProd)
          .get();
        if (response.empty === true) {
          promises.push(this.afs.collection('products').add(imports[i]));
        }
      }
      await Promise.all(promises);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async delete() {
    this.isLoading = true;
    try {
      const imports = this.products.filter(e => {
        if (e.uid) {
          return e;
        }
      });
      let promises: Promise<any>[] = [];
      for (let i = 0; i < imports.length; i++) {
        const response = await this.afs
          .collection('products')
          .ref.where('idProd', '==', imports[i].idProd)
          .get();
        if (!(response.empty === true)) {
          promises.push(
            this.afs
              .collection('products')
              .doc(response.docs[0].id)
              .delete()
          );
        }
      }
      await Promise.all(promises);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }
}
