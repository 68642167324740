import { FormControl } from '@angular/forms';

export class ValidateUrl {
  static isValid(control: FormControl) {
    const URL_REGEXP = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})*$/;
    let isValid: boolean;
    if (URL_REGEXP.test(control.value) || control.value === '') {
      isValid = true;
    }
    return isValid === true ? null : { noValidUrl: true };
  }
}
