<ion-grid fixed class="ion-no-padding">
  <ion-row>
    <ion-col size="12">
      <ion-card color="secondary" (click)="add(3)">
        <ion-item color="tertiary" lines="none" detail="true">
          <ion-icon slot="start" name="calendar"></ion-icon>
          <ion-label>
            <h2>
              Agendar
            </h2>
          </ion-label>
        </ion-item>
      </ion-card>
      <ion-card color="secondary" (click)="close()" href="tel:{{ phone }}">
        <ion-item color="tertiary" lines="none" detail="true">
          <ion-icon slot="start" name="call"></ion-icon>
          <ion-label>
            <h2>
              Llamar ahora
            </h2>
          </ion-label>
        </ion-item>
      </ion-card>
      <ion-card color="danger" (click)="close()">
        <ion-item color="danger" lines="none">
          <ion-icon slot="start" name="close"></ion-icon>
          <ion-label>
            <h2>
              Cancelar
            </h2>
          </ion-label>
        </ion-item>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
