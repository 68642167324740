import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { SearchComponent } from '../../search/search.component';
import { Logger } from '../../core/logger.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { SearchTagsComponent } from '../search-tags/search-tags.component';
import { SearchProductComponent } from '../search-product/search-product.component';
const log = new Logger('New Advertising');

@Component({
  selector: 'app-new-advertising',
  templateUrl: './new-advertising.component.html',
  styleUrls: ['./new-advertising.component.scss']
})
export class NewAdvertisingComponent implements OnInit {
  @ViewChild('inputImg', { static: true }) inputImg: HTMLInputElement;

  @Input() accion: string;
  @Input() advertising: any;

  img: string | ArrayBuffer = '/assets/img/add-image.png';

  repre: any;
  product: any;

  gettingData: boolean = false;

  addCheck: any = {
    valid: false,
    tags: {
      ok: false,
      message: null as string
    },
    content: {
      ok: false,
      message: null as string
    },
    analyticsLabel: {
      ok: false,
      message: null as string
    },
    img: {
      ok: false,
      message: null as string
    },
    repreId: {
      ok: false,
      message: null as string
    },
    link: {
      ok: false,
      message: null as string
    },
    productId: {
      ok: false,
      message: null as string
    }
  };

  constructor(
    public modalC: ModalController,
    private firestore: AngularFirestore,
    private aStorage: AngularFireStorage,
    private loadingC: LoadingController
  ) { }

  async getData() {
    this.advertising = { ...this.advertising };
    if (this.advertising) {
      this.gettingData = true;
      if (!this.advertising.analyticsLabel) {
        this.advertising.analyticsLabel = '';
      }
      if (this.advertising.repreId) {
        this.repre = await new Promise(resolve => {
          const sub = this.firestore
            .doc(`users/${this.advertising.repreId}`)
            .valueChanges()
            .subscribe((value: any) => {
              resolve(value);
              sub.unsubscribe();
            });
        });
      }
      if (this.advertising.productId) {
        this.product = await new Promise(resolve => {
          const sub = this.firestore
            .doc(`products/${this.advertising.productId}`)
            .valueChanges()
            .subscribe((value: any) => {
              resolve(value);
              sub.unsubscribe();
            });
        });
      }
      setTimeout(() => {
        this.gettingData = false;
        this.fullCheck();
      }, 0);
    }
  }

  async ngOnInit() {
    if (this.advertising) {
      this.img = this.advertising.img;
      this.getData();
    } else {
      this.advertising = {
        type: 'banner',
        tags: [],
        theTags: [],
        content: '',
        analyticsLabel: '',
        img: null,
        repreId: null,
        link: null,
        id: null,
        productId: null,
        relType: 'link'
      };
    }
  }

  loadImg(files: any) {
    if (files.length !== 0) {
      this.advertising.img = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.img = reader.result;
      };
      this.check('img');
    }
  }

  selectionChange(val?: any) {
    log.debug(val);
    if (!this.gettingData) {
      this.advertising.link = null;
      this.advertising.repreId = null;
      this.advertising.productId = null;
      this.product = null;
      this.repre = null;
    }
    this.checkSelection();
  }

  async selectUsersFunction() {
    try {
      const modal = await this.modalC.create({
        component: SearchComponent,
        componentProps: {
          viewType: 'modal',
          searchMemberType: 'representante-medico'
        }
      });
      modal.onDidDismiss().then(users => {
        if (users.data && users.data.users) {
          this.repre = users.data.users[0];
          this.advertising.repreId = users.data.users[0].id;
          this.check('repreId');
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  async getTags() {
    const callback = (data: any) => {
      this.advertising.tags = data.tags;
      this.advertising.theTags = data.theTags;
      this.check('tags');
    };

    try {
      const modal = await this.modalC.create({
        component: SearchTagsComponent,
        componentProps: {
          callback,
          tags: this.advertising.theTags
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  async getProducts() {
    const callback = (data: any) => {
      this.advertising.productId = data.productId;
      this.advertising.repreId = data.repreId;
      this.getData();
    };

    const modal = await this.modalC.create({
      component: SearchProductComponent,
      componentProps: { callback, product: this.product }
    });
    await modal.present();
  }

  async submitAdvertising() {
    // Creating Loading
    const loading = await this.loadingC.create({ message: `${this.accion} publicidad` });
    loading.present();

    try {
      // Generating the id
      let id = await this.firestore.createId();
      if (this.advertising.id) {
        id = this.advertising.id;
      }

      // Making the upload
      let upload = { state: 'success' };
      if (typeof this.advertising.img !== 'string') {
        upload = await this.aStorage.upload(`advertising/${id}`, this.advertising.img);
      }

      // Check for set values on firestore
      if (upload.state === 'success') {
        this.advertising.id = id;
        this.advertising.img = await new Promise(resolve => {
          this.aStorage
            .ref(`advertising/${id}`)
            .getDownloadURL()
            .subscribe(url => {
              resolve(url);
            });
        });

        if (!this.advertising.creation_date) {
          this.advertising.creation_date = new Date();
        }

        this.advertising.update_date = new Date();

        this.firestore
          .doc(`advertising/${id}`)
          .set(this.advertising)
          .then(() => {
            loading.message = `Se logro ${this.accion} la publicidad`;
            setTimeout(() => {
              loading.dismiss();
              this.modalC.dismiss();
            }, 1500);
          });
      }
    } catch (error) {
      log.error(error);
    }
  }

  check(key: string) {
    if (key === 'content') {
      if (this.advertising.content.length > 90) {
        this.addCheck.content.ok = false;
        this.addCheck.content.message = 'Máximo 90 caracteres';
      } else {
        this.addCheck.content.ok = true;
        this.addCheck.content.message = null;
      }
    } else if (key === 'analyticsLabel') {
      if (this.advertising.analyticsLabel.length > 90) {
        this.addCheck.analyticsLabel.ok = false;
        this.addCheck.analyticsLabel.message = 'Máximo 90 caracteres';
      } else {
        this.addCheck.analyticsLabel.ok = true;
        this.addCheck.analyticsLabel.message = null;
      }
    } else if (key === 'img') {
      if (!this.advertising.img) {
        this.addCheck.img.ok = false;
        this.addCheck.img.message = 'Se requiere de una imagen';
      } else {
        this.addCheck.img.ok = true;
        this.addCheck.img.message = null;
      }
    } else if (key === 'productId') {
      if (this.advertising.relType === 'product') {
        if (!this.advertising.productId) {
          this.addCheck.productId.ok = false;
          this.addCheck.productId.message = 'Se requiere de un producto';
        } else {
          this.addCheck.productId.ok = true;
          this.addCheck.productId.message = null;
        }
      }
    } else if (key === 'repreId') {
      if (this.advertising.relType === 'repre') {
        if (!this.advertising.repreId) {
          this.addCheck.repreId.ok = false;
          this.addCheck.repreId.message = 'Se requiere de un producto';
        } else {
          this.addCheck.repreId.ok = true;
          this.addCheck.repreId.message = null;
        }
      }
    } else if (key === 'link') {
      if (this.advertising.relType === 'link') {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
          'i'
        ); // fragment locator
        if (!pattern.test(this.advertising.link)) {
          this.addCheck.link.ok = false;
          this.addCheck.link.message = 'Link invalido';
        } else {
          this.addCheck.link.ok = true;
          this.addCheck.link.message = null;
        }
      }
    } else if (key === 'tags') {
      if (this.advertising.tags.length === 0) {
        this.addCheck.tags.ok = false;
        this.addCheck.tags.message = 'Se requiere seleccionar tags';
      } else {
        this.addCheck.tags.ok = true;
        this.addCheck.tags.message = null;
      }
    }
    this.isValid();
  }

  checkSelection() {
    this.addCheck.repreId.message = null;
    this.addCheck.productId.message = null;
    this.addCheck.link.message = null;
    if (this.advertising.relType === 'link') {
      this.addCheck.repreId.ok = true;
      this.addCheck.productId.ok = true;
    } else if (this.advertising.relType === 'repre') {
      this.addCheck.productId.ok = true;
      this.addCheck.link.ok = true;
    } else if (this.advertising.relType === 'product') {
      this.addCheck.repreId.ok = true;
      this.addCheck.link.ok = true;
    }
  }

  fullCheck() {
    this.checkSelection();
    for (const key in this.addCheck) {
      this.check(key);
    }
  }

  async isValid() {
    let valid = true;
    for (const key in this.addCheck) {
      if (valid && key !== 'valid') {
        valid = this.addCheck[key].ok;
      }
    }
    this.addCheck.valid = valid;
  }
}
