import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ChatGroupComponent } from './chat-group/chat-group.component';
import { CreateChatComponent } from './create-chat/create-chat.component';
import { TinyEditorModule } from '../shared/components/tiny-editor/tiny-editor.module';

@NgModule({
 // schemas: [ChatGroupComponent],
  imports: [
    CommonModule,
    IonicModule,
    ChatRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TinyEditorModule
  ],
  declarations: [ChatComponent, ChatGroupComponent, CreateChatComponent]
})
export class ChatModule {}
