<ion-list>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading === true" color="medium"></ion-progress-bar>
  <ion-list-header>
    <ion-label>
      <strong>Opciones de usuario</strong>
    </ion-label>
  </ion-list-header>
  <ion-item
    button
    (click)="sendEmailVerification()"
    lines="none"
    [disabled]="isLoading === true"
    *ngIf="user?.emailVerified !== true"
  >
    <ion-label>Enviar verificación de email</ion-label>
  </ion-item>
  <ion-item button (click)="sendResetPassword()" lines="none" [disabled]="isLoading === true">
    <ion-label>Enviar cambio de contraseña</ion-label>
  </ion-item>
  <ion-item button (click)="activateAlert(user?.disabled)" lines="none" [disabled]="isLoading === true">
    <ion-label>{{ user?.disabled === true ? 'Activar' : 'Desactivar' }}</ion-label>
  </ion-item>
  <ion-item button (click)="deleteAlert()" lines="none" [disabled]="isLoading === true">
    <ion-label color="danger">Eliminar</ion-label>
  </ion-item>
</ion-list>
