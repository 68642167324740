import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidateUrl } from '../shared/validators/url.validator';
import { ValidateAnalyticLabel } from '../shared/validators/anayliticsLabel.validator';
import { ValidateDomain } from '../shared/validators/domain.validator';
import { APP_DOMAINS } from '../../environments/environment';
import { AngularFirestore, DocumentData, QuerySnapshot } from '@angular/fire/compat/firestore';
import * as specialities from '../../assets/data/specialities.json';
import { PushNotificationsService } from './push-notifications.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Logger } from '../core/logger.service';
const log = new Logger('PushNotificationsComponent');

@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.scss']
})
export class PushNotificationsComponent implements OnInit {
  public myForm: FormGroup;
  public isLoading: boolean = false;
  public specialties: any[] = [];
  public locations: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private notifications: PushNotificationsService,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.initEspecialidades();
    this.initLocations();
  }

  private createForm() {
    this.myForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(30)]],
      message: ['', [Validators.required, Validators.maxLength(180)]],
      link: ['', [Validators.required, ValidateUrl.isValid, ValidateDomain.isValid]],
      analytics_label: ['', [ValidateAnalyticLabel.isValid, Validators.maxLength(30)]]
    });
  }

  async send(): Promise<void> {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    let url = String(this.myForm.get('link').value);
    for (const domain of APP_DOMAINS) {
      url = url.replace(domain, '');
    }
    try {
      await this.notifications.sendPushNotification({
        title: String(this.myForm.get('title').value),
        body: String(this.myForm.get('message').value),
        link: url,
        refLink: String(this.myForm.get('link').value),
        token: undefined,
        topic: undefined,
        condition: "'allMovilDevices' in topics || 'allWebDevices' in topics",
        analytics_label: String(this.myForm.get('analytics_label').value)
      });
      this.successAlert();
    } catch (e) {
      log.error(e);
      this.errorAlert();
    }
    this.isLoading = false;
    loadingOverlay.dismiss();
    return;
  }

  async successAlert() {
    const alert = await this.alertController.create({
      header: `Correcto`,
      message: '¡Notificación enviada correctamente!',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });
    await alert.present();
  }

  async errorAlert() {
    const alert = await this.alertController.create({
      header: `Incorrecto`,
      message: '¡La notificación no fue enviada correctamente!',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });
    await alert.present();
  }

  private async initEspecialidades(): Promise<void> {
    this.isLoading = true;
    try {
      try {
        this.specialties = await this.getSpecialties();
      } catch (error) {
        log.error(error);
      }
      let resp: QuerySnapshot<DocumentData>;
      resp = await this.afs
        .collection(`information/counters/filters/type/medico`)
        .ref.where('count', '>', 0)
        .get();
      let myArray = resp.docs.map(e => e.id);
      this.specialties = this.specialties
        .filter(element => {
          const index: number = myArray.indexOf(String(element.id));
          if (index > -1) {
            return element;
          }
        })
        .map(element => {
          let data = element;
          data.filter = data.id;
          return data;
        });
      this.specialties = this.specialties.map(element => {
        let promise: Promise<any>;
        promise = this.afs
          .doc(`information/counters/filters/type/medico/${element.filter}`)
          .ref.get()
          .then(e => e.get('count'))
          .catch(() => {
            return 0;
          });
        return { id: element.id, label: element.name, isChecked: false, count: promise };
      });
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  public async getSpecialties(): Promise<any> {
    return (specialities as any).default;
  }

  private async initLocations(): Promise<void> {
    this.isLoading = true;
    try {
      try {
        const resp = await this.afs.collection('states-in-use').ref.get();
        this.locations = resp.docs.map(e => e.get('name'));
      } catch (error) {
        log.error(error);
      }
      let resp: QuerySnapshot<DocumentData>;
      resp = await this.afs
        .collection(`information/counters/filters/type/medico`)
        .ref.where('count', '>', 0)
        .get();
      let myArray = resp.docs.map(e => e.id);
      this.locations = this.locations
        .filter(element => {
          const index: number = myArray.indexOf(String(element));
          if (index > -1) {
            return element;
          }
        })
        .map(element => {
          let data = {};
          data = {
            name: element,
            id: element,
            filter: element
          };
          return data;
        });
      this.locations = this.locations.map(element => {
        let promise: Promise<any>;
        promise = this.afs
          .doc(`information/counters/filters/type/medico/${element.filter}`)
          .ref.get()
          .then(e => e.get('count'))
          .catch(() => {
            return 0;
          });
        return { id: element.id, label: element.name, isChecked: false, count: promise };
      });
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }
}
