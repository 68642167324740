<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Generar URL para Chat</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <ion-button (click)="selectUsersFunction()" expand="block" [disabled]="isLoading === true">
          <ion-icon slot="start" name="add"></ion-icon>
          Seleccionar representante
        </ion-button>
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ rep?.name }} {{ rep?.lastName1 }} {{ rep?.lastName2 }}</ion-card-title>
            <ion-card-subtitle>{{ rep?.company?.name }}</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form [formGroup]="dataForm" (ngSubmit)="getURL()">
          <ion-item>
            <ion-textarea autofocus="true" rows="8" formControlName="message" placeholder="Mensaje..."></ion-textarea>
          </ion-item>
          <ion-text color="danger" [hidden]="!dataForm.get('message').getError('maxlength')">
            <span class="ion-padding" translate>Máximo 180 caracteres</span>
          </ion-text>
          <ion-button type="submit" color="tertiary" expand="block" [disabled]="!dataForm.valid === true || !rep">
            Obtener URL
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
