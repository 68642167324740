import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { WpService } from '@app/shared/services/wp.service';
import { CredentialsService } from '../../core/authentication/credentials.service';
import { timer, Subscription } from 'rxjs';
import * as moment from 'moment';
import { IonContent, ModalController, LoadingController } from '@ionic/angular';
//import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { FileFormatService } from '@app/shared/services/file-format.service';
//const { Device } = Plugins;
import { Logger } from '@app/core';
const log = new Logger('Login');

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  @ViewChild(IonContent, { read: IonContent, static: true }) content: IonContent;
  @ViewChild('file1', { read: ElementRef, static: true }) file1: ElementRef;
  @ViewChild('file2', { read: ElementRef, static: true }) file2: ElementRef;
  public message: string;
  public me: number;
  private users: any[] = [];
  public usersId: number[] = [];
  public messages: any[] = [];
  private id: number;
  private lastMessagesLenght: number;
  mainSubscribe: Subscription;
  chat: any;
  fileToUpload1: any;
  fileToUpload2: any;

  constructor(
    private wp: WpService,
    private credentials: CredentialsService,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private utilities: UtilitiesService,
    private fileFormat: FileFormatService
  ) {
    moment.locale('es');
    this.me = this.credentials.credentials.uid;
  }

  triggerClick1() {
    this.file1.nativeElement.click();
  }

  triggerClick2() {
    this.file2.nativeElement.click();
  }

  async selectFile1(target: any) {
    const files: FileList = target.files;
    const loadingOverlay = await this.loadingController.create({
      message: 'Por favor espera, estamos cargando tu archivo'
    });
    loadingOverlay.present();
    try {
      this.fileToUpload1 = files.item(0);
      const fileSize = Number(this.fileToUpload1.size);
      if (fileSize <= this.fileFormat.maxFileSize) {
        /*********Archivo sin formato*********/
        const theFile = this.fileToUpload1;
        if (theFile.type != undefined && theFile.type != null && theFile.type != '') {
          /*********File type*********/
          if (this.fileFormat.validateImageType(theFile.type)) {
            await this.upload1();
            this.utilities.toast('Archivo agregado correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Formato de archivo no válido.', 'Error');
          }
          /*********\\File type*********/
        } else {
          this.utilities.toast('El archivo que intentas subir podría estar dañado.', 'Error');
        }
        /*********\\Archivo sin formato*********/
      } else {
        this.utilities.toast('El archivo que intentas subir es demasiado grande (10 Mb Máximo).', 'Error');
      }
    } catch (error) {
      log.error(error);
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
    loadingOverlay.dismiss();
  }

  async selectFile2(target: any) {
    const files: FileList = target.files;
    const loadingOverlay = await this.loadingController.create({
      message: 'Por favor espera, estamos cargando tu archivo'
    });
    loadingOverlay.present();
    try {
      this.fileToUpload2 = files.item(0);
      const fileSize = Number(this.fileToUpload2.size);
      if (fileSize <= this.fileFormat.maxFileSize) {
        /*********Archivo sin formato*********/
        const theFile = this.fileToUpload2;
        if (theFile.type != undefined && theFile.type != null && theFile.type != '') {
          /*********File type*********/
          if (this.fileFormat.validateDocumentType(theFile.type)) {
            await this.upload2();
            this.utilities.toast('Archivo agregado correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Formato de archivo no válido.', 'Error');
          }
          /*********\\File type*********/
        } else {
          this.utilities.toast('El archivo que intentas subir podría estar dañado.', 'Error');
        }
        /*********\\Archivo sin formato*********/
      } else {
        this.utilities.toast('El archivo que intentas subir es demasiado grande (10 Mb Máximo).', 'Error');
      }
    } catch (error) {
      log.error(error);
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
    loadingOverlay.dismiss();
  }

  async takePicture() {
    // const loadingOverlay = await this.loadingController.create({
    //   message: 'Por favor espera, estamos cargando tu archivo'
    // });
    // loadingOverlay.present();
    // try {
    //   const image = await Plugins.Camera.getPhoto({
    //     quality: 30,
    //     allowEditing: false,
    //     resultType: CameraResultType.DataUrl,
    //     source: CameraSource.Camera
    //   });
    //   const url = image.dataUrl;
    //   const fetchUrl = await fetch(url);
    //   const blobFile = await fetchUrl.blob();
    //   let file;
    //   const info = await Device.getInfo();
    //   if (info.platform === 'android' || info.platform === 'ios') {
    //     const stringImg = image.dataUrl;
    //     let extension;
    //     const lowerCase = stringImg.toLowerCase();
    //     if (lowerCase.indexOf('png') !== -1) {
    //       extension = 'png';
    //     } else if (lowerCase.indexOf('jpg') !== -1 || lowerCase.indexOf('jpeg') !== -1) {
    //       extension = 'jpg';
    //     }
    //     file = new File([blobFile], 'file-name.' + extension, { type: 'image/' + extension });
    //   } else {
    //     file = new File([blobFile], 'file-name.' + image.format, { type: 'image/' + image.format });
    //   }
    //   this.fileToUpload1 = file;
    //   const fileSize = Number(this.fileToUpload1.size);
    //   if (fileSize <= this.fileFormat.maxFileSize) {
    //     /*********Archivo sin formato*********/
    //     const theFile = this.fileToUpload1;
    //     if (theFile.type != undefined && theFile.type != null && theFile.type != '') {
    //       /*********File type*********/
    //       if (this.fileFormat.validateImageType(theFile.type)) {
    //         await this.upload1();
    //         this.utilities.toast('Archivo agregado correctamente.', 'Correcto');
    //       } else {
    //         this.utilities.toast('Formato de archivo no válido.', 'Error');
    //       }
    //       /*********\\File type*********/
    //     } else {
    //       this.utilities.toast('El archivo que intentas subir podría estar dañado.', 'Error');
    //     }
    //     /*********\\Archivo sin formato*********/
    //   } else {
    //     this.utilities.toast('El archivo que intentas subir es demasiado grande (10 Mb Máximo).', 'Error');
    //   }
    // } catch (error) {
    //   log.error(error);
    // }
    // loadingOverlay.dismiss();
  }

  async upload1() {
    try {
      const media: any = await this.wp.uploadFile(this.fileToUpload1);
      const url = media.body.source_url;
      const message = `<img alt="${url}" src="${url}" class="iab-disabled"/>`;
      await this.sendMessage(message);
    } catch (error) {
      log.error(error);
    }
  }

  async upload2() {
    try {
      const media: any = await this.wp.uploadFile(this.fileToUpload2);
      const url = media.body.source_url;
      const ext1 = url.substr(-3);
      const ext2 = url.substr(-4);
      let ext: string = '';
      if (this.fileFormat.validateDocument(ext1)) {
        ext = ext1;
      } else if (this.fileFormat.validateDocument(ext2)) {
        ext = ext2;
      }
      const name = media.body.title.raw;
      const fullName = name + '.' + ext;
      const message = `<a href="${url}" class="iab-disabled"><ion-icon name="document"></ion-icon> ${fullName}</a>`;
      await this.sendMessage(message);
    } catch (error) {
      log.error(error);
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      this.content.scrollToBottom(100);
    }, 600);
  }

  public member(id: number): any {
    const index: number = this.users.map(element => element.id).indexOf(id);
    if (index > -1) {
      return this.users[index];
    }
  }

  private async geUserData(id: number): Promise<void> {
    try {
      const response = await this.wp.member(id);
      this.users.push(response.body);
    } catch (error) {
      log.error(error);
    }
  }

  loadData(event: any) {
    try {
      event.target.complete();
    } catch (error) {
      log.error(error);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  private async init(id: number): Promise<void> {
    try {
      const response = await this.wp.message(id);
      Object.keys(response.body[0].recipients).forEach((element: any) => {
        const userid: number = Number(element);
        if (!(this.usersId.indexOf(userid) > -1)) {
          if (!(Number(element) == this.me)) {
            this.chat = Number(element);
          }
          this.usersId.push(userid);
          this.geUserData(userid);
        }
      });
      this.messages = Array.from(response.body[0].messages).map((element: any) => {
        var stillUtc = moment.utc(element['date_sent']).toDate();
        var dateStr = moment(stillUtc)
          .local()
          .format('DD-MM-YYYY h:mm A');
        return {
          id: element['id'],
          sender_id: element['sender_id'],
          date: dateStr,
          message: element['message']['raw']
        };
      });
      if (this.lastMessagesLenght) {
        if (!(this.lastMessagesLenght == this.messages.length)) {
          this.lastMessagesLenght = this.messages.length;
          this.scrollToBottom();
        }
      } else {
        this.lastMessagesLenght = this.messages.length;
        this.scrollToBottom();
      }
    } catch (error) {
      log.error(error);
    }
  }

  public async sendMessage(msg?: string): Promise<void> {
    if ((this.message && this.message !== '') || (msg && msg !== '')) {
      try {
        const message = msg ? msg : this.message;
        await this.wp.sendMessage({ id: this.id, message: message });
        this.message = '';
        let usersid: number[] = [];
        this.usersId.forEach((num: number) => {
          usersid.push(num);
        });
        const index = usersid.indexOf(this.credentials.credentials.uid);
        if (index > -1) {
          usersid.splice(index, 1);
        }
        const uid: number = usersid[0];
        const messa: string = message.substring(0, 60);
      } catch (error) {
        log.error(error);
      }
    }
  }

  public async sendMessage2(msg?: string): Promise<void> {
    const loadingOverlay = await this.loadingController.create();
    loadingOverlay.present();
    if ((this.message && this.message !== '') || (msg && msg !== '')) {
      try {
        let message = msg ? msg : this.message;
        message = this.utilities.urlify(message);
        this.message = '';
        await this.wp.sendMessage({ id: this.id, message: message });
        let usersid: number[] = [];
        this.usersId.forEach((num: number) => {
          usersid.push(num);
        });
        const index = usersid.indexOf(this.credentials.credentials.uid);
        if (index > -1) {
          usersid.splice(index, 1);
        }
        const uid: number = usersid[0];
        this.wp.createNotification(
          Number(uid),
          Number(this.id),
          'chat_' + String(this.id) + String(this.utilities.randomChain(6)),
          'chat'
        );
      } catch (error) {
        log.error(error);
      }
    }
    loadingOverlay.dismiss();
  }

  ngOnInit() {
    this.mainSubscribe = timer(0, 5000).subscribe(() => this.init(this.id));
  }

  ionViewWillLeave() {
    try {
      this.mainSubscribe.unsubscribe();
    } catch (error) {
      log.error(error);
    }
  }
}
