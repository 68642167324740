import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { FirebaseService } from '@app/shared/services/firebase.service';

// import * as cpsInfo from './cps.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  quote: string | undefined;
  isLoading = false;

  constructor(private firebaseSvc: FirebaseService) {}

  ngOnInit() {}

  async uploadZips() {
    /*for await (const cp of cpsInfo.rows) {
      const elem = {
        postal_code: cp.postal_code,
        city: cp.city,
        colony: cp.colony,
        state: cp.state
      };
      log.debug(cp.postal_code, cp.colony, cp.city, cp.state);
      const elemState = {
        city: cp.city,
        state: cp.state
      };
      // create elements in db
      await this.firebaseSvc.saveState(elemState, elem);
      await this.firebaseSvc.saveCP(elem);
    }*/
  }
}
