<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">
      <span *ngIf="!justView === true; else justViewTitle">
        {{ id ? 'Actualizar información de compañía' : 'Agregar compañía' }}
      </span>
      <ng-template #justViewTitle>
        <span>Información de compañía</span>
      </ng-template>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <div *ngIf="!justView === true; else justViewComponent">
          <form [formGroup]="dataForm" (ngSubmit)="save()">
            <ion-item>
              <ion-label position="stacked">
                <h5>
                  Nombre de la empresa
                  <span style="color: red;"> * </span>
                </h5>
              </ion-label>
              <ion-icon slot="start" name="business"></ion-icon>
              <ion-input type="text" formControlName="name"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">
                <h5>
                  Giro de la empresa
                  <span style="color: red;"> * </span>
                </h5>
              </ion-label>
              <ion-icon slot="start" name="cube"></ion-icon>
              <ion-input type="text" formControlName="businessType"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">
                <h5>
                  Descripción de la empresa
                </h5>
              </ion-label>
              <ion-icon slot="start" name="list-box"></ion-icon>
              <ion-textarea formControlName="description" [autoGrow]="true" [rows]="5"> </ion-textarea>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">
                <h5>
                  Pagina web de la empresa
                  <span style="color: red;"> * </span>
                </h5>
              </ion-label>
              <ion-icon slot="start" name="globe"></ion-icon>
              <ion-input type="text" formControlName="companyWebsite"></ion-input>
            </ion-item>

            <ion-item>
              <ion-label position="stacked">
                <h5>
                  Logo de la empresa
                  <span style="color: red;"> * </span>
                </h5>
              </ion-label>
              <input #imgInput type="file" accept="image/*" class="ion-hide" [multiple]="false"
                (change)="loadImg(imgInput)" />
            </ion-item>

            <div (click)="imgInput.click()" style="border: 1px dashed black;">
              <img *ngIf="logo" [src]="logo" class="logo-img" />
              <img *ngIf="!logo" src="assets/img/no-image.jpg" class="logo-img" />
            </div>

            <ion-text color="danger">
              <p>* Datos obligatorios</p>
            </ion-text>
            <ion-button type="submit" color="tertiary" expand="block"
              [disabled]="!dataForm.valid || (!id && !toUploadLogo)">
              {{ id ? 'Actualizar información' : 'Agregar' }}
            </ion-button>
          </form>
        </div>
        <ng-template #justViewComponent>
          <ion-card color="light">
            <h5>
              Nombre de la empresa
            </h5>
            <ion-item>
              <ion-icon slot="start" name="business"></ion-icon>
              <p>{{ company?.name }}</p>
            </ion-item>

            <h5>
              Giro de la empresa
            </h5>
            <ion-item>
              <ion-icon slot="start" name="cube"></ion-icon>
              <p>{{ company?.businessType }}</p>
            </ion-item>

            <h5>
              Descripción de la empresa
            </h5>
            <ion-item>
              <ion-icon slot="start" name="list-box"></ion-icon>
              <p>{{ company?.description }}</p>
            </ion-item>

            <h5>
              Pagina web de la empresa
            </h5>
            <ion-item>
              <ion-icon slot="start" name="globe"></ion-icon>
              <p>{{ company?.companyWebsite }}</p>
            </ion-item>

            <h5>
              Logo de la empresa
            </h5>

            <div style="border: 1px dashed black;">
              <img *ngIf="logo" [src]="logo" class="logo-img" style="height: auto;" />
              <img *ngIf="!logo" src="assets/img/no-image.jpg" class="logo-img" style="height: auto;" />
            </div>
          </ion-card>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>