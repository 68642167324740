import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Logger } from '@app/core/logger.service';
const log = new Logger('ClickBannerLogsComponent');

@Component({
  selector: 'app-click-banner-logs',
  templateUrl: './click-banner-logs.component.html',
  styleUrls: ['./click-banner-logs.component.scss']
})
export class ClickBannerLogsComponent implements OnInit {
  public advertisings: any[] = [];
  dateStr: string;

  constructor(
    private firestore: AngularFirestore,
    private firebaseService: FirebaseService,
    private aRoute: ActivatedRoute,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {
    this.initializeApp();
  }

  initializeApp() {
    if (this.aRoute && this.aRoute.snapshot && this.aRoute.snapshot.params && this.aRoute.snapshot.params.date) {
      this.init(String(this.aRoute.snapshot.params.date));
    }
  }

  async init(date?: string) {
    const loading = await this.loadingCtrl.create();
    loading.present();
    try {
      date = date ? date : moment().format('YYYY-MM-DD');
      this.dateStr = date;
      const resp = await this.firestore
        .collection('advertising')
        .ref.orderBy('order', 'asc')
        .get();

      this.advertisings = resp.docs.map(item => {
        const data: any = item.data();
        const id: string = item.id;
        return { id, ...data };
      });

      for (let day of this.advertisings) {
        try {
          const stringRef: string = `advertising/${day.id}/clicks/${date}`;
          const respDoc = await this.firestore.doc(stringRef).ref.get();
          day.count = respDoc.get('count') ? respDoc.get('count') : 0;

          day.info = await this.firebaseService.getType({
            advertising: day,
            link: day.link,
            productId: day.productId,
            repreId: day.repreId
          });
        } catch (error) {
          log.error(error);
        }
      }
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
  }

  async download() {
    const loading = await this.loadingCtrl.create();
    loading.present();
    try {
      let adds = [];
      for (let item of this.advertisings) {
        let data: any = { title: String(item.content).trim(), id: item.id, count: item.count, info: item.info };
        try {
          const users: any[] = await this.firebaseService.getUsersBanners(item.id, this.dateStr);
          let count: number = 1;
          data.users = users.map(e => {
            let data: any = e;
            delete data.id;
            let d: any = {
              'N°': count++,
              UID: data.uid,
              Nombre: data.name,
              'Teléfono 1': data.phone1,
              'Teléfono 2': data.phone2,
              Email: data.email,
              Fecha: data.date
            };
            return d;
          });
        } catch (error) {
          log.error(error);
        }
        adds.push(data);
      }

      let wb = XLSX.utils.book_new();

      for (let sheet of adds) {
        let aoa_to_sheet: any[] = [];
        if (sheet && sheet.info && sheet.info.link) {
          aoa_to_sheet = [
            [String(sheet.title)],
            [`ID: ${sheet.id}`],
            [`N° de clicks: ${sheet.count}`],
            [`Fecha: ${this.dateStr}`],
            [`URL: ${sheet.info.link.url}`],
            [''],
            ['Médicos:'],
            ['']
          ];
        } else if (sheet && sheet.info && sheet.info.product && sheet.info.rep) {
          aoa_to_sheet = [
            [String(sheet.title)],
            [`ID: ${sheet.id}`],
            [`N° de clicks: ${sheet.count}`],
            [`Fecha: ${this.dateStr}`],
            [''],
            ['Producto'],
            [`Nombre: ${sheet.info.product.name}`],
            [`Compañía: ${sheet.info.product.company}`],
            [`Tipo: ${sheet.info.product.type}`],
            [''],
            ['Representante'],
            [`Nombre: ${sheet.info.rep.name}`],
            [`Compañía: ${sheet.info.rep.company}`],
            [`Email: ${sheet.info.rep.email}`],
            [`Teléfono 1: ${sheet.info.rep.phone1}`],
            [`Teléfono 2: ${sheet.info.rep.phone2}`],
            [''],
            ['Médicos:'],
            ['']
          ];
        } else {
          aoa_to_sheet = [
            [String(sheet.title)],
            [`ID: ${sheet.id}`],
            [`N° de clicks: ${sheet.count}`],
            [`Fecha: ${this.dateStr}`],
            [''],
            ['Representante'],
            [`Nombre: ${sheet.info.rep.name}`],
            [`Compañía: ${sheet.info.rep.company}`],
            [`Email: ${sheet.info.rep.email}`],
            [`Teléfono 1: ${sheet.info.rep.phone1}`],
            [`Teléfono 2: ${sheet.info.rep.phone2}`],
            [''],
            ['Médicos:'],
            ['']
          ];
        }
        let ws = XLSX.utils.aoa_to_sheet(aoa_to_sheet);
        XLSX.utils.sheet_add_json(ws, sheet.users, { origin: -1 });
        XLSX.utils.book_append_sheet(wb, ws, String(sheet.title).substring(0, 25));
      }

      XLSX.writeFile(wb, `reporte_clicks_en_banners_${this.dateStr}.xlsx`);
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
  }
}
