import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from '../shared/services/utilities.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { CredentialsService } from '@app/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { Logger } from '@app/core';
const log = new Logger('Points');

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})
export class PointsComponent implements OnInit {
  myForm!: FormGroup;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private utilities: UtilitiesService,
    private loadingController: LoadingController,
    public credentials: CredentialsService,
    private fb: FirebaseService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  private createForm() {
    this.myForm = this.formBuilder.group({
      points: ['', Validators.required],
      uid: ['', [Validators.minLength(10)]],
      email: ['', [Validators.email]],
      note: ['', [Validators.required, Validators.minLength(3)]]
    });
  }

  public async onSubmit() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      var idu;
      if (this.myForm.get('email').value !== '' && this.myForm.get('email').value !== null) {
        const resp = await this.fb.searchUserEmail(this.myForm.get('email').value);
        if (resp && resp.docs && resp.docs[0] && resp.docs[0].id) {
          idu = resp.docs[0].id;
        }
      } else if (this.myForm.get('uid').value !== '' && this.myForm.get('uid').value !== null) {
        const resp = await this.fb.searchUserUid(this.myForm.get('uid').value);
        if (resp.exists !== false) {
          idu = this.myForm.get('uid').value;
        }
      }

      if (idu) {
        const pointsObj = {
          points: this.myForm.get('points').value,
          note: this.myForm.get('note').value,
          user_id: idu,
          creator_id: this.credentials.credentials.uid,
          from: 'admin'
        };

        const result = await this.fb.loadPointsAdmin(pointsObj);
        if (result.success === true) {
          this.utilities.toast('Los puntos fueron cargados correctamente.', 'Exito');
          this.myForm.reset();
        } else {
          this.utilities.toast('Los puntos no fueron cargados, intente nuevamente.', 'Error');
        }
      } else {
        this.utilities.toast('El usuario no existe, verfique la información.', 'Error');
      }
    } catch (error) {
      log.error(error);
    }

    this.isLoading = false;
    loadingOverlay.dismiss();
  }
}
