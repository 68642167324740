import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore, DocumentData, Query, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
const log = new Logger('Searh company');

@Component({
  selector: 'app-search-company',
  templateUrl: './search-company.component.html',
  styleUrls: ['./search-company.component.scss']
})
export class SearchCompanyComponent implements OnInit {
  arrayData: any[] = [];
  public arrayDocs: any[];
  public forward: boolean = false;
  private perPage: number = 50;
  myContacts: boolean;
  currentFilter: any;
  private searchStr: string;
  public isLoading: boolean = false;
  public orderBy: string = 'nameStr';
  public orderByDirection: any = 'asc';
  private cursorNext: QueryDocumentSnapshot<any>;
  private cursorBack: QueryDocumentSnapshot<any>;

  constructor(
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private credentials: CredentialsService
  ) {}

  private setQueries(): Query {
    let query: Query;
    if (!(this.myContacts === true)) {
      query = this.afs.collection('companies').ref.where('status', '==', 'active');
    } else {
      query = this.afs
        .collection(
          `friends/${this.credentials.credentials.uid}/information/counters/filters/type/representante-medico`
        )
        .ref.where('status', '==', 'active');
    }
    if (this.searchStr) {
      query = query.where('search', 'array-contains', this.searchStr);
    }
    return query;
  }

  public async paginate(direction?: 'next' | 'back'): Promise<void> {
    let users: any[] = [];
    let query = this.setQueries();
    if (this.cursorNext && direction === 'next') {
      query = query
        .orderBy(this.orderBy, 'asc')
        .startAfter(this.cursorNext)
        .limit(this.perPage);
    } else if (this.cursorBack && direction === 'back') {
      query = query
        .orderBy(this.orderBy, 'asc')
        .startAt(this.cursorBack)
        .limit(this.perPage);
    } else {
      query = query.orderBy(this.orderBy, 'asc').limit(this.perPage);
    }
    try {
      const snap = await query.get();
      this.isLoading = true;
      this.cursorNext = snap.docs.length > 0 ? snap.docs[snap.docs.length - 1] : undefined;
      let snapBack: QuerySnapshot<DocumentData>;
      if (snap && snap.docs[0]) {
        const queryBack = this.setQueries();
        snapBack = await queryBack
          .orderBy(this.orderBy, 'desc')
          .startAfter(snap.docs[0])
          .limit(this.perPage)
          .get();
      } else {
        this.isLoading = false;
        if (this.searchStr) {
          this.arrayDocs = [];
        }
        return;
      }
      this.cursorBack =
        snapBack && snapBack.docs && snapBack.docs.length > 0 ? snapBack.docs[snapBack.docs.length - 1] : undefined;
      users = snap.docs.map((element: any) => {
        let data: any = element.data();
        data.isChecked = false;
        data.label = data.name;
        const id: string = element.id;
        if (this.arrayData && this.arrayData[0]) {
          if (this.arrayData[0].id === id) {
            data.isChecked = true;
          }
        }
        return { id, ...data };
      });
      this.arrayDocs = users;
      this.isLoading = false;
    } catch (error) {
      log.error(error);
    }
  }

  ngOnInit() {
    this.paginate();
  }

  doSearch(ev: any) {
    try {
      this.search(ev);
    } catch (error) {
      log.error(error);
    }
  }

  async search(ev: any) {
    const searchStr: string = String(ev.target.value).toLocaleLowerCase();
    if (searchStr.length >= 3) {
      try {
        this.isLoading = true;
        this.searchStr = searchStr;
        await this.paginate();
        this.isLoading = false;
      } catch (error) {
        log.error(error);
      }
    }
  }

  async loadData() {
    this.isLoading = true;
    this.searchStr = '';
    try {
      await this.paginate();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  clearSearch() {
    this.searchStr = '';
    this.arrayDocs = [];
  }

  selectItem(item: any) {
    item.isChecked = true;
    const element = [item];
    this.modalCtrl.dismiss({ arrayData: element }, 'data');
  }

  closeModal() {
    this.modalCtrl.dismiss({ arrayData: this.arrayData }, 'close');
  }

  clearFilter() {
    this.modalCtrl.dismiss({ arrayData: [] }, 'clear');
  }
}
