import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, LoadingController, Platform } from '@ionic/angular';
import { CameraSource } from '@capacitor/camera';
import { Device } from '@capacitor/device';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { TextToLinkService } from '@app/shared/services/text-to-link/text-to-link.service';
import { Logger } from '@app/core';
import { Message } from '@app/shared/services/firebase.service';
import { FilesService } from '@app/shared/services/files.service';
const log = new Logger('Chat Group');

@Component({
  selector: 'app-chat-group',
  templateUrl: './chat-group.component.html',
  styleUrls: ['./chat-group.component.scss']
})
export class ChatGroupComponent implements OnInit {
  @ViewChild('file1', { read: ElementRef, static: true }) file1: ElementRef;
  @ViewChild('file2', { read: ElementRef, static: true }) file2: ElementRef;
  @ViewChild('textAreaChat', { read: ElementRef, static: true }) textAreaChat: ElementRef;
  @Output() save = new EventEmitter();
  public message: string;
  fileToUpload1: any;
  fileToUpload2: any;
  public dataForm: FormGroup;
  item: any;
  isLoading: boolean;
  users: number[];
  files: string[] = [];
  rawMessages: Message[] = [];
  idGroup: any;
  path = 'chats';
  userDefault: boolean = true;
  selectionStart: number;
  selectionEnd: number;
  public mementos: string[] = [];
  public flagFromUndo: boolean = false;
  public isWeb: boolean = false;
  htmlContent: string;
  resetEditor: number = 0;
  isLoad: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private utilities: UtilitiesService,
    private filesService: FilesService,
    public platform: Platform,
    private textToLink: TextToLinkService
  ) {
    this.initForm();
  }

  async init() {
    await this.platform.ready();
    this.initForm();
  }

  ngOnInit() {}

  initForm() {
    this.dataForm = this.formBuilder.group({
      msg: ['', Validators.required],
      sendAnalytics: [false],
      analyticsLabel: ['']
    });
  }

  sendAnalytics(event: any) {
    if (event && event.target && event.target.checked === true) {
      this.dataForm.controls['analyticsLabel'].setValidators([Validators.required]);
      this.dataForm.controls['analyticsLabel'].updateValueAndValidity();
    } else {
      this.dataForm.controls['analyticsLabel'].clearValidators();
      this.dataForm.controls['analyticsLabel'].updateValueAndValidity();
    }
  }

  deleteItem(index: number) {
    this.files.splice(index, 1);
    this.rawMessages.splice(index, 1);
  }

  public async send(): Promise<void> {
    if (this.dataForm.get('msg').value && this.dataForm.get('msg').value !== '') {
      this.rawMessages.unshift({
        isFile: false,
        message: this.dataForm.get('msg').value, //,this.textToLink.replace(this.dataForm.get('msg').value),
        url: null,
        convert: null
      });
    }
    let data: any = { messages: this.rawMessages };
    if (this.dataForm.get('sendAnalytics').value === true && this.dataForm.get('analyticsLabel').value) {
      data.analytics = {
        send: true,
        label: this.dataForm.get('analyticsLabel').value
      };
    }
    this.save.emit(data);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  triggerClick1() {
    this.file1.nativeElement.click();
  }

  triggerClick2() {
    this.file2.nativeElement.click();
  }

  async selectFile1(target: any) {
    const file: FileList = target.files;
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const response = await this.filesService.selectImageFile(file, `${this.path}/${this.idGroup}`, 'chat');
      const url = response.main.url;
      const message = `<img alt="${url}" src="${url}" class="iab-disabled"/>`;
      this.files.push(message);
      this.rawMessages.push({ isFile: false, message: message, url: null, convert: null });
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async selectFile2(target: any) {
    const file: FileList = target.files;
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const response = await this.filesService.selectAnyFile(file, `${this.path}/${this.idGroup}`, [
        'doc',
        'docx',
        'ppt',
        'pptx',
        'xls',
        'xlsx',
        'pdf'
      ]);
      if (response && response.main && response.main.url) {
        const url = response.main.url;
        const nameMedia = response.main.path;
        const fullName = nameMedia.split('/')[2];
        const message = `<a href="${url}" class="iab-disabled"><ion-icon name="document"></ion-icon> ${fullName}</a>`;
        this.files.push(message);
        this.rawMessages.push({ isFile: true, message: fullName, url: url, convert: null });
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async takePicture(image?: CameraSource) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const response = await this.filesService.takePicture(image, this.path, 'chat');
      const url = response.main.url;
      const message = `<img alt="${url}" src="${url}" class="iab-disabled"/>`;
      this.files.push(message);
      this.rawMessages.push({ isFile: false, message: message, url: null, convert: null });
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async imageFromGallery() {
    try {
      const info = await Device.getInfo();
      if (info && info.platform == 'web') {
        this.triggerClick1();
      } else {
        this.takePicture(CameraSource.Photos);
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
  }

  public keypressEvent(event: any): void {
    if (!this.platform.is('cordova')) {
      if (event && event.key === '\n') {
        this.textAreaChat.nativeElement.value = this.textAreaChat.nativeElement.value + '\n';
      } else if (
        event &&
        event.key === 'Enter' &&
        event.altKey === false &&
        event.ctrlKey === false &&
        event.shiftKey === false
      ) {
        this.send();
      }
    }
  }

  public textBold() {
    if (this.selectionStart && this.selectionEnd) {
      this.selectedText('**', '**');
    } else if (this.selectionStart) {
      log.debug(`selectionStart: ${this.selectionStart}`);
    } else if (this.selectionEnd) {
      this.selectionStart = 0;
      this.selectedText('**', '**');
    }
    this.setFocusTextArea();
  }

  public textItalic() {
    if (this.selectionStart && this.selectionEnd) {
      this.selectedText('*', '*');
    } else if (this.selectionStart) {
      log.debug(`selectionStart: ${this.selectionStart}`);
    } else if (this.selectionEnd) {
      this.selectionStart = 0;
      this.selectedText('*', '*');
    }
    this.setFocusTextArea();
  }

  private selectedText(start: string, end?: string) {
    const TEXT_INITIAL: string = this.textAreaChat.nativeElement.value;
    const TEXT_1 = TEXT_INITIAL.substr(0, this.selectionStart);
    let text2 = TEXT_INITIAL.substr(this.selectionStart, this.selectionEnd - this.selectionStart);
    text2 = text2.trim();
    const TEXT_3 = TEXT_INITIAL.substr(this.selectionEnd);
    const END: string = end ? end : '';
    const TEXT_FINAL = `${TEXT_1}${start}${text2}${END}${TEXT_3}`;
    this.textAreaChat.nativeElement.value = TEXT_FINAL;
    this.selectionStart = undefined;
    this.selectionEnd = undefined;
  }

  textSelectionTrigger(event: any) {
    this.selectionStart = event.target.selectionStart;
    this.selectionEnd = event.target.selectionEnd;
  }

  saveMemento(event: any) {
    const value: string = String(event.target.value);
    if (this.flagFromUndo !== true) {
      if (value && value !== '') {
        this.mementos.push(value);
      }
    }
    this.flagFromUndo = false;
  }

  undo() {
    this.mementos.splice(-1);
    const lastMemento = this.mementos[this.mementos.length - 1];
    this.textAreaChat.nativeElement.value = lastMemento ? lastMemento : '';
    this.flagFromUndo = true;
    this.setFocusTextArea();
  }

  resetUndo() {
    this.mementos = [];
    this.flagFromUndo = false;
  }

  setFocusTextArea() {
    this.textAreaChat.nativeElement.setFocus();
  }

  keyupHandler(event: any) {
    if (event == '' || event == undefined || event == null) {
      this.dataForm.get('msg').setValue('');
    } else {
      this.dataForm.get('msg').setValue(event);
    }
  }
}
