import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { DetailSurveyComponent } from './detail-survey/detail-survey.component';
import { Logger } from '@app/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { APP_URL } from '../../environments/environment';
import moment from 'moment';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { ActivatedRoute, Router } from '@angular/router';
const log = new Logger('SurveysAndTestsComponent');

@Component({
  selector: 'app-surveys-and-tests',
  templateUrl: './surveys-and-tests.component.html',
  styleUrls: ['./surveys-and-tests.component.scss']
})
export class SurveysAndTestsComponent implements OnInit {

  public surveys: any[] = [];
  public isLoading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private afs: AngularFirestore,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private utilities: UtilitiesService,
    private aRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ionViewDidEnter() {
    if (
      this.aRoute &&
      this.aRoute.snapshot &&
      this.aRoute.snapshot.queryParams &&
      this.aRoute.snapshot.queryParams.action &&
      this.aRoute.snapshot.queryParams.action === 'reload') {
      this.init();
      this.router.navigate(['/surveys-and-tests']);
    }
  }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const data = await this.afs.collection('surveys-and-tests').ref.orderBy('creationDate', 'desc').get();
      this.surveys = data.docs.map(item => {
        let data: any = item.data();
        data.dateStr = moment(data.creationDate.toDate()).format('YYYY-MM-DD HH:mm');
        data.html = this.utilities.trustHtml(data.description);
        const id: string = item.id;
        return { ...data, id }
      });
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async detailSurvey(id: string) {
    try {
      const modal = await this.modalCtrl.create({
        component: DetailSurveyComponent,
        componentProps: {
          id
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  async _deleteSurvey(id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      await this.afs.collection('surveys-and-tests').doc(id).delete();
      await this.init();
      this.presentToast('top', "¡Encuesta eliminada correctamente!");
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async deleteSurvey(id: string) {
    const alert = await this.alertController.create({
      header: `Confirmar eliminación`,
      message: `¿Está seguro de eliminar esta encuesta?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Eliminar',
          handler: async () => {
            this._deleteSurvey(id);
          }
        }
      ]
    });
    await alert.present();
  }

  async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2500,
      position: position,
    });
    await toast.present();
  }

  async inactive(event: any, id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const status: any = event && event.detail && event.detail.checked && event.detail.checked === true ? { value: 'active', label: 'activada' } : { value: 'inactive', label: 'inactivada' };
      await this.afs.collection('surveys-and-tests').doc(id).update({ status: status.value });
      await this.init();
      this.presentToast('top', `¡Encuesta ${status.label} correctamente!`);
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  getLink(id: string) {
    const link = `${APP_URL}/surveys/${btoa(id)}`;
    this.linkAlert(link);
  }

  duplicate(survey: any) {
    this.duplicateAlert(survey);
  }

  async linkAlert(link: string) {
    const alert = await this.alertController.create({
      header: `Link de encuesta`,
      message: link,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Copiar',
          handler: async () => {
            this.copiarContenido(link)
          }
        }
      ]
    });
    await alert.present();
  }

  async duplicateAlert(survey: any) {
    const alert = await this.alertController.create({
      header: `Duplicar encuesta`,
      message: `¿Está seguro de duplicar la encuesta "${survey && survey.title ? survey.title : ''}"?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Duplicar',
          handler: async () => {
            this.duplicateHandler(survey)
          }
        }
      ]
    });
    await alert.present();
  }

  async copiarContenido(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      this.presentToast('top', 'Contenido copiado al portapapeles');
    } catch (err) {
      this.presentToast('top', 'Error al copiar');
      log.error('Error al copiar: ', err);
    }
  }

  async duplicateHandler(survey: any) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const id = survey && survey.id ? survey.id : undefined;
      const newTitle = survey && survey.title ? `${survey.title} (Copy)` : '';
      if (id) {
        let newSurvey: any = Object.assign({}, survey);
        delete newSurvey.dateStr;
        delete newSurvey.html;
        delete newSurvey.id;
        newSurvey.creationDate = moment().toDate();
        newSurvey.modificationDate = moment().toDate();
        newSurvey.title = newTitle;
        await this.afs.collection('surveys-and-tests').add(newSurvey);
        await this.init();
        this.presentToast('top', `¡Encuesta duplicada correctamente!`);
      }
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async setDeleteAccountForm(survey: any) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const id = survey && survey.id ? survey.id : undefined;
      const isDeleteAccountForm = survey && survey.isDeleteAccountForm === true ? true : false;
      const resp = await this.afs.collection('surveys-and-tests').ref.where('isDeleteAccountForm', '==', true).get();
      for (let item of resp.docs) {
        await item.ref.update({ isDeleteAccountForm: false });
      }
      await this.afs.collection('surveys-and-tests').doc(id).update({ isDeleteAccountForm: !isDeleteAccountForm });
      await this.init();
      this.presentToast('top', `Formulario actualizado correctamente!`);
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async setDeleteAccountFormAlert(survey: any) {
    const text = survey && survey.isDeleteAccountForm === true ? `¿Está seguro quitar este formulario: "${survey && survey.title ? survey.title : ''}", del formulario de elimanción de cuenta?` : `¿Está seguro usar este formulario: "${survey && survey.title ? survey.title : ''}", como formulario de elimanción de cuenta?`;
    const alert = await this.alertController.create({
      header: `Formulario de eliminación de cuenta`,
      message: text,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: survey && survey.isDeleteAccountForm === true ? 'Quitar' : 'Usar',
          handler: async () => {
            this.setDeleteAccountForm(survey)
          }
        }
      ]
    });
    await alert.present();
  }

}
