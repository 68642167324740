import {
  Component,
  AfterViewInit,
  EventEmitter,
  OnDestroy,
  Input,
  Output,
  SimpleChanges,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import 'tinymce';
import 'tinymce/themes/modern';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
declare var tinymce: any;
import { Logger } from '@app/core/logger.service';
const log = new Logger('TinyEditorComponent');

@Component({
  selector: 'tiny-editor',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <textarea id="{{ elementId }}" rows="{{ rows }}">{{ contentHtml }}</textarea>
  `
})
export class TinyEditorComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() elementId: string;
  @Input() toolbar: string;
  @Input() contentHtml: string;
  @Input() rows: number = 20;
  @Output() onEditorContentChange = new EventEmitter();
  public editor: any;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.contentHtml && changes.contentHtml.currentValue) {
      this.editor.setContent(changes.contentHtml.currentValue);
    }
  }

  ngAfterViewInit() {
    this.initEditor(this.toolbar);
  }

  initEditor(toolbar?: string) {
    tinymce.init({
      menubar: false,
      statusbar: false,
      strict_loading_mode: true,
      selector: '#' + this.elementId,
      plugins: ['link'],
      skin_url: '/assets/skins/light',
      toolbar1: toolbar ? toolbar : 'undo redo | bold italic underline | link unlink',
      setup: (editor: any) => {
        this.editor = editor;
        editor.on('keyup change', () => {
          const content = editor.getContent();
          this.onEditorContentChange.emit(content);
        });
        editor.on('init', () => {
          if (this.contentHtml) {
            const content = editor.getContent();
            this.onEditorContentChange.emit(content);
            editor.setContent(this.contentHtml);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    try {
      tinymce.remove(this.editor);
    } catch (error) {
      log.error(error);
    }
  }

  @Input()
  set resetEditor(value: number) {
    if (value) {
      this.editor.setContent('');
      this.onEditorContentChange.emit('');
    }
  }
}
