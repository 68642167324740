import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastController, AlertController } from '@ionic/angular';
import { HttpService, HttpHeaders } from '@app/shared/services/http/http.service';
import { Logger } from '../../core/logger.service';
import { FIREBASE_API } from '@env/environment';
const minLength = 3;
const log = new Logger('UtilitiesService');

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  removeAccents = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  unique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  public slugify = (text: string) =>
    text
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-');

  constructor(
    private http: HttpService,
    private sanitizer: DomSanitizer,
    public toastController: ToastController,
    private alertCtrl: AlertController
  ) { }

  public trustHtml(html: string): SafeHtml {
    return html ? this.sanitizer.bypassSecurityTrustHtml(html) : null;
  }

  public async toast(message: string, title?: string) {
    title = title ? title : '';
    const toast = await this.toastController.create({
      header: title,
      message: message,
      position: 'top',
      duration: 10000,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  public urlify(text: string, noIab?: boolean) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      if (noIab === true) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      } else {
        return '<a href="' + url + '" class="iab-disabled">' + url + '</a>';
      }
    });
  }

  async striplinks(text: string): Promise<string> {
    const re = /<a\s.*?href=[\"\'](.*?)[\"\']*?>(.*?)<\/a>/g;
    const str = text;
    const subst = '$2';
    const result = str.replace(re, subst);
    return result;
  }

  public setUrlParameter(url: any, key: any, value: any): string {
    let baseUrl = url.split('?')[0],
      urlQueryString = url.split('?')[1] ? '?' + url.split('?')[1] : '',
      newParam = value ? key + '=' + value : '',
      params = `?${newParam}`;
    if (urlQueryString) {
      const updateRegex = new RegExp('([?&])' + key + '[^&]*');
      const removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');
      if (typeof value === 'undefined' || value === null || value === '') {
        params = urlQueryString.replace(removeRegex, '$1');
        params = params.replace(/[&;]$/, '');
      } else if (urlQueryString.match(updateRegex) !== null) {
        params = urlQueryString.replace(updateRegex, '$1' + newParam);
      } else {
        params = `${urlQueryString}&${newParam}`;
      }
    }
    params = params === '?' ? '' : params;
    return `${baseUrl}${params}`;
  }

  public mapMemberData(item: any): any {
    let data: any;
    const name =
      item &&
        item.xprofile &&
        item.xprofile.groups &&
        item.xprofile.groups[1] &&
        item.xprofile.groups[1].fields &&
        item.xprofile.groups[1].fields[33] &&
        item.xprofile.groups[1].fields[33].value &&
        item.xprofile.groups[1].fields[33].value.raw
        ? item.xprofile.groups[1].fields[33].value.raw
        : '';
    const firstName =
      item &&
        item.xprofile &&
        item.xprofile.groups &&
        item.xprofile.groups[1] &&
        item.xprofile.groups[1].fields &&
        item.xprofile.groups[1].fields[34] &&
        item.xprofile.groups[1].fields[34].value &&
        item.xprofile.groups[1].fields[34].value.raw
        ? item.xprofile.groups[1].fields[34].value.raw
        : '';
    const lastName =
      item &&
        item.xprofile &&
        item.xprofile.groups &&
        item.xprofile.groups[1] &&
        item.xprofile.groups[1].fields &&
        item.xprofile.groups[1].fields[35] &&
        item.xprofile.groups[1].fields[35].value &&
        item.xprofile.groups[1].fields[35].value.raw
        ? item.xprofile.groups[1].fields[35].value.raw
        : '';
    data = {
      avatar: item && item.avatar_urls && item.avatar_urls.full ? item.avatar_urls.full : '',
      ciudad1:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[4] &&
          item.xprofile.groups[4].fields &&
          item.xprofile.groups[4].fields[48] &&
          item.xprofile.groups[4].fields[48].value &&
          item.xprofile.groups[4].fields[48].value.raw
          ? item.xprofile.groups[4].fields[48].value.raw
          : '',
      ciudad2:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[5] &&
          item.xprofile.groups[5].fields &&
          item.xprofile.groups[5].fields[58] &&
          item.xprofile.groups[5].fields[58].value &&
          item.xprofile.groups[5].fields[58].value.raw
          ? item.xprofile.groups[5].fields[58].value.raw
          : '',
      estado1:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[4] &&
          item.xprofile.groups[4].fields &&
          item.xprofile.groups[4].fields[49] &&
          item.xprofile.groups[4].fields[49].value &&
          item.xprofile.groups[4].fields[49].value.raw
          ? item.xprofile.groups[4].fields[49].value.raw
          : '',
      colonia1:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[4] &&
          item.xprofile.groups[4].fields &&
          item.xprofile.groups[4].fields[50] &&
          item.xprofile.groups[4].fields[50].value &&
          item.xprofile.groups[4].fields[50].value.raw
          ? item.xprofile.groups[4].fields[50].value.raw
          : '',
      estado2:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[5] &&
          item.xprofile.groups[5].fields &&
          item.xprofile.groups[5].fields[59] &&
          item.xprofile.groups[5].fields[59].value &&
          item.xprofile.groups[5].fields[59].value.raw
          ? item.xprofile.groups[5].fields[59].value.raw
          : '',
      colonia2:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[5] &&
          item.xprofile.groups[5].fields &&
          item.xprofile.groups[5].fields[60] &&
          item.xprofile.groups[5].fields[60].value &&
          item.xprofile.groups[5].fields[60].value.raw
          ? item.xprofile.groups[5].fields[60].value.raw
          : '',
      empresa:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[6] &&
          item.xprofile.groups[6].fields &&
          item.xprofile.groups[6].fields[63] &&
          item.xprofile.groups[6].fields[63].value &&
          item.xprofile.groups[6].fields[63].value.raw
          ? item.xprofile.groups[6].fields[63].value.raw
          : '',
      giro:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[6] &&
          item.xprofile.groups[6].fields &&
          item.xprofile.groups[6].fields[65] &&
          item.xprofile.groups[6].fields[65].value &&
          item.xprofile.groups[6].fields[65].value.raw
          ? item.xprofile.groups[6].fields[65].value.raw
          : '',
      especialidad1:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[3] &&
          item.xprofile.groups[3].fields &&
          item.xprofile.groups[3].fields[71] &&
          item.xprofile.groups[3].fields[71].value &&
          item.xprofile.groups[3].fields[71].value.raw
          ? item.xprofile.groups[3].fields[71].value.raw
          : '',
      especialidad2:
        item &&
          item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[3] &&
          item.xprofile.groups[3].fields &&
          item.xprofile.groups[3].fields[72] &&
          item.xprofile.groups[3].fields[72].value &&
          item.xprofile.groups[3].fields[72].value.raw
          ? item.xprofile.groups[3].fields[72].value.raw
          : '',
      id: Number(item.id),
      name: name + ' ' + firstName + ' ' + lastName,
      type: item && item.member_types && item.member_types[0] ? item.member_types[0] : '',
      roles: item && item.roles && item.roles[0] ? item.roles[0] : '',
      email: item && item.user_login != '' ? item.user_login : ''
    };
    return data;
  }

  tConvert(time: any) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  randomChain(length: number): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  initEqualsObject() {
    // Object['equals'] = function(x: any, y: any) {
    //   if (x === y) {
    //     return true;
    //   }
    //   if (!(x instanceof Object) || !(y instanceof Object)) {
    //     return false;
    //   }
    //   if (x.constructor !== y.constructor) {
    //     return false;
    //   }
    //   for (var p in x) {
    //     if (!x.hasOwnProperty(p)) {
    //       continue;
    //     }
    //     if (!y.hasOwnProperty(p)) {
    //       return false;
    //     }
    //     if (x[p] === y[p]) {
    //       continue;
    //     }
    //     if (typeof x[p] !== 'object') {
    //       return false;
    //     }
    //     if (!Object['equals'](x[p], y[p])) {
    //       return false;
    //     }
    //   }
    //   for (p in y) {
    //     if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
    //       return false;
    //     }
    //   }
    //   return true;
    // };
  }

  mapAdress(data: any): any[] {
    let adress = [];
    const calle1 = data.xprofile.groups[4].fields['43'].value.raw;
    const nExt1 = data.xprofile.groups[4].fields['44'].value.raw;
    const nInt1 = data.xprofile.groups[4].fields['45'].value.raw;
    const cp1 = data.xprofile.groups[4].fields['46'].value.raw;
    const col1 = data.xprofile.groups[4].fields['47'].value.raw;
    const estado1 = data.xprofile.groups[4].fields['49'].value.raw;
    const delegacion1 = data.xprofile.groups[4].fields['50'].value.raw;
    const hospital1 = data.xprofile.groups[4].fields['51'].value.raw;
    const torre1 = data.xprofile.groups[4].fields['52'].value.raw;
    let adress1: string =
      calle1 +
      ' ' +
      nExt1 +
      ', No. Int. ' +
      nInt1 +
      ', Col. ' +
      col1 +
      ', ' +
      delegacion1 +
      ', ' +
      estado1 +
      ', C.P. ' +
      cp1 +
      ', Hospital: ' +
      hospital1 +
      ', Torre: ' +
      torre1;
    const calle2 = data.xprofile.groups[5].fields['53'].value.raw;
    const nExt2 = data.xprofile.groups[5].fields['54'].value.raw;
    const nInt2 = data.xprofile.groups[5].fields['55'].value.raw;
    const cp2 = data.xprofile.groups[5].fields['56'].value.raw;
    const col2 = data.xprofile.groups[5].fields['57'].value.raw;
    const estado2 = data.xprofile.groups[5].fields['59'].value.raw;
    const delegacion2 = data.xprofile.groups[5].fields['60'].value.raw;
    const hospital2 = data.xprofile.groups[5].fields['61'].value.raw;
    const torre2 = data.xprofile.groups[5].fields['62'].value.raw;
    let adress2: string =
      calle2 +
      ' ' +
      nExt2 +
      ', No. Int. ' +
      nInt2 +
      ', Col. ' +
      col2 +
      ', ' +
      delegacion2 +
      ', ' +
      estado2 +
      ', C.P. ' +
      cp2 +
      ', Hospital: ' +
      hospital2 +
      ', Torre: ' +
      torre2;

    if (cp1 && cp1 !== '' && cp1 !== null && cp1 !== undefined) {
      adress.push({ adress: adress1, id: 4 });
    }
    if (cp2 && cp2 !== '' && cp2 !== null && cp2 !== undefined) {
      adress.push({ adress: adress2, id: 5 });
    }
    return adress;
  }

  replaceInHtml(html: string): string {
    let newHtml = html.replace(/href=/gi, ' class="iab-disabled" target="_blank" href=');
    newHtml = newHtml.replace(/<img/gi, '<img class="iab-disabled" ');
    newHtml = newHtml.replace(/<table/gi, '<div class="scroll-overflow-on-table"><table');
    newHtml = newHtml.replace(/<\/table>/gi, '</table></div>');
    return newHtml;
  }

  stringSearch(str: string, whiteSpaces?: boolean): string {
    str = str.trim();
    var noTildes: string = this.removeAccents(str);
    let regexp: RegExp = /[^a-zA-Z0-9]/g;
    if (whiteSpaces === true) {
      regexp = /[^a-zA-Z0-9 ]/g;
    }
    let search: string = noTildes.replace(regexp, '').toLocaleLowerCase();
    search = search.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
    return search;
  }

  public getEmails(emails: string): string[] {
    var str = String(emails).toLocaleLowerCase();
    var res = str.replace(/\n/gi, ',');
    res = res.replace(';', ',');
    res = res.replace(/ /g, ',');
    let idsString: string[] = res.split(',');
    let idsRes: string[];
    idsRes = idsString.map((item: string) => {
      return String(item);
    });
    idsRes = idsRes.filter(item => {
      const re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(item);
      if (typeof item === 'string' && re == true) {
        return item;
      }
      return undefined;
    });
    idsRes = idsRes.filter((valor, index, arreglo) => arreglo.indexOf(valor) === index);
    return idsRes;
  }

  public getIDS(emails: string): string[] {
    var str = String(emails);
    var res = str.replace(/\n/gi, ',');
    res = res.replace(';', ',');
    res = res.replace(/ /g, ',');
    let idsString: string[] = res.split(',');
    let idsRes: string[];
    idsRes = idsString.map((item: string) => {
      return String(item);
    });
    idsRes = idsRes.filter(item => {
      const re = /^([a-zA-Z0-9])+$/i.test(item);
      if (typeof item === 'string' && re == true) {
        return item;
      }
      return undefined;
    });
    idsRes = idsRes.filter((valor, index, arreglo) => arreglo.indexOf(valor) === index);
    return idsRes;
  }

  async presentAlert(message?: string, header?: string, subHeader?: string) {
    const alert = await this.alertCtrl.create({
      header: header ? header : '',
      subHeader: subHeader ? subHeader : '',
      message: message ? message : '',
      buttons: ['Ok']
    });
    alert.present();
  }

  public async arraySearchRequest(text: string | number): Promise<string[]> {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json'
      };
      const resp: any = await this.http
        .post(
          `${FIREBASE_API}getSearchArray`,
          {
            text: text
          },
          { headers: headers }
        );
      if (resp && resp.search_array) {
        return resp.search_array;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }
}
