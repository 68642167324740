import { FormControl } from '@angular/forms';

export class ValidateAnalyticLabel {
  static isValid(control: FormControl) {
    const LABEL_REGEXP = /^[a-zA-Z0-9-_.~%]{1,50}$/;
    let isValid: boolean;
    if (LABEL_REGEXP.test(control.value) || control.value === '') {
      isValid = true;
    }
    return isValid === true ? null : { noValidLabel: true };
  }
}
