import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('UserInformationComponent');

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent implements OnInit {
  uid: string;
  user: any;
  isLoading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private firebaseService: FirebaseService
  ) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.isLoading = true;
    try {
      this.user = await this.firebaseService.getUserData(this.uid);
      let metadata = {};
      switch (this.user.type) {
        case 'representante-medico':
          metadata = await this.firebaseService.getRepData(this.uid);
          break;
      }
      this.user.metadata = metadata;
      log.debug(this.user);
    } catch (e) {
      log.error(e);
    }
    this.isLoading = false;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async onChangeOpenToHealthProfessionals(event: any) {
    this.isLoading = true;
    try {
      let value = false;
      if (event && event.detail && event.detail.checked && event.detail.checked === true) {
        value = true;
      }
      await this.firebaseService.afs.doc(`users/${this.uid}`).update({ openToHealthProfessionals: value });
    } catch (e) {
      log.error(e);
    }
    this.isLoading = false;
  }

}
