import { Component, OnInit } from '@angular/core';
import { AngularFirestore, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@app/core';
import { SearchComponent } from '@app/search/search.component';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { DatabaseService } from '../../shared/services/database.service';
import { from } from 'rxjs';
import { FirebaseService } from '../../shared/services/firebase.service';
const log = new Logger('MembersPlanComponent');

@Component({
  selector: 'app-members-plan',
  templateUrl: './members-plan.component.html',
  styleUrls: ['./members-plan.component.scss']
})
export class MembersPlanComponent implements OnInit {
  plan: any;
  isLoading: boolean = false;
  id: string;
  public mainArray: any[] = [];
  private maxMedSize: number = 500;
  private limit: number = 200;
  public cursorNext: QueryDocumentSnapshot<any>;

  constructor(
    private aRoute: ActivatedRoute,
    private afs: AngularFirestore,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private databaseService: DatabaseService,
    private alertController: AlertController,
    private firebase: FirebaseService
  ) {
    this.aRoute.params.subscribe(params => {
      if (params && params.id) {
        this.id = String(params.id);
        this.initPlan();
      }
    });
  }

  ngOnInit() {}

  private async initPlan() {
    try {
      await this.paginate();
      const RESP = await this.afs.doc(`plans/${this.id}`).ref.get();
      const DATA: any = RESP.data();
      this.plan = { id: this.id, ...DATA };
    } catch (e) {
      log.error(e);
    }
    return;
  }

  async selectUsersFunction(type?: 'medico' | 'representante-medico') {
    try {
      const data: any[] = this.mainArray.map(e => e);
      const modal = await this.modalCtrl.create({
        component: SearchComponent,
        componentProps: {
          viewType: 'modal',
          memberType: 'medico',
          searchMemberType: type
        }
      });
      modal.onDidDismiss().then(async users => {
        this.isLoading = true;
        const loadingOverlay = await this.loadingController.create({});
        from(loadingOverlay.present());
        if (users.data && users.data.users) {
          let array: any[] = Array.from(users.data.users);
          if (array.length > this.maxMedSize) {
            array = array.splice(0, this.maxMedSize);
          }
          await this.databaseService.updatePlanRep(this.id, 'array', array);
          await this.reset();
        }
        loadingOverlay.dismiss();
        this.isLoading = false;
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  public async paginate(direction?: string) {
    try {
      let query: Query = this.afs
        .collection('users')
        .ref.where('plan', '==', String(this.id))
        .where('type', '==', 'representante-medico')
        .orderBy('nameStr', 'asc');
      if (this.cursorNext && direction === 'forward') {
        query = query.startAfter(this.cursorNext).limit(this.limit);
      } else {
        query = query.limit(this.limit);
      }
      const RESP = await query.get();
      this.mainArray = this.mainArray.concat(
        RESP.docs.map(element => {
          const DATA: any = element.data();
          const ID: string = element.id;
          return { id: ID, ...DATA };
        })
      );
      this.cursorNext = RESP.empty === false ? RESP.docs[RESP.size - 1] : undefined;
    } catch (e) {
      log.error(e);
    }
    return;
  }

  private async reset() {
    try {
      this.mainArray = [];
      this.cursorNext = undefined;
      await this.paginate();
    } catch (e) {
      log.error(e);
    }
    return;
  }

  public async delete(id: string, index: number) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    from(loadingOverlay.present());
    try {
      const RESP1 = await this.afs
        .collection('plans')
        .ref.where('default', '==', true)
        .get();
      const ARRAY_PLAN = RESP1.docs.map(element => {
        const DATA: any = element.data();
        const ID: string = element.id;
        return { id: ID, ...DATA };
      });
      const DEFAULT_PLAN = ARRAY_PLAN && ARRAY_PLAN[0] ? ARRAY_PLAN[0].id : undefined;
      log.debug(DEFAULT_PLAN);
      const MY_PLAN = (await this.afs.doc(`users/${id}`).ref.get()).get('plan');
      if (DEFAULT_PLAN === MY_PLAN) {
        this.canNotDelete();
      } else {
        await this.databaseService.singleRepToDefaultPlan(id);
        this.mainArray.splice(index, 1);
      }
    } catch (e) {
      log.error(e);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
    return;
  }

  async confirmDelete(id: string, index: number) {
    const alert = await this.alertController.create({
      header: `¿Está seguro de borrar a éste miembro del Plan?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Borrar',
          handler: () => {
            this.delete(id, index);
          }
        }
      ]
    });
    await alert.present();
  }

  async canNotDelete() {
    const alert = await this.alertController.create({
      header: `No se puede borrar a usuario que pertenece al plan 'Default'`,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
    await alert.present();
  }
}
