import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCompanyComponent } from './search-company.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AddCompanyComponent } from './add-company/add-company.component';

@NgModule({
  declarations: [SearchCompanyComponent, AddCompanyComponent],
  exports: [SearchCompanyComponent, AddCompanyComponent],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule]
})
export class SearchCompanyModule {}
