<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">
      <p *ngIf="member(chat) as member">
        {{
        (member.xprofile?.groups)[1].fields[33].value.raw +
        ' ' +
        (member.xprofile?.groups)[1].fields[34].value.raw +
        ' ' +
        (member.xprofile?.groups)[1].fields[35].value.raw
        }}
      </p>
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content #chatBox id="chatBox" [scrollEvents]="true">
  <ion-grid fixed class="ion-no-padding">
    <ion-row *ngFor="let item of messages">
      <ion-col size="10" [offset]="item && item.sender_id == me ? 2 : 0">
        <ion-card color="light">
          <ion-card-header>
            <ion-label [color]="item && item.sender_id == me ? 'tertiary' : ''">
              <h2>
                <strong *ngIf="member(item.sender_id) as member">
                  {{
                  (member.xprofile?.groups)[1].fields[33].value.raw +
                  ' ' +
                  (member.xprofile?.groups)[1].fields[34].value.raw +
                  ' ' +
                  (member.xprofile?.groups)[1].fields[35].value.raw
                  }}
                </strong>
              </h2>
            </ion-label>
          </ion-card-header>
          <ion-card-content>
            <div [innerHTML]="item.message"></div>
            <ion-label color="primary">
              <h3>
                <strong>{{ item.date }}</strong>
              </h3>
            </ion-label>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <input id="file1" class="hide" #file1 name="file1" accept="image/*" type="file" name="file"
    (change)="selectFile1($event?.target)" />

  <input id="file2" class="hide" #file2 name="file2" type="file" name="file" (change)="selectFile2($event?.target)" />

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="tertiary">
      <ion-icon name="attach"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button (click)="triggerClick1()">
        <ion-icon name="images"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="takePicture()">
        <ion-icon name="camera"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="triggerClick2()">
        <ion-icon name="document"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ion-footer>
  <ion-item class="ion-padding-left input-chat" color="secondary" lines="none">
    <ion-textarea [(ngModel)]="message" placeholder="Escribe tu mensaje..."></ion-textarea>
    <ion-button slot="end" fill="clear" (click)="sendMessage2()">
      <ion-icon slot="icon-only" name="send"></ion-icon>
    </ion-button>
  </ion-item>
</ion-footer>