import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, AlertController, ToastController } from '@ionic/angular';
import { Logger } from '@app/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('Login');

export interface Company {
  uid: string;
  name: string;
  businessType: string;
  companyWebsite: string;
}

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  public dataForm: FormGroup;
  public isLoading: boolean;
  // private company: Company;
  // private companyData: any[];

  constructor(
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private firebase: FirebaseService,
    private modalController: ModalController,
    private utilities: UtilitiesService,
    private toastController: ToastController
  ) {
    this.initForm();
    this.initData();
  }

  async presentAlertConfirm() {
    const alert = await this.toastController.create({
      header: 'Error al crear empresa',
      message: 'La empresa ya ha sido creada anteriormente, para continuar, usa el buscador y selecciona esta empresa.',
      buttons: [
        {
          text: 'ok'
        }
      ]
    });

    await alert.present();
  }

  ngOnInit() {}

  private initForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', Validators.required],
      companyWebsite: [''],
      businessType: ['']
    });
  }

  refillForm(data: any) {
    this.dataForm.setValue({
      name: data && data.name ? data.name : '',
      companyWebsite: data && data.companyWebsite ? data.companyWebsite : '',
      businessType: data && data.businessType ? data.businessType : ''
    });
  }

  public async initData(): Promise<void> {
    // this.isLoading = true;
    // const loadingOverlay = await this.loadingController.create({});
    // loadingOverlay.present();
    // try {
    //   const data = await this.firebase.getRepData(this.user.uid);
    //   this.company = data.company;
    //   this.refillForm(this.company);
    // } catch (error) {
    //   log.error(error);
    // }
    // loadingOverlay.dismiss();
    // this.isLoading = false;
  }

  public async submit(): Promise<void> {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      let data = {
        name: this.dataForm.get('name').value,
        companyWebsite: this.dataForm.get('companyWebsite').value,
        businessType: this.dataForm.get('businessType').value,
        status: 'active',
        nameStr: '',
        slug: '',
        search: ['']
      };
      data.nameStr = '';
      data.search = [];
      const response = await this.firebase.createCompany(data);
      if (response && response.id) {
        this.closeModal(response);
      } else {
        this.presentAlertConfirm();
      }
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  closeModal(data?: any) {
    this.modalController.dismiss(data);
  }
}
