<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/chat"></ion-back-button>
    </ion-buttons>
    <ion-title class="ion-text-center"> Mensaje de chat</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding" *ngIf="visible !== true">
    <ion-row>
      <ion-col size="12">
        <ion-button (click)="selectUsersFunction()" expand="block" [disabled]="isLoading === true">
          <ion-icon slot="start" name="add"></ion-icon>
          Seleccionar representante
        </ion-button>
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ rep?.name }} {{ rep?.lastName1 }} {{ rep?.lastName2 }}</ion-card-title>
            <ion-card-subtitle>{{ rep?.company?.name }}</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <ion-button
          expand="block"
          color="secondary"
          (click)="visible = !visible"
          [disabled]="isLoading === true || !rep"
        >
          Redactar Mensaje
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-button>

        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          (change)="selectFile($event)"
        />

        <ion-button (click)="doUpload()" shape="round" expand="block" [disabled]="isLoading === true || !rep || !file">
          Enviar mensaje
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <app-chat-group *ngIf="visible === true" (save)="chatGroup($event)"></app-chat-group>
</ion-content>
