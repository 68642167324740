import { FIREBASE_API } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import * as moment from 'moment';
import { LoadingController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
const log = new Logger('ClickMeetingComponent');

@Component({
  selector: 'app-click-meeting',
  templateUrl: './click-meeting.component.html',
  styleUrls: ['./click-meeting.component.scss']
})
export class ClickMeetingComponent implements OnInit {
  public conferences: any[] = [];
  public conferenceStatus: 'inactive' | 'active' = 'inactive';

  constructor(private http: HttpClient, private utilities: UtilitiesService, private loadingCtrl: LoadingController) {}

  ngOnInit() {
    this.initClickMeeting();
  }

  async initClickMeeting(conferenceStatus: 'inactive' | 'active' = 'inactive') {
    this.conferenceStatus = conferenceStatus;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const resp: any = await this.http
        .post(`${FIREBASE_API}clickMeetingV2`, { route: `conferences/${conferenceStatus}` }, { headers: headers })
        .toPromise();
      let conferences = Array.from(resp.response).map((e: any) => {
        let data = e;
        data.order = moment(data.starts_at).valueOf();
        data.inicio = moment(data.starts_at).format('YYYY/MM/DD HH:mm');
        data.fin = moment(data.ends_at).format('YYYY/MM/DD HH:mm');
        data.trustHtml = this.utilities.trustHtml(e.lobby_description);
        return data;
      });
      let sortedOrder = [];
      if (conferenceStatus === 'inactive') {
        sortedOrder = conferences.sort((r1, r2) => (r1.order < r2.order ? 1 : r1.order > r2.order ? -1 : 0));
      } else {
        sortedOrder = conferences.sort((r1, r2) => (r1.order > r2.order ? 1 : r1.order < r2.order ? -1 : 0));
      }
      this.conferences = sortedOrder;
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
  }

  public changeStatus(ev: any) {
    if (ev.detail.checked === true) {
      this.initClickMeeting('active');
    } else {
      this.initClickMeeting('inactive');
    }
  }
}
