import { Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentData, Query, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core';
import { UserOptionsComponent } from './user-options/user-options.component';
import { IonContent, LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
const log = new Logger('UsersActionsComponent');
const LIMIT: number = 100;
const ORDER_By: string = 'nameStr';

@Component({
  selector: 'app-users-actions',
  templateUrl: './users-actions.component.html',
  styleUrls: ['./users-actions.component.scss']
})
export class UsersActionsComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) ionContent: IonContent;
  public users: any[] = [];
  public isLoading: boolean = false;
  public cursorNext: QueryDocumentSnapshot<any>;
  public cursorBack: QueryDocumentSnapshot<any>;
  public searchForm: FormGroup;

  constructor(
    private afs: AngularFirestore,
    private popoverController: PopoverController,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private firebaseService: FirebaseService,
    private formBuilder: FormBuilder
  ) {
    this.initForm();
  }

  initForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.initializeApp();
  }

  private setQueries() {
    let query = this.afs.collection(`users`).ref; //.where('type', '==', 'medico');
    return query;
  }

  public async paginate(direction: 'next' | 'back') {
    this.isLoading = true;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      await this.getUsers(direction);
      this.searchForm.patchValue({ search: '' });
      this.ionContent.scrollToTop(500);
    } catch (e) {
      log.error(log);
    }
    loading.dismiss();
    this.isLoading = false;
  }

  public async getUsers(direction?: 'next' | 'back'): Promise<void> {
    let arrayDocs: any[] = [];
    let query: Query = this.setQueries();

    if (this.cursorNext && direction === 'next') {
      query = query
        .orderBy(ORDER_By, 'asc')
        .startAfter(this.cursorNext)
        .limit(LIMIT);
    } else if (this.cursorBack && direction === 'back') {
      query = query
        .orderBy(ORDER_By, 'asc')
        .startAt(this.cursorBack)
        .limit(LIMIT);
    } else {
      query = query.orderBy(ORDER_By, 'asc').limit(LIMIT);
    }
    try {
      const snap = await query.get();
      this.isLoading = true;
      this.cursorNext = snap.docs.length > 0 ? snap.docs[snap.docs.length - 1] : undefined;
      let snapBack: QuerySnapshot<DocumentData>;
      if (snap && snap.docs[0]) {
        const queryBack = this.setQueries();
        snapBack = await queryBack
          .orderBy(ORDER_By, 'desc')
          .startAfter(snap.docs[0])
          .limit(LIMIT)
          .get();
      } else {
        return;
      }
      this.cursorBack =
        snapBack && snapBack.docs && snapBack.docs.length > 0 ? snapBack.docs[snapBack.docs.length - 1] : undefined;
      arrayDocs = snap.docs.map((e: any) => {
        let data: any = e.data();
        const UID: string = e.id;
        return { ...data, uid: UID };
      });
      this.users = arrayDocs;
      this.isLoading = false;
    } catch (error) {
      log.error(error);
    }
  }

  public async initializeApp() {
    this.isLoading = true;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      await this.getUsers();
      this.searchForm.patchValue({ search: '' });
    } catch (e) {
      log.error(log);
    }
    loading.dismiss();
    this.isLoading = false;
  }

  public async options(uid: string, event: any) {
    try {
      const popover = await this.popoverController.create({
        component: UserOptionsComponent,
        event: event,
        translucent: true,
        componentProps: { uid: uid }
      });
      await popover.present();
      await popover.onDidDismiss().then(data => {
        if (data && data.role && data.role === 'deleted' && data.data && data.data.uid) {
          const index: number = this.users.map(e => e.uid).indexOf(data.data.uid);
          if (index > -1) {
            this.users.splice(index, 1);
          }
        }
        if (data && data.role && data.role === 'disabled' && data.data) {
          const index: number = this.users.map(e => e.uid).indexOf(data.data.uid);
          if (index > -1) {
            this.users[index].status = data && data.data && data.data.disabled === true ? 'inactive' : 'active';
          }
        }
      });
    } catch (e) {
      log.error(e);
    }
  }

  public async detail(uid: string, event: any) {
    event;
    try {
      const modal = await this.modalCtrl.create({
        component: UserDetailComponent,
        componentProps: { uid: uid },
        backdropDismiss: false
      });
      modal.onDidDismiss().then(data => {
        if (data && data.role && data.role === 'refresh' && data.data) {
          const index: number = this.users.map(e => e.uid).indexOf(data.data.uid);
          if (index > -1) {
            this.users[index].status = data && data.data && data.data.disabled === true ? 'inactive' : 'active';
            this.users[index].email = data && data.data && data.data.email ? data.data.email : '';
          }
        }
        if (data && data.role && data.role === 'deleted' && data.data && data.data.uid) {
          const index: number = this.users.map(e => e.uid).indexOf(data.data.uid);
          if (index > -1) {
            this.users.splice(index, 1);
          }
        }
      });
      return await modal.present();
    } catch (e) {
      log.error(e);
    }
  }

  public async onSearchChange() {
    const search: string = this.searchForm.value.search;
    if (search) {
      if (search.length > 6) {
        this.search(search);
      }
    }
  }

  public onSearchClear(event: any) {
    event;
    this.initializeApp();
  }

  public async search(search: string) {
    this.isLoading = true;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      this.users = [];
      const respUid = await this.firebaseService.callFunctionFB('getAuthUser', { uid: search });
      const respEmail = await this.firebaseService.callFunctionFB('getAuthUserByEmail', { email: search });
      if (respUid && respUid.uid) {
        const uid: string = respUid.uid;
        const user = await this.getUser(uid);
        if (user && user.uid) {
          this.users.push(user);
        }
      }
      if (respEmail && respEmail.uid) {
        const uid: string = respEmail.uid;
        const user = await this.getUser(uid);
        if (user && user.uid) {
          this.users.push(user);
        }
      }
    } catch (e) {
      log.error(log);
    }
    loading.dismiss();
    this.isLoading = false;
  }

  private async getUser(uid: string) {
    try {
      const resp: any = await this.afs
        .collection('users')
        .doc(uid)
        .ref.get();
      return { ...resp.data(), uid: resp.id };
    } catch (e) {
      log.error(log);
      return;
    }
  }
}
