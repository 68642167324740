import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WpService } from '@app/shared/services/wp.service';
import { FirebaseService } from '@app/shared/services/firebase.service';

@Component({
  selector: 'app-search-by-company-v2',
  templateUrl: './search-by-company-v2.component.html',
  styleUrls: ['./search-by-company-v2.component.scss']
})
export class SearchByCompanyV2Component implements OnInit {
  public myArray: any[] = [];
  arrayData: any[] = [];
  isLoading: boolean;
  values: any;

  constructor(private modalCtrl: ModalController, private wp: WpService, private fb: FirebaseService) { }

  ngOnInit() {
    this.init();
  }

  private async init(): Promise<void> {
    this.isLoading = true;
    const raw: any = await this.fb.getCompanies();

    this.myArray = raw.docs.map((element: any) => {
      const data: any = element.data();
      return { id: element.id, label: data.name, isChecked: false };
    });
    this.isLoading = false;
  }

  selectItem(item: any) {
    item.isChecked = true;
    const element = [item];
    this.modalCtrl.dismiss({ arrayData: element });
  }

  refillData() {
    if (this.arrayData && this.arrayData[0]) {
      this.arrayData.forEach(element => {
        const index: number = this.myArray.map(e => e.id).indexOf(element.id);
        if (index > -1) {
          this.myArray[index].isChecked = element.isChecked;
        }
      });
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({ arrayData: this.arrayData });
  }

  clearFilter() {
    this.modalCtrl.dismiss({ arrayData: [] });
  }
}
