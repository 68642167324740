import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() item: any;
  @Input() index?: number;
  @Input() color: string;

  constructor() {}

  ngOnInit() {
    var str: string = String(this.item.avatar);
    var n = str.search('www.gravatar.com');
    if (n > -1) {
      this.item.avatar = undefined;
    }
  }
}
