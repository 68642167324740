import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PostsReviewRoutingModule } from './posts-review-routing.module';
import { PostsReviewComponent } from './posts-review.component';

@NgModule({
  imports: [CommonModule, IonicModule, PostsReviewRoutingModule],
  declarations: [PostsReviewComponent]
})
export class PostsReviewModule { }
