import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './core/authentication/authentication.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    data: {
      user: ['administrador', 'editor', 'encuestas', 'validador', 'validadorAdmin']
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {
      user: ['administrador', 'editor', 'encuestas', 'validador', 'validadorAdmin']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
    data: {
      user: ['administrador', 'editor', 'encuestas', 'validador', 'validadorAdmin']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'activate-users',
    loadChildren: () => import('./activate-member/activate-member.module').then(m => m.ActivateMemberModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'activate-members',
    loadChildren: () => import('./activate-member-v2/activate-member-v2.module').then(m => m.ActivateMemberV2Module),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'activate-rep',
    loadChildren: () => import('./activate-rep/activate-rep.module').then(m => m.ActivateRepModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'advertising',
    loadChildren: () => import('./advertising/advertising.module').then(m => m.AdvertisingModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'assign-contacts-v2',
    loadChildren: () => import('./assign-contacts-v2/assign-contacts-v2.module').then(m => m.AssignContactsV2Module),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'banner-click',
    loadChildren: () => import('./click-banner-logs/click-banner-logs.module').then(m => m.ClickBannerLogsModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'click-meeting-reports',
    loadChildren: () => import('./click-meeting/click-meeting.module').then(m => m.ClickMeetingModule),
    data: {
      user: ['administrador', 'editor']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'companies',
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'disable-medical-sample',
    loadChildren: () => import('./disable-medical-sample/disable-medical-sample.module').then(m => m.DisableMedicalSampleModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'generate-url',
    loadChildren: () => import('./generate-url/generate-url.module').then(m => m.GenerateUrlModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'import',
    loadChildren: () => import('./import/import.module').then(m => m.ImportModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'import-posts',
    loadChildren: () => import('./import-posts/import-posts.module').then(m => m.ImportPostsModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'medical-forum',
    loadChildren: () => import('./medical-forum/medical-forum.module').then(m => m.MedicalForumModule),
    data: {
      user: ['administrador', 'editor']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'plans',
    loadChildren: () => import('./plans/plans.module').then(m => m.PlansModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'points',
    loadChildren: () => import('./points/points.module').then(m => m.PointsModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'posts-info',
    loadChildren: () => import('./posts-info/posts-info.module').then(m => m.PostsInfoModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'preregistro',
    loadChildren: () => import('./preregistro/preregistro.module').then(m => m.PreregistroModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'push-notifcations',
    loadChildren: () => import('./push-notifications/push-notifications.module').then(m => m.PushNotificationsModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'surveys',
    loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'surveys-and-tests',
    loadChildren: () => import('./surveys-and-tests/surveys-and-tests.module').then(m => m.SurveysAndTestsModule),
    data: {
      user: ['administrador', 'encuestas']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'users-actions',
    loadChildren: () => import('./users-actions/users-actions.module').then(m => m.UsersActionsModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'validation-of-information',
    loadChildren: () => import('./validation-of-information/validation-of-information.module').then(m => m.ValidationOfInformationModule),
    data: {
      user: ['administrador', 'validador', 'validadorAdmin']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'zipcodes-suggestion',
    loadChildren: () => import('./zipcodes-suggestion/zipcodes-suggestion.module').then(m => m.ZipcodesSuggestionModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'posts-review',
    loadChildren: () => import('./posts-review/posts-review.module').then(m => m.PostsReviewModule),
    data: {
      user: ['administrador']
    },
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'shared-survey-results',
    loadChildren: () => import('./shared-survey-results/shared-survey-results.module').then(m => m.SharedSurveyResultsModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
