<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Alertas Conectimed</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-margin ion-no-padding" color="light">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card *ngFor="let item of surveys">
          <ion-card-header>
            <ion-toolbar>
              <ion-title>{{ item?.title }}</ion-title>
              <ion-buttons slot="end">
                <ion-button color="primary" (click)="add(item?.id)">
                  <ion-icon slot="icon-only" name="create"></ion-icon>
                </ion-button>
                <ion-button color="danger" (click)="deleteAlert(item?.id)">
                  <ion-icon slot="icon-only" name="trash"></ion-icon>
                </ion-button>
                <ion-toggle
                  [disabled]="isLoading === true"
                  (ionChange)="desactive($event, item?.id)"
                  [checked]="item?.status === 'active' ? true : false"
                ></ion-toggle>
              </ion-buttons>
            </ion-toolbar>
          </ion-card-header>
          <ion-card-content>
            <p>{{ item?.text }}</p>
            <p>
              <strong>URL: {{ item?.url }}</strong>
            </p>
            <div>
              <hr />
              <h4 class="ion-margin">Botónes:</h4>
              <hr />
              <ion-chip [color]="item?.buttons?.cancel?.show === true ? 'primary' : 'medium'" mode="ios" outline="true">
                <ion-icon [name]="item?.buttons?.cancel?.show === true ? 'eye' : 'eye-off'"></ion-icon>
                <ion-label>{{ item?.buttons?.cancel?.text }}</ion-label>
              </ion-chip>

              <ion-chip
                [color]="(item?.buttons)['remember-later']?.show === true ? 'primary' : 'medium'"
                mode="ios"
                outline="true"
              >
                <ion-icon [name]="(item?.buttons)['remember-later']?.show === true ? 'eye' : 'eye-off'"> </ion-icon>
                <ion-label>{{ (item?.buttons)['remember-later']?.text }}</ion-label>
              </ion-chip>

              <ion-chip [color]="item?.buttons?.acept?.show === true ? 'primary' : 'medium'" mode="ios" outline="true">
                <ion-icon [name]="item?.buttons?.acept?.show === true ? 'eye' : 'eye-off'"></ion-icon>
                <ion-label>{{ item?.buttons?.acept?.text }}</ion-label>
              </ion-chip>
            </div>
            <div>
              <ion-badge *ngFor="let target of item?.topics" color="primary" mode="ios">
                {{
                  target === 'representante-medico'
                    ? 'Representantes médicos'
                    : target === 'medico'
                    ? 'Médicos'
                    : 'Todos'
                }}
              </ion-badge>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="tertiary" (click)="add()" [disabled]="isLoading == true">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
