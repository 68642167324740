<div>
  <ion-text>
    <h3>
      <ion-icon [name]="item.type === 'representante-medico' ? 'briefcase' : 'medkit'"></ion-icon>
      <strong>
        {{ (item?.name + ' ' + item?.lastName1 + ' ' + item?.lastName2).toUpperCase() }}
      </strong>
    </h3>
    <div *ngIf="showInfo === true">
      <p><strong>Email: </strong>{{ item?.email }}</p>
      <p><strong>ID: </strong>{{ item?.id }}</p>
    </div>
    <div *ngIf="(item?.metaData) | async as meta" class="ion-text-wrap">
      <div *ngIf="item.type === 'medico'">
        <small *ngIf="parserany(meta)?.specialty1 as data"> <ion-icon name="school"></ion-icon> {{ data?.name }} </small>
        <small *ngIf="parserany(meta)?.specialty2 as data"> <ion-icon name="school"></ion-icon> {{ data?.name }} </small>
        <br />
        <span *ngIf="parserany(meta)?.address1 as address">
          &bull;
          <span *ngIf="address?.state && address?.city">
            <small *ngIf="address.state === 'Ciudad de México'; else otherAddress1">
              {{ address?.state + ', ' + address?.colony }}
            </small>
            <ng-template #otherAddress1>
              <small>
                {{ address?.state + ', ' + address?.city }}
              </small>
            </ng-template>
          </span>
        </span>
        <span *ngIf="parserany(meta)?.address2 as address">
          &bull;
          <span *ngIf="address?.state && address?.city">
            <small *ngIf="address.state === 'Ciudad de México'; else otherAddress2">
              {{ address?.state + ', ' + address?.colony }}
            </small>
            <ng-template #otherAddress2>
              <small>
                {{ address?.state + ', ' + address?.city }}
              </small>
            </ng-template>
          </span>
        </span>
      </div>
      <div *ngIf="item.type === 'representante-medico'">
        <div *ngIf="parserany(meta)?.company as company">
          <p>
            <strong>{{ company.name }}</strong>
          </p>
          <p>{{ company.businessType }}</p>
          <p></p>
        </div>
      </div>
    </div>
  </ion-text>
</div>
