import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core';
import { AlertController, LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { AddPlanComponent } from '../add-plan/add-plan.component';
const log = new Logger('OptionsPlanComponent');
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DatabaseService } from '@app/shared/services/database.service';
@Component({
  selector: 'app-options-plan',
  templateUrl: './options-plan.component.html',
  styleUrls: ['./options-plan.component.scss']
})
export class OptionsPlanComponent implements OnInit {
  public _default: boolean = true;
  public id: string;
  public status: string;
  public isLoading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private afs: AngularFirestore,
    private loadingController: LoadingController,
    private database: DatabaseService,
    private router: Router
  ) {}

  ngOnInit() {}

  public async update() {
    try {
      const modal = await this.modalCtrl.create({
        component: AddPlanComponent,
        componentProps: { id: this.id }
      });
      await this.popoverController.dismiss();
      return await modal.present();
    } catch (e) {
      log.error(e);
    }
  }

  async deleteAlert() {
    try {
      const alert = await this.alertController.create({
        header: '¿Eliminar plan?',
        message: '¿Está seguro de eliminar este plan?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: 'Eliminar',
            handler: () => {
              this.delete();
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      log.error(e);
    }
    return;
  }

  async activateAlert() {
    try {
      const alert = await this.alertController.create({
        header: `¿${this.status === 'active' ? 'Desactivar' : 'Activar'} plan?`,
        message: `¿Está seguro de ${this.status === 'active' ? 'desactivar' : 'activar'} este plan?`,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: `${this.status === 'active' ? 'Desactivar' : 'Activar'} `,
            handler: () => {
              this.activate(this.status);
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      log.error(e);
    }
    return;
  }

  private async delete() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const path: string = `plans/${this.id}`;
      await this.database.allPlanIDRepsToDefaultPlan(this.id);
      await this.afs.doc(path).delete();
    } catch (e) {
      log.error(e);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
    return await this.popoverController.dismiss();
  }

  private async activate(status: string = 'active' || 'incative') {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const path: string = `plans/${this.id}`;
      let data: any = {};
      if (status === 'active') {
        data.inactivation_date = moment().toDate();
      } else {
        data.activation_date = moment().toDate();
      }
      await this.afs.doc(path).update({
        status: status === 'active' ? 'inactive' : 'active',
        ...data
      });
    } catch (e) {
      log.error(e);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
    return await this.popoverController.dismiss();
  }

  public async goTo() {
    try {
      this.router.navigate([`/plans/members/${this.id}`]);
    } catch (e) {
      log.error(e);
    }
    return await this.popoverController.dismiss();
  }
}
