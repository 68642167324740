import { Component, OnInit } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import * as rawChats from './data/chats.json';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { Logger } from '@app/core';
const log = new Logger('Import Chats');

export interface Message {
  message: string;
  isFile: boolean;
  url: string;
  convert: boolean;
}

@Component({
  selector: 'app-import-chats',
  templateUrl: './import-chats.component.html',
  styleUrls: ['./import-chats.component.scss']
})
export class ImportChatsComponent implements OnInit {
  public isLoading = false;
  public _rawChats: any[] = [];
  public percentaje: number = 0;

  constructor(private afs: AngularFirestore, private utilities: UtilitiesService) {
    log.debug('================== HERE ==================');
  }

  async init() {
    this.isLoading = true;
    try {
      this._rawChats = Array.from((rawChats as any).default.rows);
      for (let i = 0; i < this._rawChats.length; i++) {
        try {
          const data = {
            message: this._rawChats[i].message.replace(/(<([^>]+)>)/gi, ''),
            date: new Date(this._rawChats[i].date_sent),
            sender: await this.getUserByWPID(Number(this._rawChats[i].senderIdWP)),
            user: await this.getUserByWPID(Number(this._rawChats[i].otherUser))
          };
          if (data.sender && data.user) {
            this.chat(data.sender, data.user, data.message);
          }
          this.percentaje = (i + 1) / this._rawChats.length;
        } catch (error) {
          log.error(error);
        }
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async getUserByWPID(wp_id: number): Promise<string> {
    let uid: string;
    try {
      const response = await this.afs
        .collection('users')
        .ref.where('wp_id', '==', wp_id)
        .get();
      if (!(response.empty == true)) {
        return response.docs[0].id;
      }
    } catch (error) {
      log.error(error);
    }
    return uid;
  }

  ngOnInit() { }

  import() {
    this.init();
  }

  save() { }

  public async chat(sender: string, userid: string, message: string): Promise<void> {
    this.createChat(sender, userid, [{ message: message, isFile: false, url: '', convert: false }]);
  }

  public async createChat(sender: string, userId: string, messages?: Message[]): Promise<void> {
    const ids: string[] = [String(sender) + String(userId), String(userId) + String(sender)];
    const response = await this.afs
      .collection('chats')
      .ref.where('identifier', 'in', ids)
      .get();
    if (response.empty == false) {
      messages.forEach(element => {
        this.sendChatMessage(
          sender,
          response.docs[0].id,
          element.message,
          element.convert,
          element.isFile,
          element.url
        );
      });
    } else {
      let viewers: any = {};
      viewers[sender] = {
        seen: true,
        news: 0
      };
      viewers[userId] = {
        seen: false,
        news: 0
      };
      const dataSimple1 = await this.getUserData(sender);
      const dataSimple2 = await this.getUserData(userId);
      const member1 = dataSimple1;
      const member2 = dataSimple2;
      const currentDate: Date = new Date();
      let data: any = {
        identifier: ids[0],
        date: currentDate,
        last_message: {
          message: '',
          date: currentDate,
          user: userId,
          viewers: viewers
        },
        initialized: false,
        participants: [String(sender), String(userId)],
        members: [member1, member2]
      };
      const newChat = await this.afs.collection('chats').add(data);
      messages.forEach(element => {
        this.sendChatMessage(sender, newChat.id, element.message, element.convert, element.isFile, element.url);
      });
    }
  }

  public async sendChatMessage(
    sender: string,
    chatId: string,
    message: string,
    convert?: boolean,
    isFile?: boolean,
    url?: string
  ): Promise<any> {
    if (message && message !== '') {
      try {
        let uid: string;
        if (convert === true) {
          message = this.utilities.urlify(message, true);
        }
        const chatData: any = (
          await this.afs
            .collection('chats')
            .doc(chatId)
            .ref.get()
        ).data();
        const participants: string[] = Array.from(chatData.members).map((item: any) => String(item.uid));
        let viewers: any =
          chatData && chatData.last_message && chatData.last_message.viewers ? chatData.last_message.viewers : {};
        let newViewers: any = {};
        const ArrayViewers: string[] = Object.keys(viewers);
        ArrayViewers.forEach(item => {
          let data = viewers[item];
          if (String(item) === String(sender)) {
            data = {
              news: 0,
              seen: true
            };
          } else {
            uid = String(item);
            const news: number = Number(data.news) + 1;
            data = {
              news: news,
              seen: false
            };
          }
          newViewers[item] = data;
        });
        let last_message: any = {
          date: new Date(),
          message: message,
          user: String(sender),
          viewers: newViewers
        };
        this.afs
          .collection('chats')
          .doc(chatId)
          .update({
            initialized: true,
            last_message: last_message,
            participants: participants
          });
        await this.afs
          .collection('chats')
          .doc(chatId)
          .collection('messages')
          .add({
            date: new Date(),
            message,
            isFile: isFile ? isFile : false,
            url: url ? url : '',
            sender: String(sender)
          });
        return chatData;
      } catch (error) {
        log.error(error);
      }
    }
    return undefined;
  }

  async getUserData(uid: string): Promise<any> {
    try {
      const response = await this.afs
        .collection('users')
        .doc(uid)
        .ref.get();
      if (response.exists) {
        return { uid: response.id, ...response.data() as any };
      } else {
        return;
      }
    } catch (error) {
      return error;
    }
  }
}
