import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';
import { AuthenticationService } from './authentication.service';
import { Observable, map } from 'rxjs';
const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {
  constructor(
    private router: Router,
    private credService: CredentialsService,
    private authServe: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authServe.isAuthenticated().pipe(map(auth => {
      if (auth === true) {
        if (
          (this.credService && this.credService.credentials && this.credService.credentials.type === 'administrador') ||
          (this.credService && this.credService.credentials && this.credService.credentials.type === 'editor') ||
          (this.credService && this.credService.credentials && this.credService.credentials.type === 'validador') ||
          (this.credService && this.credService.credentials && this.credService.credentials.type === 'validadorAdmin') ||
          (this.credService && this.credService.credentials && this.credService.credentials.type === 'encuestas')
        ) {
          if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.user) {
            const index: number = Array.from(route.routeConfig.data.user).indexOf(this.credService.credentials.type);
            return index > -1 ? true : false;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      log.warn(`%c${state.url} *** route not permitted ***`, 'color: red', route.paramMap);
      this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
      return false;
    }));
  }
}


/*


    if (

    ) {
    }


*/