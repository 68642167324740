<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Importar Chats</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="import()" [disabled]="isLoading">
        <ion-icon slot="start" name="checkmark"></ion-icon>
        Importar
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-progress-bar [value]="percentaje" color="success"></ion-progress-bar>
        <div class="ion-text-center" color="primary" *ngIf="isLoading === true">
          <h3>Cargando...</h3>
        </div>
        <div class="ion-text-center" color="primary" *ngIf="percentaje === 1">
          <h3>Terminado</h3>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
