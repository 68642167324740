<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>About</span></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card class="ion-text-center">
    <ion-card-content>
      <ion-card-title translate>APP_NAME</ion-card-title>
      <p><ion-icon name="code"></ion-icon> <span translate>Version</span> {{ version }}</p>
    </ion-card-content>
  </ion-card>
</ion-content>
