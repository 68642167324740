import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { toInteger } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-firebase-constants',
  templateUrl: './firebase-constants.component.html',
  styleUrls: ['./firebase-constants.component.scss']
})
export class FirebaseConstantsComponent implements OnInit {
  constants: any = {
    defaultLikes: null,
    pageVersion: null,
    payments: true
  };

  subscription: Subscription;

  constructor(
    public modalController: ModalController,
    private afs: AngularFirestore,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.subscription = this.afs
      .doc('constants/constants')
      .valueChanges()
      .subscribe((data: any) => {
        if (data && data.defaultLikes) {
          this.constants.defaultLikes = data.defaultLikes;
        }
        if (data && data.pageVersion) {
          this.constants.pageVersion = data.pageVersion;
        }
        if (data && (data.payments === true || data.payments === false)) {
          this.constants.payments = data.payments;
        }
        if (data && (data.openConections === true || data.openConections === false)) {
          this.constants.openConections = data.openConections;
        }
      });
  }

  applyChanges() {
    this.afs
      .doc('constants/constants')
      .update(this.constants)
      .then(async () => {
        this.modalController.dismiss();
        this.subscription.unsubscribe();
        const alert = await this.alertController.create({
          header: 'Se realizaron de forma exitosa los cambios',
          buttons: [
            {
              text: 'ok'
            }
          ]
        });
        await alert.present();
      });
  }

  integer() {
    if (this.constants.defaultLikes) {
      this.constants.defaultLikes = toInteger(this.constants.defaultLikes);
    } else {
      this.constants.defaultLikes = 0;
    }
  }
}
