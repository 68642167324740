<ion-app>
  <ion-header>
    <ion-toolbar color="secondary">
      <ion-title>Buscar Producto</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="modalC.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="primary">
      <ion-searchbar
        placeholder="Buscar un Producto"
        inputmode="decimal"
        type="decimal"
        [(ngModel)]="search"
        (keyup.enter)="buscar()"
        [debounce]="0"
        showCancelButton="always"
        mode="md"
      ></ion-searchbar>
      <ion-buttons slot="end">
        <ion-button (click)="buscar()">
          <ion-icon slot="icon-only" name="search"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="read-content">
      <ion-item *ngFor="let item of products" [button]="true" detail (click)="product = item">
        <ion-avatar slot="start" *ngIf="item.image">
          <img [src]="item.image" />
        </ion-avatar>
        <ion-avatar slot="start" *ngIf="!item.image">
          <img src="/assets/img/no-product.PNG" />
        </ion-avatar>
        <ion-label>
          <h5>{{ item.marca }}</h5>
          <p>
            <span>linea: {{ item.linea_terapeutica_1 }}</span>
            <span *ngIf="item.linea_terapeutica_2">, {{ item.linea_terapeutica_2 }}</span>
          </p>
        </ion-label>
      </ion-item>
    </div>
  </ion-content>

  <ion-footer>
    <ion-row class="ion-align-items-center">
      <ion-col size="">
        <ion-item>
          <ion-avatar slot="start" *ngIf="product.image">
            <img [src]="product.image" />
          </ion-avatar>
          <ion-avatar slot="start" *ngIf="!product.image">
            <img src="/assets/img/no-product.PNG" />
          </ion-avatar>
          <ion-label>
            <h5>{{ product.marca }}</h5>
            <p>
              <span>linea: {{ product.linea_terapeutica_1 }}</span>
              <span *ngIf="product.linea_terapeutica_2">, {{ product.linea_terapeutica_2 }}</span>
            </p>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col size="auto">
        <ion-buttons>
          <ion-button (click)="generateData()" color="success">
            <ion-icon slot="icon-only" name="checkmark-circle-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
  </ion-footer>
</ion-app>
