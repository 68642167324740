import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { Logger } from '@app/core';
import { from } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { DatabaseService } from '@app/shared/services/database.service';
const log = new Logger('AddPlanComponent');
import * as moment from 'moment';

@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.scss']
})
export class AddPlanComponent implements OnInit {
  public id: string;
  public dataForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private afs: AngularFirestore,
    private utilities: UtilitiesService,
    private database: DatabaseService
  ) {
    this.init();
  }

  init() {
    this.initForm();
  }

  async ngOnInit() {
    if (this.id) {
      this.isLoading = true;
      const loadingOverlay = await this.loadingController.create({});
      from(loadingOverlay.present());
      try {
        const RESP = await this.afs
          .collection('plans')
          .doc(this.id)
          .ref.get();
        const DATA:any = RESP.data();

        if (DATA && DATA.max_products_limit === true) {
          this.maxProductLimit({
            detail: {
              checked: true
            }
          });
        }

        if (DATA && DATA.max_contacts_limit === true) {
          this.maxContactsLimit({
            detail: {
              checked: true
            }
          });
        }

        this.dataForm.setValue({
          name: DATA && DATA.name ? String(DATA.name) : '',
          description: DATA && DATA.description ? String(DATA.description) : '',
          max_products_limit: DATA && DATA.max_products_limit === true ? Boolean(DATA.max_products_limit) : false,
          max_products: DATA && DATA.max_products ? Number(DATA.max_products) : null,
          max_contacts_limit: DATA && DATA.max_contacts_limit === true ? Boolean(DATA.max_contacts_limit) : false,
          max_contacts: DATA && DATA.max_contacts ? Number(DATA.max_contacts) : null
        });
        this.dataForm.updateValueAndValidity();
      } catch (e) {
        log.error(e);
      }
      loadingOverlay.dismiss();
      this.isLoading = false;
    }
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      max_products_limit: [false, Validators.required],
      max_products: [],
      max_contacts_limit: [false, Validators.required],
      max_contacts: []
    });
  }

  public maxProductLimit(event: any) {
    if (event && event.detail && event.detail.checked === true) {
      this.dataForm.controls['max_products'].setValidators([Validators.required]);
      this.dataForm.controls['max_products'].updateValueAndValidity();
    } else {
      this.dataForm.controls['max_products'].setValue(null);
      this.dataForm.controls['max_products'].clearValidators();
      this.dataForm.controls['max_products'].updateValueAndValidity();
    }
  }

  public maxContactsLimit(event: any) {
    if (event && event.detail && event.detail.checked === true) {
      this.dataForm.controls['max_contacts'].setValidators([Validators.required]);
      this.dataForm.controls['max_contacts'].updateValueAndValidity();
    } else {
      this.dataForm.controls['max_contacts'].setValue(null);
      this.dataForm.controls['max_contacts'].clearValidators();
      this.dataForm.controls['max_contacts'].updateValueAndValidity();
    }
  }
  public async save() {
    try {
      if (!this.id) {
        await this.add();
      } else {
        await this.update(this.id);
      }
    } catch (e) {
      log.error(e);
    }
  }

  public async add() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    from(loadingOverlay.present());
    try {
      const data: any = {
        date: moment().toDate(),
        name: this.dataForm && this.dataForm.value && this.dataForm.value.name ? String(this.dataForm.value.name) : '',
        description:
          this.dataForm && this.dataForm.value && this.dataForm.value.description
            ? String(this.dataForm.value.description)
            : '',
        default: false,
        max_products_limit:
          this.dataForm && this.dataForm.value && this.dataForm.value.max_products_limit
            ? this.dataForm.value.max_products_limit
            : false,
        max_products:
          this.dataForm && this.dataForm.value && this.dataForm.value.max_products
            ? Number(this.dataForm.value.max_products)
            : null,
        max_contacts_limit:
          this.dataForm && this.dataForm.value && this.dataForm.value.max_contacts_limit
            ? this.dataForm.value.max_contacts_limit
            : false,
        max_contacts:
          this.dataForm && this.dataForm.value && this.dataForm.value.max_contacts
            ? Number(this.dataForm.value.max_contacts)
            : null,
        status: 'inactive'
      };
      await this.afs.collection('plans').add(data);
      this.closeModal();
      this.utilities.toast('¡Plan agregado correctamente!');
    } catch (e) {
      log.error(e);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  public async update(id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    from(loadingOverlay.present());
    try {
      const data: any = {
        last_update_date: moment().toDate(),
        name: this.dataForm && this.dataForm.value && this.dataForm.value.name ? String(this.dataForm.value.name) : '',
        description:
          this.dataForm && this.dataForm.value && this.dataForm.value.description
            ? String(this.dataForm.value.description)
            : '',
        max_products_limit:
          this.dataForm && this.dataForm.value && this.dataForm.value.max_products_limit
            ? this.dataForm.value.max_products_limit
            : false,
        max_products:
          this.dataForm && this.dataForm.value && this.dataForm.value.max_products
            ? Number(this.dataForm.value.max_products)
            : null,
        max_contacts_limit:
          this.dataForm && this.dataForm.value && this.dataForm.value.max_contacts_limit
            ? this.dataForm.value.max_contacts_limit
            : false,
        max_contacts:
          this.dataForm && this.dataForm.value && this.dataForm.value.max_contacts
            ? Number(this.dataForm.value.max_contacts)
            : null
      };
      await this.afs
        .collection('plans')
        .doc(id)
        .update(data);

      await this.database.updatePlanRep(id, 'id');
      this.closeModal();
      this.utilities.toast('¡Plan actualizado correctamente!');
    } catch (e) {
      log.error(e);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  public closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }
}
