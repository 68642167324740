import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { AlertController, LoadingController, ModalController, ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Post } from '@app/shared/models/post';
import { Files } from '@app/shared/models/files';
import { take } from 'rxjs/operators';
import { UploadFilesService } from '@app/shared/services/upload-files.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Subscription } from 'rxjs';
import { Logger } from '@app/core';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CameraSource, CameraResultType, Camera } from '@capacitor/camera';
import { Device } from '@capacitor/device';
import { FileFormatService } from '@app/shared/services/file-format.service';
import { FilesService } from '@app/shared/services/files.service';
import { WpServiceV2 } from '@app/shared/services/wpv2/wp.servicev2';
import { FieldValue } from 'firebase/firestore';
const log = new Logger('EditPostComponent');

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit, OnDestroy {
  @ViewChild('inputMedia', { static: true }) inputMedia: any;
  @ViewChild('documentInput', { read: ElementRef, static: true }) documentInput: ElementRef;
  @ViewChild('imageInput', { read: ElementRef, static: true }) imageInput: ElementRef;
  @Input() id: any;
  @Input() callback: any;

  segmentIs: string = 'writepost';
  title: string = '';
  content: string = '';
  descriptionUrl: string = '';
  url: string = '';
  uploadArchives: Files[] = [];
  files: Files[] = [];
  idPost: string;
  subscriptions: Subscription[] = [];
  subscriptionFiles: Subscription;
  arrayKeyWords: string[] = [];
  keyWord: string = '';
  urlCheck = {
    ok: true,
    urlMessage: '',
    descMessage: ''
  };
  filesCheck = {
    size: 0,
    porcent: 0,
    color: 'success'
  };
  fileSizes: number[] = [];
  fileSizesStored: number[] = [];
  preImages: any[] = [];

  private foroDocument: AngularFirestoreDocument<Post>;

  fileToUpload1: any;
  imageProfile: any;
  image: any;
  isIosDevice: boolean = false;
  isLoading: boolean = false;

  constructor(
    private fileFormat: FileFormatService,
    private filesService: FilesService,
    public alertController: AlertController,
    private router: Router,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    public loadingController: LoadingController,
    public credentials: CredentialsService,
    private uploadFile: UploadFilesService,
    private utilities: UtilitiesService,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private sanitizer: DomSanitizer,
    private wp: WpServiceV2
  ) { }

  async ngOnInit() {
    const info = await Device.getInfo();
    if (info && info.platform === 'ios') {
      this.isIosDevice = true;
    }
    if (this.id) {
      this.idPost = this.id;
    }

    await this.getDatPost();
    await this.getFiles();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  async getDatPost() {
    const postData = await this.firestore
      .doc<Post>(`foro/${this.idPost}`)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise();

    this.title = postData.payload.data().title;
    this.content = postData.payload.data().content;
    this.descriptionUrl = postData.payload.data().descriptionUrl;
    this.url = postData.payload.data().url;
    if (postData.payload.data().uploadArchives) {
      this.uploadArchives = postData.payload.data().uploadArchives;
    }
    this.arrayKeyWords = postData.payload.data().keyWords;
  }

  getFiles() {
    this.subscriptionFiles = this.firestore
      .collection(`foro/${this.idPost}/files`)
      .snapshotChanges()
      .subscribe(async data => {
        this.files = [];

        // Getting files data
        await data.map(async (file: any) => {
          const fileData = file.payload.doc.data();
          fileData['id'] = file.payload.doc.id;

          // We create the url of the file in firestore Storage
          const fileUrl = `foro/${this.idPost}/Files/${fileData.name}`;

          // Getting the metadata of the file
          await this.storage.storage
            .ref(fileUrl)
            .getMetadata()
            .then(async meta => {
              // Getting the size of the file
              const size = meta.size / 1024 / 1024;
              this.fileSizesStored.push(size);
              this.files.push(fileData);
              this.filesCheck.size += size;
              this.filesCheck.porcent = this.filesCheck.size / 50;
              if (this.filesCheck.porcent < 0.4) {
                this.filesCheck.color = 'success';
              } else if (this.filesCheck.porcent < 0.8) {
                this.filesCheck.color = 'warning';
              } else {
                this.filesCheck.color = 'danger';
              }
            });
        });

        this.subscriptionFiles.unsubscribe();
      });

    this.subscriptions.push(this.subscriptionFiles);
  }

  getKeyWords() {
    this.subscriptionFiles = this.firestore
      .collection(`foro/${this.idPost}/files`)
      .snapshotChanges()
      .subscribe(data => {
        this.files = [];
        data.map(async (file: any) => {
          const fileData = file.payload.doc.data();
          fileData['id'] = file.payload.doc.id;
          this.files.push(fileData);
        });
      });

    this.subscriptions.push(this.subscriptionFiles);
  }

  async editPost() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Actualizando...'
    });
    await loading.present();

    const credentialData = this.credentials.credentials;

    const dataForo = {
      userUid: credentialData.uid,
      title: this.title,
      content: this.content,
      url: this.url,
      descriptionUrl: this.descriptionUrl,
      keyWords: this.arrayKeyWords
    };

    try {
      const info = await Device.getInfo();
      this.foroDocument = this.firestore.doc<Post>(`foro/${this.idPost}`);
      await this.foroDocument.update(dataForo);
      if (this.preImages.length > 0) {
        let arrayPromise: Promise<any>[] = [];
        this.preImages.forEach(element => {
          const randomId = Math.random()
            .toString(36)
            .substring(2, 8);
          let newName: string = '';
          if (info && (info.platform === 'ios' || info.platform === 'android')) {
            const fileUpload = this.filesService.uploadImage(element.file, `foro/${this.idPost}/Files`);
            arrayPromise.push(fileUpload);
          } else {
            newName = randomId + '_' + element.file.name;
            const fileUpload = this.uploadFile.uploadFile('foro', this.idPost, 'Files', newName, element.file);
            arrayPromise.push(fileUpload);
          }
        });
        let respuesta = await Promise.all(arrayPromise);
        const filesFB: Files[] = respuesta
          .filter(e => {
            if (e) {
              return e;
            }
          })
          .map(element => {
            return {
              description: '',
              titleFile: '',
              type: element.contentType || element.main.metadata.contentType || 'image/png',
              file: element.downloadURL || element.main.url,
              name: element.name || element.main.metadata.name || 'image.png'
            };
          });
        for (const fileFB of filesFB) {
          await this.foroDocument.collection<Files>('files').add(fileFB);
        }
        loading.dismiss();
        this.modalController.dismiss();
        this.callback();
      } else {
        loading.dismiss();
        this.modalController.dismiss();
        this.callback();
      }
    } catch (error) {
      loading.message = 'Ocurrio un error';
      setTimeout(() => {
        loading.dismiss();
      }, 1000);
      log.error(error);
    }
  }

  async presentAlert(_header: string, _message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: _header,
      message: _message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.title = '';
            this.content = '';
            this.descriptionUrl = '';
            this.url = '';
            this.uploadArchives = [];
            this.router.navigate(['multimedia']);
          }
        }
      ]
    });

    await alert.present();
  }

  segmentChanged(ev: any) {
    this.segmentIs = ev.target.value;
  }

  fileuploadEventHandler($event: any) {
    this.uploadArchives = $event;
  }

  async deleteFile(_item: Files) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¡Atención!',
      message: '¿Deseas eliminar este archivo?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        },
        {
          text: 'Si',
          handler: async () => {
            // Getting the size of the file
            const index = this.files.indexOf(_item);
            const size = this.fileSizesStored[index];
            this.filesCheck.size -= size;
            this.filesCheck.porcent = this.filesCheck.size / 50;
            if (this.filesCheck.porcent < 0.4) {
              this.filesCheck.color = 'success';
            } else if (this.filesCheck.porcent < 0.8) {
              this.filesCheck.color = 'warning';
            } else {
              this.filesCheck.color = 'danger';
            }
            this.fileSizesStored.splice(index, 1);
            this.files.splice(index, 1);

            this.firestore
              .collection(`foro/${this.idPost}/files`)
              .doc(_item.id)
              .delete();
            this.uploadFile.deleteFile('foro', this.idPost, 'Files', _item.name);
          }
        }
      ]
    });

    await alert.present();
  }

  async cancel() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Descartar publicación',
      message: '¿Quieres salir de la edición?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        },
        {
          text: 'Si',
          handler: () => {
            this.modalController.dismiss();
          }
        }
      ]
    });

    await alert.present();
  }

  async checkKeyWords() {
    if (this.arrayKeyWords.length === 0) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        message: 'Por favor agrega una palabra clave',
        buttons: ['Ok']
      });
      await alert.present();
    } else {
      this.editPost();
    }
  }

  addKeyWord() {
    this.arrayKeyWords.push(this.keyWord);
    this.keyWord = '';
  }

  deleteKeyWord(_item: string) {
    const arrayRemove = (FieldValue as any).arrayRemove;
    const doc = this.firestore.doc(`foro/${this.idPost}`);

    doc
      .update({
        keyWords: arrayRemove(_item)
      })
      .then(() => {
        const index = this.arrayKeyWords.indexOf(_item);
        if (index > -1) {
          this.arrayKeyWords.splice(index, 1);
        }
      });
  }

  checkUrl() {
    if (this.url !== '') {
      const r = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
      if (r.test(this.url)) {
        if (this.descriptionUrl !== '') {
          this.urlCheck.ok = true;
          this.urlCheck.urlMessage = '';
          this.urlCheck.descMessage = '';
        } else {
          this.urlCheck.ok = false;
          this.urlCheck.urlMessage = '';
          this.urlCheck.descMessage = 'La url requiere de texto para ser mostrado';
        }
      } else {
        this.urlCheck.ok = false;
        this.urlCheck.urlMessage = 'Esta url no es corresta, verifique si lleva http:// o https://';
        this.urlCheck.descMessage = '';
      }
    } else {
      this.urlCheck.ok = true;
      this.urlCheck.urlMessage = '';
      this.urlCheck.descMessage = '';
    }
  }

  checkSize(size: number): boolean {
    // Getting the size of the file
    this.fileSizes.push(size);
    this.filesCheck.size += size;
    this.filesCheck.porcent = this.filesCheck.size / 50;
    if (this.filesCheck.porcent < 0.4) {
      this.filesCheck.color = 'success';
    } else if (this.filesCheck.porcent < 0.8) {
      this.filesCheck.color = 'warning';
    } else {
      this.filesCheck.color = 'danger';
    }

    // If the size is more than 50MB
    if (this.filesCheck.porcent > 1) {
      this.filesCheck.size -= size;
      this.filesCheck.porcent = this.filesCheck.size / 50;
      this.fileLimitAlert();
      return false;
    } else {
      return true;
    }
  }

  async fileLimitAlert() {
    const alert = await this.alertController.create({
      header: 'El limite para cargar archivos es de 50MB',
      message: 'Revise el tamaño del archivo o si tiene mas archivos elimine uno',
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });
    await alert.present();
  }

  async newMedia(files: File[]) {
    if (files.length !== 0) {
      // Checking the Size
      if (!this.checkSize(files[0].size / 1024 / 1024)) {
        this.inputMedia.nativeElement.value = null;
        return;
      }

      // Create the constants to future asignment
      const title = new Date().getTime();
      const fileType = files[0].type;

      // making the file reader
      const reader = new FileReader();
      reader.onload = async () => {
        const file: Files = {
          titleFile: `file_${title}`,
          file: reader.result as string,
          name: `file_${title}`,
          type: fileType,
          description: ''
        };
        this.preImages.push(reader.result);
        this.uploadArchives.push(file);
      };
      reader.readAsDataURL(files[0]);

      this.inputMedia.nativeElement.value = null;
    }
  }

  quitMedia(file: any) {
    // Getting the index
    const index = this.uploadArchives.indexOf(file);

    // Generating the new size
    const size = this.fileSizes[index];
    this.filesCheck.size -= size;
    this.filesCheck.porcent = this.filesCheck.size / 50;

    // Deleting the item
    this.fileSizes.splice(index, 1);
    this.uploadArchives.splice(index, 1);
    this.preImages.splice(index, 1);

    if (this.filesCheck.porcent < 0.4) {
      this.filesCheck.color = 'success';
    } else if (this.filesCheck.porcent < 0.8) {
      this.filesCheck.color = 'warning';
    } else {
      this.filesCheck.color = 'danger';
    }
  }

  /////////////////////////////////////////////

  ///////////////////////////////////////////////
  webImage(files: FileList) {
    try {
      const fileToUpload1 = files.item(0);
      const fileSize = Number(fileToUpload1.size);
      if (fileSize <= this.fileFormat.maxFileSize) {
        /*********Archivo sin formato*********/
        const theFile = fileToUpload1;
        if (theFile.type != undefined && theFile.type != null && theFile.type != '') {
          /*********File type*********/
          if (this.fileFormat.validateImageType(theFile.type)) {
            this.fileToUpload1 = fileToUpload1;
            this.imageProfile = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.fileToUpload1));
            //Push to images array
            this.preImages.push({ image: this.imageProfile, file: this.fileToUpload1 });
          } else {
            this.utilities.toast('Formato de archivo no válido.', 'Error');
          }
          /*********\\File type*********/
        } else {
          this.utilities.toast('El archivo que intentas subir podría estar dañado.', 'Error');
        }
        /*********\\Archivo sin formato*********/
      } else {
        this.utilities.toast('El archivo que intentas subir es demasiado grande (10 Mb Máximo).', 'Error');
      }
    } catch (error) {
      log.error(error);
    }
  }

  async webImageTrigger() {
    try {
      this.imageInput.nativeElement.click();
    } catch (error) {
      log.error(error);
    }
  }

  async actionSheetImages(id?: number) {
    let buttons = [
      {
        text: 'Galería',
        icon: 'images',
        handler: () => {
          this.fromGallery();
        }
      },
      {
        text: 'Cámara',
        icon: 'camera',
        handler: () => {
          this.fromCamera();
        }
      },
      {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel'
      }
    ];
    if (this.image && this.image.id) {
      buttons.push({
        text: 'Eliminar',
        icon: 'trash',
        handler: () => {
          this.confirmDeleteDocument(id);
        }
      });
    }
    const actionSheet = await this.actionSheetController.create({
      header: 'Reemplazar imagen actual',
      buttons: buttons
    });
    await actionSheet.present();
  }

  async fromGallery() {
    this.isLoading = true;
    const info = await Device.getInfo();
    if (info && (info.platform === 'ios' || info.platform === 'android')) {
      this.iosAndAndroidImage();
    } else {
      this.webImageTrigger();
    }
    this.isLoading = false;
  }

  async fromCamera() {
    this.isLoading = true;
    const info = await Device.getInfo();
    if (info && (info.platform === 'ios' || info.platform === 'android')) {
      this.fromCameraIosAndroid();
    } else {
      this.takePictureFromWeb();
    }
    this.isLoading = false;
  }

  async fromCameraIosAndroid() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const response = await this.filesService.takePicture(CameraSource.Camera, '', 'preview');
      if (response && response.main && response.main.path) {
        this.imageProfile = this.sanitizer.bypassSecurityTrustResourceUrl(response.main.path);
        this.fileToUpload1 = response.main.path;
        this.preImages.push({ image: this.imageProfile, file: this.fileToUpload1 });
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async iosAndAndroidImage() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const response = await this.filesService.takePicture(CameraSource.Photos, '', 'preview');
      if (response && response.main && response.main.path) {
        this.imageProfile = this.sanitizer.bypassSecurityTrustResourceUrl(response.main.path);
        this.fileToUpload1 = response.main.path;
        this.preImages.push({ image: this.imageProfile, file: this.fileToUpload1 });
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async takePictureFromWeb() {
    try {
      const image = await Camera.getPhoto({
        quality: 40,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera
      });
      this.imageProfile = this.sanitizer.bypassSecurityTrustResourceUrl(image && image.dataUrl);
      const url = image.dataUrl;
      const fetchUrl = await fetch(url);
      const blobFile = await fetchUrl.blob();
      let fileToUpload1;
      await Device.getInfo();
      fileToUpload1 = new File([blobFile], 'file-name.' + image.format, { type: 'image/' + image.format });
      const fileSize = Number(fileToUpload1.size);
      if (fileSize <= this.fileFormat.maxFileSize) {
        /*********Archivo sin formato*********/
        const theFile = fileToUpload1;
        if (theFile.type != undefined && theFile.type != null && theFile.type != '') {
          /*********File type*********/
          if (this.fileFormat.validateImageType(theFile.type)) {
            this.fileToUpload1 = fileToUpload1;
            log.debug(this.fileToUpload1);
            //Push to images array
            this.preImages.push({ image: this.imageProfile, file: this.fileToUpload1 });
          } else {
            this.utilities.toast('Formato de archivo no válido.', 'Error');
          }
          /*********\\File type*********/
        } else {
          this.utilities.toast('El archivo que intentas subir podría estar dañado.', 'Error');
        }
        /*********\\Archivo sin formato*********/
      } else {
        this.utilities.toast('El archivo que intentas subir es demasiado grande (10 Mb Máximo).', 'Error');
      }
    } catch (error) {
      log.error(error);
    }
  }

  async confirmDeleteDocument(id: number) {
    const alert = await this.alertController.create({
      header: 'Eliminar archivo',
      message: '¿Estás seguro de eliminar este archivo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.deleteMedia(id);
          }
        }
      ]
    });
    await alert.present();
  }

  async deleteMedia(id: number) {
    this.isLoading = true;
    try {
      const response: any = await this.wp.deleteMedia(id);
      if (response['deleted'] === true) {
        this.imageProfile = undefined;
        this.utilities.toast('Archivo eliminado correctamente.', 'Correcto');
      } else {
        this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  deleteDocument(index: number) {
    try {
      this.preImages.splice(index, 1);
    } catch (error) {
      log.error(error);
    }
  }

  parserany(obj: any): any {
    return obj;
  }
}
