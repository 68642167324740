import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CallOptsComponent } from './call-opts/call-opts.component';
import { ScheduleEvtComponent } from './schedule-evt/schedule-evt.component';
import { UserDetailViewComponent } from './user-detail-view.component';
import { PullToRefreshModule } from './../pull-to-refresh/pull-to-refresh.module';

@NgModule({
  declarations: [UserDetailViewComponent, CallOptsComponent, ScheduleEvtComponent],
  exports: [UserDetailViewComponent, CallOptsComponent, ScheduleEvtComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    RouterModule,
    PullToRefreshModule
  ],
 // entryComponents: [UserDetailViewComponent, CallOptsComponent, ScheduleEvtComponent]
})
export class UserDetailViewModule {}
