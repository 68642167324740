import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
const LIMIT: number = 10;
import { Logger } from '@app/core/logger.service';
const log = new Logger('PostsInfoComponent');
@Component({
  selector: 'app-posts-info',
  templateUrl: './posts-info.component.html',
  styleUrls: ['./posts-info.component.scss']
})
export class PostsInfoComponent implements OnInit {
  posts: any[] = [];
  canContinue: boolean = true;
  pivot: QueryDocumentSnapshot<DocumentData>;
  categories: any[] = [];

  constructor(
    private afs: AngularFirestore,
    private utilities: UtilitiesService,
    private loadingCtrl: LoadingController
  ) { }

  ngOnInit() { }

  public async init() {
    const loading = await this.loadingCtrl.create();
    loading.present();
    try {
      await this.getCategories();
      await this.getAllPosts();
      await this.download();
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
    this.utilities.toast('!Operación terminada con éxito!');
  }

  private async download() {
    try {
      let wb = XLSX.utils.book_new();
      const aoa_to_sheet: any[] = [];
      let ws = XLSX.utils.aoa_to_sheet(aoa_to_sheet);
      XLSX.utils.sheet_add_json(ws, this.posts, { origin: -1 });
      XLSX.utils.book_append_sheet(wb, ws, String('Posts').substring(0, 25));

      XLSX.writeFile(wb, 'posts-information.xlsx');
    } catch (error) {
      log.error(error);
    }
  }

  private async getCategories() {
    try {
      const resp1 = await this.afs.collection('categories_video').ref.get();
      const resp2 = await this.afs.collection('categories').ref.get();

      const array1 = resp1.docs.map(item => {
        let data: any = { name: item.get('name'), id: item.id, path: item.ref.path };
        data.name = `${data.name}(Video)`;
        return data;
      });

      const array2 = resp2.docs.map(item => {
        return { name: item.get('name'), id: item.id, path: item.ref.path };
      });

      this.categories = this.categories.concat(array1, array2);
    } catch (error) {
      log.error(error);
    }
  }

  private async getAllPosts() {
    do {
      await this.page(this.pivot);
    } while (this.canContinue === true);
  }

  private async page(pivot?: QueryDocumentSnapshot<DocumentData>) {
    try {
      let resp: QuerySnapshot<DocumentData>;

      if (pivot) {
        resp = await this.afs
          .collection('posts')
          .ref.orderBy('date', 'asc')
          .startAfter(pivot)
          .limit(LIMIT)
          .get();
      } else {
        resp = await this.afs
          .collection('posts')
          .ref.orderBy('date', 'asc')
          .limit(LIMIT)
          .get();
      }

      for (const item of resp.docs) {
        let category: string = '';
        try {
          if (item.get('category')) {
            const ref = item.get('category') as DocumentReference;
            const index: number = this.categories.map(e => e.path).indexOf(ref.path);

            if (index > -1) {
              category = this.categories[index].name;
            }
          }
        } catch (error) {
          log.error(error);
        }

        this.posts.push({
          ID: item.id,
          Título: item.get('title'),
          Fecha: moment(item.get('date').toDate()).format('YYYY-MM-DD HH:mm'),
          Tipo: item.get('type'),
          Categoría: category,
          "Número de Likes": item.get('likeCount') || 0,
        });
      }

      if (resp.size === LIMIT) {
        this.pivot = resp.docs[resp.size - 1];
        this.canContinue = true;
      } else {
        this.canContinue = false;
      }
    } catch (error) {
      log.error(error);
      this.canContinue = false;
    }
  }
}
