import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { SearchComponent } from '../search/search.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_URL, DEEPLINK_URL } from '../../environments/environment';
const log = new Logger('GROUP DETAIL');
@Component({
  selector: 'app-generate-url',
  templateUrl: './generate-url.component.html',
  styleUrls: ['./generate-url.component.scss']
})
export class GenerateUrlComponent implements OnInit {
  public dataForm: FormGroup;
  public isLoading: boolean = false;
  rep: any;
  files: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private alertController: AlertController,
    private toastController: ToastController
  ) {
    this.initForm();
  }

  ngOnInit() {}

  initForm() {
    this.dataForm = this.formBuilder.group({
      message: ['', Validators.maxLength(180)]
    });
  }

  async selectUsersFunction() {
    try {
      const modal = await this.modalCtrl.create({
        component: SearchComponent,
        componentProps: {
          viewType: 'modal',
          searchMemberType: 'representante-medico',
          multiselection: false
        }
      });
      modal.onDidDismiss().then(element => {
        if (element && element.data && element.data.users && element.data.users[0]) {
          this.getRepInfo(element.data.users[0]);
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  async getRepInfo(user: any) {
    this.isLoading = true;
    try {
      this.rep = user;
      if (user && user['filter-meta-data'] && user['filter-meta-data'][0]) {
        const idCompany = user['filter-meta-data'][0];
        const resp = await this.afs.doc(`companies/${idCompany}`).ref.get();
        if (resp.exists === true) {
          this.rep.company = resp.data();
        }
      }
    } catch (e) {
      log.error(e);
    }
    this.isLoading = false;
  }

  public getURL(): void {
    try {
      if (this.rep && this.rep.id) {
        const ID: string = String(this.rep.id);
        const BASE64_ID: string = encodeURIComponent(btoa(ID));
        let message: string;
        let base64Message: string;
        let url: string;
        if (this.dataForm.get('message').value !== '') {
          message = String(this.dataForm.get('message').value);
          base64Message = btoa(message);
          base64Message = encodeURIComponent(base64Message);
          url = `/home/chat/user/${BASE64_ID}/${base64Message}`;
        } else {
          url = `/home/chat/user/${BASE64_ID}`;
        }
        let parameter = `${DEEPLINK_URL}${url}`;
        parameter = btoa(parameter);
        const retUrl = `${APP_URL}/sharing/${parameter}`;
        this.presentAlertPrompt(retUrl);
      }
    } catch (error) {
      log.error(error);
    }
    return;
  }

  async presentAlertPrompt(url: string) {
    const alert = await this.alertController.create({
      header: '¡URL generado correctamente!',
      subHeader: 'Copie la siguiente URL:',
      inputs: [
        {
          name: 'url',
          type: 'url',
          value: url
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'danger'
        },
        {
          text: 'Copiar link',
          handler: (data: any) => {
            if (data && data.url) {
              var text = String(data.url);
              navigator.clipboard
                .writeText(text)
                .then(() => {
                  this.toast('¡URL copiado correctamente!');
                })
                .catch(error => {
                  log.error(error, 'Error al copiar URL');
                });
            }
          }
        }
      ]
    });
    await alert.present();
  }

  public async toast(message: string, title?: string, position: 'top' | 'bottom' | 'middle' = 'top') {
    title = title ? title : '';
    const toast = await this.toastController.create({
      header: title,
      message: message,
      position: position,
      duration: 10000,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }
}
