import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AddSurveyComponent } from './add-survey/add-survey.component';
const log = new Logger('SurveysComponent');
@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {
  public isLoading: boolean = false;
  surveys: any[] = [];

  constructor(
    private afs: AngularFirestore,
    private alertController: AlertController,
    private modalCtrl: ModalController
  ) {
    this.loadData();
  }

  ngOnInit() { }

  async loadData() {
    try {
      const RESP = await this.afs
        .collection('surveys')
        .ref.orderBy('date', 'desc')
        .get();
      this.surveys = RESP.docs.map(e => {
        const DATA: any = e.data();
        const ID = e.id;
        return { id: ID, ...DATA };
      });
    } catch (error) {
      log.error(error);
    }
  }

  async desactive(event: any, surveyId: string) {
    this.isLoading = true;
    try {
      const value: 'active' | 'inactive' = Boolean(event.detail.checked) === true ? 'active' : 'inactive';
      await this.afs.doc(`surveys/${surveyId}`).update({ status: value });
      //await this.loadData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async delete(surveyId: string) {
    this.isLoading = true;
    try {
      await this.afs.doc(`surveys/${surveyId}`).delete();
      await this.loadData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async deleteAlert(surveyId: string) {
    const alert = await this.alertController.create({
      header: 'Eliminar Alerta',
      message: '¿Está seguro de eliminar esta alerta?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Eliminar',
          handler: () => {
            this.delete(surveyId);
          }
        }
      ]
    });

    await alert.present();
  }

  async add(id?: string) {
    try {
      const modal = await this.modalCtrl.create({
        component: AddSurveyComponent,
        componentProps: { id: id }
      });
      modal.onDidDismiss().then(resp => {
        if (resp && resp.role && resp.role === 'db') {
          this.loadData();
        }
      });
      return await modal.present();
    } catch (e) {
      log.error(e);
    }
  }
}
