<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Información de posts</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-button (click)="init()" color="secondary" expand="block">
          <ion-icon slot="start" name="download"></ion-icon>
          Descargar información
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
