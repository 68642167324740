<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> {{ id ? 'Actualizar información de plan' : 'Agregar plan' }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="dataForm" (ngSubmit)="save()">
          <ion-item>
            <ion-label position="stacked">Nombre <ion-text color="danger">*</ion-text> </ion-label>
            <ion-input type="text" formControlName="name"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Descripción <ion-text color="danger">*</ion-text> </ion-label>
            <ion-textarea formControlName="description" rows="3"></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-label>¿Limitar número de productos?</ion-label>
            <ion-toggle formControlName="max_products_limit" (ionChange)="maxProductLimit($event)"></ion-toggle>
          </ion-item>
          <ion-item *ngIf="this.dataForm.controls['max_products_limit'].value === true">
            <ion-label position="stacked">Número de productos <ion-text color="danger">*</ion-text> </ion-label>
            <ion-input type="number" formControlName="max_products"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>¿Limitar número de contactos?</ion-label>
            <ion-toggle formControlName="max_contacts_limit" (ionChange)="maxContactsLimit($event)"></ion-toggle>
          </ion-item>
          <ion-item *ngIf="this.dataForm.controls['max_contacts_limit'].value === true">
            <ion-label position="stacked">Número de contactos <ion-text color="danger">*</ion-text> </ion-label>
            <ion-input type="number" formControlName="max_contacts"></ion-input>
          </ion-item>
          <ion-text color="danger">
            <p>* Datos obligatorios</p>
          </ion-text>
          <ion-button type="submit" color="tertiary" expand="block" [disabled]="!dataForm.valid">
            {{ id ? 'Actualizar información' : 'Agregar' }}
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
