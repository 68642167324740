import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AppRoutingModule } from './app-routing.module';
import { FIREBASE_CONFIG } from '@env/environment';
import { HomeModule } from '@app/home/home.module';
import { AboutModule } from '@app/about/about.module';
import { ActivateMemberModule } from '@app/activate-member/activate-member.module';
import { ActivateMemberV2Module } from '@app/activate-member-v2/activate-member-v2.module';
import { SearchModule } from '@app/search/search.module';
import { ActivateRepModule } from '@app/activate-rep/activate-rep.module';
import { AdvertisingModule } from '@app/advertising/advertising.module';
import { AssignContactsModule } from '@app/assign-contacts/assign-contacts.module';
import { AssignContactsV2Module } from '@app/assign-contacts-v2/assign-contacts-v2.module';
import { ChatModule } from '@app/chat/chat.module';
import { ClickBannerLogsModule } from '@app/click-banner-logs/click-banner-logs.module';
import { ClickMeetingModule } from '@app/click-meeting/click-meeting.module';
import { CompaniesModule } from '@app/companies/companies.module';
import { DeleteContactsModule } from '@app/delete-contacts/delete-contacts.module';
import { DisableMedicalSampleModule } from '@app/disable-medical-sample/disable-medical-sample.module';
import { GenerateUrlModule } from '@app/generate-url/generate-url.module';
import { ImportModule } from '@app/import/import.module';
import { ImportChatsModule } from '@app/import-chats/import-chats.module';
import { ImportPostsModule } from '@app/import-posts/import-posts.module';
import { ImportProductsModule } from '@app/import-products/import-products.module';
import { MedicalForumModule } from '@app/medical-forum/medical-forum.module';
import { PlansModule } from '@app/plans/plans.module';
import { PointsModule } from '@app/points/points.module';
import { PostsInfoModule } from '@app/posts-info/posts-info.module';
import { PreregistroModule } from '@app/preregistro/preregistro.module';
import { PushNotificationsModule } from '@app/push-notifications/push-notifications.module';
import { SurveysModule } from '@app/surveys/surveys.module';
import { SurveysAndTestsModule } from '@app/surveys-and-tests/surveys-and-tests.module';
import { UsersActionsModule } from '@app/users-actions/users-actions.module';
import { ZipcodesSuggestionModule } from '@app/zipcodes-suggestion/zipcodes-suggestion.module';
import { PostsReviewModule } from '@app/posts-review/posts-review.module';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { SharedSurveyResultsModule } from './shared-survey-results/shared-survey-results.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    CoreModule,
    SharedModule,
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    AppRoutingModule,
    HomeModule,
    AboutModule,
    ActivateMemberModule,
    ActivateMemberV2Module,
    SearchModule,
    ActivateRepModule,
    AdvertisingModule,
    AssignContactsModule,
    AssignContactsV2Module,
    ChatModule,
    ClickBannerLogsModule,
    ClickMeetingModule,
    CompaniesModule,
    DeleteContactsModule,
    DisableMedicalSampleModule,
    GenerateUrlModule,
    ImportModule,
    ImportChatsModule,
    ImportPostsModule,
    ImportProductsModule,
    MedicalForumModule,
    PlansModule,
    PointsModule,
    PostsInfoModule,
    PreregistroModule,
    PushNotificationsModule,
    SurveysModule,
    SurveysAndTestsModule,
    UsersActionsModule,
    ZipcodesSuggestionModule,
    AgChartsAngularModule,
    PostsReviewModule,
    SharedSurveyResultsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule
  ],
  declarations: [AppComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
