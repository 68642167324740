<div
  class="wrapper"
  [routerDirection]="doNotRouting === true ? null : 'forward'"
  [routerLink]="doNotRouting === true ? null : ['/user/details/' + item.id]"
  class="ion-no-margin"
>
  <div class="body">
    <ion-item color="light" lines="full">
      <ion-thumbnail slot="start">
        <img
          [src]="
            item?.avatar?.thumbnail?.url
              ? item?.avatar?.thumbnail?.url
              : '../../../../assets/img/default-' + item.type + '.jpg'
          "
        />
      </ion-thumbnail>
      <div slot="start" class="ion-text-center icon-big">
        <ion-icon [name]="item.type === 'representante-medico' ? 'briefcase' : 'medkit'"></ion-icon>
        <span class="type-label">{{ item.type === 'representante-medico' ? 'Rep. Médico' : 'Médico' }}</span>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">
          <h2>
            {{ (' ' + item?.name + ' ' + item?.lastName1 + ' ' + item?.lastName2).toUpperCase() }}
          </h2>
          <p>{{ item?.email }}</p>
          <p>{{ item?.id }}</p>
          <ion-badge *ngIf="status === true" [color]="item?.status === 'active' ? 'success' : 'medium'" mode="ios">
            {{ item?.status === 'active' ? 'Activo' : 'Inactivo' }}</ion-badge
          >
          <div *ngIf="item?.metaData | async as meta" class="ion-text-wrap">
            <p *ngIf="item.type === 'medico'">
              <ion-icon name="school"></ion-icon> <span *ngIf="parserany(meta)?.specialty1 as data">{{ data?.name }}</span>
              <span *ngIf="parserany(meta)?.specialty2?.name">&nbsp;&nbsp;&bull;&nbsp;&nbsp;{{ parserany(meta)?.specialty2?.name }} </span>
            </p>
            <p *ngIf="item.type === 'representante-medico'">
              <strong>{{ parserany(meta)?.company?.name }}</strong>
            </p>

            <p class="mob-locchip ion-text-right">
              <ion-text color="dark" *ngIf="item?.type !== 'representante-medico'">
                <span *ngIf="parserany(meta)?.address1 as address">
                  <span *ngIf="address?.state && address?.city">
                    <span *ngIf="address?.state === 'Ciudad de México'; else otherAddress1">
                      <ion-icon name="map"></ion-icon>
                      {{ address?.state + ', ' + address?.colony }}
                    </span>
                    <ng-template #otherAddress1>
                      <span>
                        <ion-icon name="map"></ion-icon>
                        {{ address?.state + ', ' + address?.city }}
                      </span>
                    </ng-template>
                  </span>
                </span>
                <span *ngIf="parserany(meta)?.address2 as address">
                  <span *ngIf="address?.state && address?.city">
                    <span *ngIf="address?.state === 'Ciudad de México'; else otherAddress2">
                      <ion-icon name="map"></ion-icon>
                      {{ address?.state + ', ' + address?.colony }}
                    </span>
                    <ng-template #otherAddress2>
                      <span>
                        <ion-icon name="map"></ion-icon>
                        {{ address?.state + ', ' + address?.city }}
                      </span>
                    </ng-template>
                  </span>
                </span>
              </ion-text>
              <ion-text color="dark" *ngIf="item.type === 'representante-medico'">
                <p>
                  {{ parserany(meta)?.company?.businessType | uppercase }}
                </p>
              </ion-text>
            </p>
          </div>
          <div *ngIf="item?.isFriend | async as isFriend">
            <ion-chip *ngIf="isFriend === true" color="primary">
              <ion-label>Mi contacto</ion-label>
              <ion-icon name="checkmark-circle" color="success"></ion-icon>
            </ion-chip>
          </div>
        </ion-text>
      </ion-label>
      <ion-note *ngIf="item?.metaData | async as meta" class="ion-text-wrap" slot="end">
        <ion-text color="dark" *ngIf="item.type !== 'representante-medico'">
          <p *ngIf="parserany(meta)?.address1 as address">
            <span *ngIf="address?.state && address?.city">
              <span *ngIf="address.state === 'Ciudad de México'; else otherAddress1">
                <ion-icon name="map"></ion-icon>
                {{ address?.state + ', ' + address?.colony }}
              </span>
              <ng-template #otherAddress1>
                <span>
                  <ion-icon name="map"></ion-icon>
                  {{ address?.state + ', ' + address?.city }}
                </span>
              </ng-template>
            </span>
          </p>
          <p *ngIf="parserany(meta)?.address2 as address">
            <span *ngIf="address?.state && address?.city">
              <span *ngIf="address.state === 'Ciudad de México'; else otherAddress2">
                <ion-icon name="map"></ion-icon>
                {{ address?.state + ', ' + address?.colony }}
              </span>
              <ng-template #otherAddress2>
                <span>
                  <ion-icon name="map"></ion-icon>
                  {{ address?.state + ', ' + address?.city }}
                </span>
              </ng-template>
            </span>
          </p>
        </ion-text>
        <ion-text color="dark" *ngIf="item.type === 'representante-medico'">
          <p>
            {{ parserany(meta)?.company.businessType | uppercase }}
          </p>
        </ion-text>
      </ion-note>
    </ion-item>
  </div>
</div>

<ion-grid fixed *ngIf="buttons === 'sent'" class="ion-no-margin">
  <ion-row>
    <ion-col>
      <ion-button expand="block" fill="outline" color="danger" (click)="confirmCancel()" [disabled]="isLoading">
        <ion-icon name="close" slot="start"></ion-icon>
        Cancelar solicitud
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-grid fixed *ngIf="buttons === 'received'" class="ion-no-margin">
  <ion-row>
    <ion-col size="6">
      <ion-button expand="block" fill="outline" color="tertiary" (click)="confirmAccept()" [disabled]="isLoading">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        Aceptar
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button expand="block" fill="outline" color="danger" (click)="confirmReject()" [disabled]="isLoading">
        <ion-icon name="close" slot="start"></ion-icon>
        Rechazar
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
