import { Injectable } from '@angular/core';
import { Logger } from '@app/core';
import {
  AngularFirestore,
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentData,
  Query
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CredentialsService } from '@app/core';
import { WpServiceV2 } from '@app/shared/services/wpv2/wp.servicev2';
import { UtilitiesService } from './utilities.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { DEFAULT_USERS_CONNECTIONS, FIREBASE_API } from '../../../environments/environment';
const log = new Logger('FB SERVICE');
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';

export interface Message {
  message: string;
  isFile: boolean;
  url: string;
  convert: boolean;
  user?: string;
  sendMail?: boolean;
  label?: string;
}
export interface Zipcode {
  postal_code: string;
  city: string;
  colony: string;
  state: string;
}
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor(
    private route: Router,
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    public afs: AngularFirestore,
    private wpv2: WpServiceV2,
    private credentials: CredentialsService,
    private fns: AngularFireFunctions,
    private utilities: UtilitiesService,
    private alertController: AlertController,
    private http: HttpClient,
    private auth: AngularFireAuth,
    private modalController: ModalController,
    private loadingController: LoadingController
  ) { }

  public async getProductData(productId: string) {
    return await new Promise(resolve => {
      const sub = this.afs
        .doc(`products/${productId}`)
        .valueChanges()
        .subscribe(value => {
          resolve(value);
          sub.unsubscribe();
        });
    });
  }

  share(route: string): string {
    route;
    // let parameter = `${DEEPLINK_URL}${route}`;
    // parameter = btoa(parameter);
    // return `${APP_URL}/sharing/${parameter}`;
    return undefined;
  }

  async productInfo(email: string, name: string, chatID: string) {
    try {
      let otherName: string = '';
      const urlButton: string = this.share(`/home/chat/${chatID}`);
      const data = await this.getUserData(this.credentials.credentials.uid);
      otherName = `${data && data.name ? data.name : ''} ${data && data.lastName1 ? data.lastName1 : ''} ${data && data.lastName2 ? data.lastName2 : ''
        }`;
      const text = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html>

    <head>
        <title>¡Solicitud de información de productos!</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
        <style type="text/css">
            * {
                font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            }

            body {
                background-color: #f2f2f2;
            }

            table {
                font-size: 24px;
            }

            #tdtoshowinmobile {
                display: none;
            }

            @media screen and (max-device-width: 767px),
            screen and (max-width: 767px) {
                table {
                    font-size: 32px !important;
                }

                #tdtoshowinmobile {
                    display: block !important;
                }
            }
        </style>
    </head>

    <body>

        <div
            style="display: block;position: relative;margin: 0 auto;max-width: 600px;background: #ffffff;border-radius: 10px;padding: 20px;box-shadow: 1px 1px 5px #b0b0b0;margin-top: 20px;">
            <div><img style="display: block; position: relative;margin: 0 auto; width: 250px;"
                    src="https://app.conectimed.com/assets/img/logo-horizontal.png" /></div>
            <hr style="border: 1px ridge;" />
            <h2><strong>Estimado(a) ${name}:</strong></h2>
            <p style="text-align: left;">El medico <strong>${otherName}</strong> est&aacute; en l&iacute;nea en el chat para solicitarle
                informaci&oacute;n de sus productos.</p>
            <p style="text-align: center;"><span style="background-color: #236fa1; color: #ffffff;"><strong><a
                            style="background-color: #2c8ecf;color: #ffffff;display: inline-block;padding: 10px;text-decoration: none;border-radius: 5px;border: 2px solid #1773b1;"
                            title="Responda a este Chat" href="${urlButton}" rel="noopener">Responda este
                            chat</a></strong></span></p>
            <p style="text-align: center;">Para cualquier duda o comentario escribanos a <a
                    href="mailto:contacto@conectimed.com">contacto@conectimed.com</a> donde con gusto lo
                atenderemos.<br />En
                <strong>Conectimed </strong>trabajamos todos los d&iacute;as buscando la satisfacci&oacute;n de nuestros
                usuarios.<br /><br />Descarga la App
            </p>
            <table style="border-collapse: collapse; width: 100%;" border="0">
                <tbody>
                    <tr>
                        <td style="width: 48.0441%;" align="center">
                            <a href="https://apps.apple.com/mx/app/conectimed-app/id1488809696">
                                <img width="153"
                                    src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-alternate_2x.png" />
                            </a>
                        </td>
                        <td style="width: 48.1543%;" align="center">
                            <a href="https://play.google.com/store/apps/details?id=com.enacment.conectimed&hl=es_MX&gl=US">
                                <img width="153"
                                    src="https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0" />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: center;">&nbsp;</p>
        </div>

    </body>

    </html>`;

      await this.email(email, '¡Solicitud de información de productos!', text, name);
    } catch (error) {
      log.error(error);
    }
  }

  async email(
    recipient: string,
    subject: string,
    text: string,
    name: string,
    cc?: Array<string> | null,
    bcc?: Array<string> | null
  ) {
    try {
      await this.callFunctionFB('sendMail', {
        recipient,
        subject,
        text,
        name,
        cc,
        bcc
      });
    } catch (err) {
      log.error(err);
    }
  }

  async goChat(chatId: string, component: any, accepted: boolean = true): Promise<void> {
    try {
      const loadingOverlay = await this.loadingController.create({});
      loadingOverlay.present();
      var participantsArrayInfo: any[] = [];
      const userid: string = String(this.credentials.credentials.uid);
      let participants: string[];
      const refDoc: any = await this.afs
        .collection('chats')
        .doc(chatId)
        .ref.get();
      participants = Array.from(refDoc.data().participants);
      const index: number = participants.indexOf(userid);
      if (!(index > -1)) {
        participants.push(userid);
        await this.afs
          .collection('chats')
          .doc(chatId)
          .update({ participants });
      }
      participants.forEach(async (i: any) => {
        var fullName, avatar;
        const user = await this.getUserData(i);
        fullName = user.name + ' ' + user.lastName1 + ' ' + user.lastName1;
        avatar = user.avatar && user.avatar.list && user.avatar.list.url ? user.avatar.list.url : '';
        participantsArrayInfo[i] = { nombre: fullName, avatar };
      });
      const modal = await this.modalController.create({
        component,
        componentProps: { id: chatId, participants: participantsArrayInfo, accepted }
      });
      loadingOverlay.dismiss();
      return await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  async ifChatExists(ids: string[]) {
    return await this.afs
      .collection('chats')
      .ref.where('identifier', 'in', ids)
      .get();
  }

  public async sendChatMessage(
    chatId: string,
    message: string,
    convert?: boolean,
    isFile?: boolean,
    url?: string,
    user?: string,
    sendMail?: boolean,
    label?: string
  ): Promise<any> {
    if (message && message !== '') {
      try {
        if (convert === true) {
          message = this.utilities.urlify(message, true);
        }
        const chatData: any = (
          await this.afs
            .collection('chats')
            .doc(chatId)
            .ref.get()
        ).data();
        const participants: string[] = Array.from(chatData.members).map((item: any) => String(item.uid));
        const viewers: any =
          chatData && chatData.last_message && chatData.last_message.viewers ? chatData.last_message.viewers : {};
        const newViewers: any = {};
        const ArrayViewers: string[] = Object.keys(viewers);
        let otherUser: string;
        ArrayViewers.forEach(item => {
          let data = viewers[item];
          if (String(item) === String(user ? user : this.credentials.credentials.uid)) {
            data = {
              news: 0,
              seen: true
            };
          } else {
            const news: number = Number(data.news) + 1;
            data = {
              news,
              seen: false
            };
            otherUser = item;
          }
          newViewers[item] = data;
        });
        const CURRENT_DATE = moment().toDate();
        const last_message: any = {
          date: CURRENT_DATE,
          message,
          user: String(user ? user : this.credentials.credentials.uid),
          receiver: otherUser,
          viewers: newViewers,
          sendMail: sendMail ? sendMail : false
        };
        // Check send Email
        const data = await this.afs
          .collection('chats')
          .doc(chatId)
          .ref.get();
        const lastMessage = data.get('last_message');
        if (lastMessage && lastMessage.sendMail === true && lastMessage.user && !user) {
          const dataUser = await this.getUserData(lastMessage.user);
          // send mail handler
          this.productInfo(
            dataUser.email,
            `${dataUser && dataUser.name ? dataUser.name : ''} ${dataUser && dataUser.lastName1 ? dataUser.lastName1 : ''
            } ${dataUser && dataUser.lastName2 ? dataUser.lastName2 : ''}`,
            chatId
          );
        }
        // !=
        this.afs
          .collection('chats')
          .doc(chatId)
          .update({
            initialized: true,
            last_message_date: CURRENT_DATE,
            last_message_user_id: String(user ? user : this.credentials.credentials.uid),
            last_message,
            participants
          });
        let dataMessage: any = {
          date: moment().toDate(),
          message,
          isFile: isFile ? isFile : false,
          url: url ? url : '',
          sender: String(user ? user : this.credentials.credentials.uid)
        };
        if (label !== undefined && label !== null && label !== '') {
          let myParticipants = participants;
          const index: number = myParticipants.indexOf(String(user ? user : this.credentials.credentials.uid));
          const userID = myParticipants[0];
          if (index > -1) {
            myParticipants.splice(index, 1);
          }
          let docData: any = {
            chatID: chatId,
            label: label,
            sender: String(user ? user : this.credentials.credentials.uid),
            to: userID,
            viewed: false,
            date: moment().toDate()
          };
          const resp = await this.afs.collection('chat-metrics').add(docData);
          dataMessage.chat_metric_doc_id = resp.id;
          dataMessage.metric_label = label;
        }
        await this.afs
          .collection('chats')
          .doc(chatId)
          .collection('messages')
          .add(dataMessage);
        return chatData;
      } catch (error) {
        log.error(error);
      }
    }
    return undefined;
  }

  public async createChat(
    userId: string,
    component: any,
    sendHere?: boolean,
    messages?: any[],
    maskedID?: string
  ): Promise<void> {
    const myID: string = maskedID ? maskedID : this.credentials.credentials.uid;
    const ids: string[] = [String(myID) + String(userId), String(userId) + String(myID)];
    const response = await this.ifChatExists(ids);
    if (response.empty == false) {
      if (sendHere && sendHere == true) {
        messages.forEach(element => {
          this.sendChatMessage(
            response.docs[0].id,
            element.message,
            element.convert,
            element.isFile,
            element.url,
            myID,
            element.sendMail,
            element.label
          );
        });
      } else {
        this.goChat(response.docs[0].id, component);
      }
    } else {
      const viewers: any = {};
      viewers[myID] = {
        seen: true,
        news: 0
      };
      viewers[userId] = {
        seen: false,
        news: 0
      };
      const dataSimple1 = await this.getUserData(myID);
      const dataSimple2 = await this.getUserData(userId);
      const member1 = dataSimple1;
      const member2 = dataSimple2;
      const currentDate = moment().toDate();
      const data: any = {
        identifier: ids[0],
        date: currentDate,
        last_message_date: currentDate,
        last_message_user_id: String(myID),
        last_message: {
          message: '',
          date: currentDate,
          user: userId,
          receiver: myID,
          viewers
        },
        initialized: false,
        participants: [String(myID), String(userId)],
        members: [member1, member2]
      };
      const newChat = await this.createNewChat(data);
      if (sendHere && sendHere == true) {
        messages.forEach(element => {
          this.sendChatMessage(
            newChat.id,
            element.message,
            element.convert,
            element.isFile,
            element.url,
            myID,
            element.sendMail,
            element.label
          );
        });
      } else {
        this.goChat(newChat.id, component);
      }
    }
  }

  async createNewChat(data: any) {
    return this.afs.collection('chats').add(data);
  }

  getPreregistros() {
    return this.firestore.collection('preregistro').snapshotChanges();
  }

  deletePreregistro(id: string) {
    return this.firestore
      .collection('preregistro')
      .doc(id)
      .delete();
  }

  async saveState(state: object, cp: Zipcode) {
    return await this.firestore
      .collection('zipcodes')
      .doc(`${cp.postal_code}`)
      .set(state);
  }

  async friendShip(initiator: string, requested: string) {
    try {
      const response1 = await this.firestore
        .collection('contacts')
        .ref.where('initiator', '==', initiator)
        .where('requested', '==', requested)
        .get();
      const response2 = await this.firestore
        .collection('contacts')
        .ref.where('initiator', '==', requested)
        .where('requested', '==', initiator)
        .get();
      if (response1.empty && response2.empty) {
        return await this.firestore.collection('contacts').add({
          dateSent: new Date(),
          initiator: initiator,
          requested: requested,
          status: 'accepted',
          participants: [initiator, requested],
          dateAccepted: new Date()
        });
      } else {
        return undefined;
      }
    } catch (error) {
      log.error(error);
      return undefined
    }
  }

  async saveCP(cp: Zipcode) {
    return await this.firestore
      .collection('zipcodes')
      .doc(`${cp.postal_code}`)
      .collection('colonies')
      .add(cp);
  }

  async getZipcode(cp: string): Promise<any> {
    const response = await this.firestore
      .collection('zipcodes')
      .doc(`${cp}`)
      .ref.get();
    if (response.exists === true) {
      const data: any = response.data();
      const id = response.id;
      return { id, ...data };
    }
  }

  async content() {
    try {
      return await this.firestore.collection('products').ref.get();
    } catch (error) {
      return undefined;
    }
  }

  async getRepData(uid: string): Promise<any> {
    try {
      const response: any = await this.firestore
        .collection('representante-meta')
        .doc(uid)
        .ref.get();
      const response2 = await (response.data().company as DocumentReference).get();
      const company = { company: { uid: response2.id, ...response2.data() } };
      return { uid: response.id, ...response.data(), ...company };
    } catch (error) {
      return error;
    }
  }

  async getUsers(type: string) {
    try {
      return await this.firestore
        .collection('users')
        .ref.where('type', '==', type)
        .get();
    } catch (error) {
      return error;
    }
  }

  async getUsers2(type: string, start?: any) {
    try {
      start;
      return this.firestore.collection('users').ref.where('type', '==', type);
    } catch (error) {
      return error;
    }
  }

  async getCompanies() {
    try {
      return await this.firestore.collection('companies').get();
    } catch (error) {
      return error;
    }
  }

  async getStates() {
    try {
      return await this.firestore
        .collection('states-in-use')
        .ref.orderBy('name', 'asc')
        .get();
    } catch (error) {
      return error;
    }
  }

  async getSpecialties() {
    try {
      return await this.firestore
        .collection('specialties-in-use')
        .ref.orderBy('name', 'asc')
        .get();
    } catch (error) {
      return error;
    }
  }

  async getUserByEmail(email: string, type: string) {
    try {
      return await this.firestore
        .collection('users')
        .ref.where('email', '==', email)
        .where('type', '==', type)
        .get();
    } catch (error) {
      return error;
    }
  }

  async sendFriendShip(initiator: string, requested: string) {
    const response1 = await this.firestore
      .collection('contacts')
      .ref.where('initiator', '==', initiator)
      .where('requested', '==', requested)
      .get();
    const response2 = await this.firestore
      .collection('contacts')
      .ref.where('initiator', '==', requested)
      .where('requested', '==', initiator)
      .get();
    if (response1.empty && response2.empty) {
      return await this.firestore.collection('contacts').add({
        dateSent: new Date(),
        initiator,
        requested,
        status: 'accepted',
        participants: [initiator, requested],
        from: 'admin',
        type: 'massive-assign',
        dateAccepted: new Date()
      });
    } else {
      return '';
    }
  }

  async searchUserEmail(email: string): Promise<any> {
    try {
      return await this.firestore
        .collection('users')
        .ref.where('email', '==', email)
        .get();
    } catch (error) {
      return error;
    }
  }

  async searchUserUid(uid: string): Promise<any> {
    try {
      return await this.firestore
        .collection('users')
        .doc(uid)
        .ref.get();
    } catch (error) {
      return error;
    }
  }

  async loadPointsAdmin(pointsObject: any) {
    const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
    if (sendPoints && sendPoints.success && sendPoints.success === true) {
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async callFunctionFB(name: string, data: object) {
    return this.fns
      .httpsCallable(name)(data)
      .toPromise();
  }

  //////////////////

  async createFriendShip(initiator: string, requested: string) {
    const response1 = await this.firestore
      .collection('contacts')
      .ref.where('initiator', '==', initiator)
      .where('requested', '==', requested)
      .get();
    const response2 = await this.firestore
      .collection('contacts')
      .ref.where('initiator', '==', requested)
      .where('requested', '==', initiator)
      .get();
    if (response1.empty === true && response2.empty === true) {
      return await this.firestore.collection('contacts').add({
        dateSent: new Date(),
        initiator,
        requested,
        from: 'admin',
        type: 'massive-assign',
        status: 'accepted',
        participants: [initiator, requested]
      });
    } else {
      return undefined;
    }
  }

  public async getRelationByIDs(
    initiator: string,
    requested: string
  ): Promise<{ ref: QueryDocumentSnapshot<DocumentData>; initiator: string; requested: string }> {
    try {
      let ref: QueryDocumentSnapshot<DocumentData>;
      const proof1 = await this.firestore
        .collection('contacts')
        .ref.where('initiator', '==', initiator)
        .where('requested', '==', requested)
        .get();
      const proof2 = await this.firestore
        .collection('contacts')
        .ref.where('initiator', '==', requested)
        .where('requested', '==', initiator)
        .get();
      if (proof1.empty === false) {
        ref = proof1.docs[0];
      } else if (proof2.empty === false) {
        ref = proof2.docs[0];
      }
      return { ref: ref, initiator: initiator, requested: requested };
    } catch (error) {
      return undefined;
    }
  }

  async getDoctorData(uid: string): Promise<any> {
    try {
      const response = await this.firestore
        .collection('medico-meta')
        .doc(uid)
        .ref.get();
      const data: any = response.data();
      if (data && data.specialty1 && data.specialty1.id && Number(data.specialty1.id) > 0) {
        const specialty = await this.wpv2.getSpecialty(Number(data.specialty1.id));
        data.specialty1.name = specialty.name;
      }
      if (data && data.specialty2 && data.specialty2.id && Number(data.specialty2.id) > 0) {
        const specialty = await this.wpv2.getSpecialty(Number(data.specialty2.id));
        data.specialty2.name = specialty.name;
      }
      return { uid: response.id, ...data };
    } catch (error) {
      return error;
    }
  }

  async deleteFriendShip(id: string, initiator?: string, requested?: string) {
    if (id) {
      await this.firestore
        .collection('contacts')
        .doc(id)
        .delete();

      if (initiator && requested) {
        await this.firestore.doc(`friends/${initiator}/friends/${requested}`).delete();
        await this.firestore.doc(`friends/${requested}/friends/${initiator}`).delete();

        const resp1: any = await this.firestore.doc(`users/${initiator}`).ref.get();
        const resp2: any = await this.firestore.doc(`users/${requested}`).ref.get();

        const iniData: any = { id: resp1.id, ...resp1.data() };
        const reqData: any = { id: resp2.id, ...resp2.data() };

        /////////Delete abcedario
        if (iniData && iniData.firstCharacter !== '' && iniData.type !== '') {
          const ref: string = `friends/${requested}/information/counters/alphabet/${iniData.type}/letters/${iniData.firstCharacter}`;
          await this.firestore.doc(`${ref}/users/${initiator}`).delete();
        }
        if (reqData && reqData.firstCharacter !== '' && reqData.type !== '') {
          const ref: string = `friends/${initiator}/information/counters/alphabet/${reqData.type}/letters/${reqData.firstCharacter}`;
          await this.firestore.doc(`${ref}/users/${requested}`).delete();
        }

        //////Delete filters
        if (iniData && iniData['filter-meta-data'] !== '' && iniData.type !== '') {
          const array: string[] = Array.from(iniData['filter-meta-data']);
          for (const filter of array) {
            if (filter !== '') {
              const ref: string = `friends/${requested}/information/counters/filters/type/${iniData.type}/${filter}`;
              await this.firestore.doc(`${ref}/users/${initiator}`).delete();
            }
          }
        }
        if (reqData && reqData['filter-meta-data'] !== '' && reqData.type !== '') {
          const array: string[] = Array.from(reqData['filter-meta-data']);
          for (const filter of array) {
            if (filter !== '') {
              const ref: string = `friends/${initiator}/information/counters/filters/type/${reqData.type}/${filter}`;
              await this.firestore.doc(`${ref}/users/${requested}`).delete();
            }
          }
        }
      }
      return { success: true, initiator: initiator, requested: requested };
    } else {
      return { success: false, initiator: initiator, requested: requested };
    }
  }

  public async related(uid: string): Promise<boolean> {
    try {
      const proof1 = await this.firestore
        .collection('contacts')
        .ref.where('initiator', '==', this.credentials.credentials.uid)
        .where('requested', '==', uid)
        .get();
      const proof2 = await this.firestore
        .collection('contacts')
        .ref.where('initiator', '==', uid)
        .where('requested', '==', this.credentials.credentials.uid)
        .get();
      return !proof1.empty || !proof2.empty ? true : false;
    } catch (error) {
      return undefined;
    }
  }

  async getRelationShipByPaticipants(user: string): Promise<string> {
    try {
      let idsRelationShips: string[] = [];
      const response1 = await this.firestore
        .collection('contacts')
        .ref.where('initiator', '==', this.credentials.credentials.uid)
        .where('requested', '==', user)
        .get();
      const response2 = await this.firestore
        .collection('contacts')
        .ref.where('initiator', '==', user)
        .where('requested', '==', this.credentials.credentials.uid)
        .get();
      idsRelationShips = idsRelationShips.concat(response1.docs.map(e => e.id));
      idsRelationShips = idsRelationShips.concat(response2.docs.map(e => e.id));
      if (idsRelationShips.length > 0) {
        return idsRelationShips[0];
      }
    } catch (error) {
      log.error(error);
    }
    return undefined;
  }

  async confirmCancel(relation: string) {
    const alert = await this.alertController.create({
      header: 'Cancelar solicitud',
      message: '¿Estás seguro de cancelar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.cancelRequest(relation);
          }
        }
      ]
    });
    await alert.present();
  }

  async cancelRequest(id: string) {
    const defaultUser: boolean =
      DEFAULT_USERS_CONNECTIONS.map(item => item.email).indexOf(this.credentials.credentials.email) > -1 ? true : false;
    try {
      if (this.credentials.credentials.type === 'representante-medico' && defaultUser === true) {
        const response = await this.deleteFriendShip(id);
        if (response && response.success && response.success === true) {
          this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      } else if (this.credentials.credentials.type === 'representante-medico' && defaultUser === false) {
        const pointsObject = {
          points: 232,
          note: 'Reembolso por cancelar solitud conexión',
          user_id: this.credentials.credentials.uid
        };
        const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
        if (sendPoints && sendPoints.success && sendPoints.success === true) {
          const response = await this.deleteFriendShip(id);
          if (response && response.success && response.success === true) {
            this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
          }
        } else {
          this.utilities.toast('Error al reembolsar saldo a la cuenta.', 'Reembolso por cancelar solitud conexión');
        }
      } else {
        const pointsObject = {
          points: -50,
          note: 'Descuento por cancelar solitud conexión',
          user_id: this.credentials.credentials.uid
        };
        const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
        if (sendPoints && sendPoints.success && sendPoints.success === true) {
          const response = await this.deleteFriendShip(id);
          if (response && response.success && response.success === true) {
            this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
          }
        } else {
          this.utilities.toast('Error al descontar puntos de la cuenta.', 'Descuento por cancelar solitud conexión');
        }
      }
    } catch (error) {
      log.error(error);
    }
  }

  async confirmAccept(relation: string, userid: string) {
    const alert = await this.alertController.create({
      header: 'Aceptar solicitud',
      message: '¿Estás seguro de aceptar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.acceptRequest(relation, userid);
          }
        }
      ]
    });
    await alert.present();
  }

  async acceptRequest(id: string, userid: string) {
    const defaultUser: boolean =
      DEFAULT_USERS_CONNECTIONS.map(item => item.email).indexOf(this.credentials.credentials.email) > -1 ? true : false;
    try {
      if (this.credentials.credentials.type === 'representante-medico' && defaultUser === true) {
        const response = await this.acceptFriendships(id);
        if (response.success === true) {
          this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      } else if (this.credentials.credentials.type === 'representante-medico' && defaultUser === false) {
        const user = await this.getUserData(this.credentials.credentials.uid);
        const points: number = user.points ? user.points : 0;
        if (points < 232) {
          this.confirmGoPayments();
        } else {
          const pointsObject = {
            points: -232,
            note: 'Cargo por conexión',
            user_id: this.credentials.credentials.uid
          };
          const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
          if (sendPoints && sendPoints.success && sendPoints.success === true) {
            const response = await this.acceptFriendships(id);
            if (response.success === true) {
              this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
            } else {
              this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
            }
          } else {
            this.utilities.toast('Error al descontar saldo de la cuenta.', 'Cargo por conexión');
          }
        }
      } else {
        const otherUser = await this.getUserData(userid);
        if (otherUser.type !== 'medico') {
          const pointsObject = {
            points: 50,
            note: 'Abono por conexión',
            user_id: this.credentials.credentials.uid
          };
          const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
          if (sendPoints && sendPoints.success && sendPoints.success === true) {
            const response = await this.acceptFriendships(id);
            if (response.success === true) {
              this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
            } else {
              this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
            }
          } else {
            this.utilities.toast('Error al abonar puntos a la cuenta.', 'Cargo por conexión');
          }
        } else {
          const response = await this.acceptFriendships(id);
          if (response.success === true) {
            this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
          }
        }
      }
    } catch (error) {
      log.error(error);
    }
  }

  async acceptFriendships(id: string): Promise<any> {
    try {
      const result = await this.firestore
        .collection('contacts')
        .doc(id)
        .update({ status: 'accepted', dateAccepted: moment().toDate() });
      return { success: true };
    } catch (error) {
      return error;
    }
  }

  async getUserData(uid: string): Promise<any> {
    try {
      const response: any = await this.firestore
        .collection('users')
        .doc(uid)
        .ref.get();
      if (response.exists) {
        return { uid: response.id, ...response.data() };
      } else {
        return;
      }
    } catch (error) {
      return error;
    }
  }

  async confirmGoPayments() {
    const alert = await this.alertController.create({
      header: 'Saldo insuficiente',
      message: '¿Ir a agregar saldo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Ir',
          handler: () => {
            this.route.navigateByUrl('/invoice/cards');
          }
        }
      ]
    });
    await alert.present();
  }

  async confirmReject(relation: string, userId: string) {
    const alert = await this.alertController.create({
      header: 'Rechazar solicitud',
      message: '¿Estás seguro de rechazar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.rejectRequest(relation, userId);
          }
        }
      ]
    });
    await alert.present();
  }

  async rejectRequest(relation: string, userId: string) {
    const defaultUser: boolean =
      DEFAULT_USERS_CONNECTIONS.map(item => item.email).indexOf(this.credentials.credentials.email) > -1 ? true : false;
    try {
      if (this.credentials.credentials.type === 'representante-medico' && defaultUser === true) {
        const response = await this.deleteFriendShip(relation);
        if (response && response.success && response.success === true) {
          this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      } else if (this.credentials.credentials.type === 'representante-medico' && defaultUser === false) {
        const pointsObject = {
          points: -50,
          note: 'Descuento por cancelación de solitud conexión',
          user_id: userId
        };
        const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
        if (sendPoints && sendPoints.success && sendPoints.success === true) {
          const response = await this.deleteFriendShip(relation);
          if (response && response.success && response.success === true) {
            this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
          }
        } else {
          this.utilities.toast(
            'Error al reembolsar saldo a la cuenta.',
            'Descuento por cancelación de solitud conexión'
          );
        }
      } else {
        const pointsObject = {
          points: 232,
          note: 'Reembolso por cancelación de solitud conexión',
          user_id: userId
        };
        const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
        if (sendPoints && sendPoints.success && sendPoints.success === true) {
          const response = await this.deleteFriendShip(relation);
          if (response && response.success && response.success === true) {
            this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
          }
        } else {
          this.utilities.toast(
            'Error al descontar puntos de la cuenta.',
            'Reembolso por cancelación de solitud conexión'
          );
        }
      }
    } catch (error) {
      log.error(error);
    }
  }

  async getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        this.fireAuth.authState.subscribe(
          async state => {
            const token = await state.getIdToken(true);
            resolve(token);
          },
          error => {
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  async createCompany(body: any): Promise<any> {
    try {
      const response = await this.firestore
        .collection('companies')
        .ref.where('name', '==', body.name)
        .get();
      if (response.empty === true) {
        return await this.firestore.collection('companies').add(body);
      } else {
        return { id: response.docs[0].id };
      }
    } catch (error) {
      return error;
    }
  }

  async activateUser(id: string, deactivate?: boolean) {
    try {
      const status = deactivate === true ? 'inactive' : 'active';
      const response = await this.firestore
        .collection('users')
        .doc(id)
        .update({
          status: status
        });
      return { success: true, id: id };
    } catch (error) {
      log.error(error);
      return { success: false, id: id };
    }
  }

  async desactiveProductsFromRepresentant(uid: string, activate: 'active' | 'inactive') {
    let query: Query = await this.firestore.collection('products').ref;
    query = query.where('uid', '==', uid);
    const snaps = await query.get();
    log.debug(snaps.docs);
    for (const product of snaps.docs) {
      await this.firestore.doc(`products/${product.id}`).update({ status: activate });
    }
  }

  async checkInactiveCompanyFromUsers(users: any[], active: boolean) {
    const companiesUids = await this.getCompaniesFromUsers(users);

    if (companiesUids) {
      for (const uid of companiesUids) {
        const companiReps = await this.firestore
          .collection(`users`)
          .ref.where('filter-meta-data', 'array-contains', uid)
          .get();

        const representants = [];
        let allInactive = true;
        for (const rep of companiReps.docs) {
          if ((rep.data() as any).status === 'active') {
            allInactive = false;
          }

          representants.push(rep.data());
        }

        if (!allInactive && active) {
          this.firestore.doc(`companies/${uid}`).update({ status: 'active' });
        } else if (allInactive && !active) {
          this.firestore.doc(`companies/${uid}`).update({ status: 'inactive' });
        }
      }
    }
  }

  async getCompaniesFromUsers(users: any[]) {
    const array: any = {};
    for (const user of users) {
      for (const metaData of user['filter-meta-data']) {
        try {
          const data = await this.firestore.doc(`/companies/${metaData}`).valueChanges();

          const promise = new Promise(resolve => {
            const fun = data.subscribe(resp => {
              if (resp) {
                array[(metaData)] = resp;
                resolve(resp);
              }
              fun.unsubscribe();
            });
          });
          await promise;
        } catch (error) { }
      }
    }
    const companies = [];
    // tslint:disable-next-line: forin
    for (const key in array) {
      companies.push(key);
    }
    return companies;
  }

  async getUsersFromCampanyId(id: string) {
    const snap = await this.firestore
      .collection('users')
      .ref.where('filter-meta-data', 'array-contains', id)
      .get();
    if (snap.empty) {
      return null;
    } else {
      const users = [];
      for (const doc of snap.docs) {
        users.push({ ...doc.data() as any, id: doc.id });
      }
      return users;
    }
  }

  async updateAvatar(uid: string, avatar: any): Promise<void> {
    try {
      return this.firestore
        .collection('users')
        .doc(uid)
        .update({
          avatar
        });
    } catch (error) {
      return undefined;
    }
  }

  async getType(data: { repreId?: string; productId?: string; link?: string; advertising: any }) {
    let repreData: any = null;
    let productData: any = null;
    let params: any = {};
    let templateId: number;
    let type: string;

    if (data.link) {
      params.link = {};
      params.link.url = data.link;
      params.link.description = data && data.advertising && data.advertising.content ? data.advertising.content : '';
      templateId = 75;
      type = 'link';
    }

    if (data.repreId) {
      repreData = await this.getUserData(data.repreId);
      let company: string = '';
      try {
        const REP: any = (await this.afs.doc(`representante-meta/${data.repreId}`).ref.get()).data();
        const ref = REP.company as DocumentReference;
        const COMAPANY = await ref.get();
        company = COMAPANY.get('name');
      } catch (error) {
        log.error(error);
      }
      params.rep = {};
      params.rep.name = `${repreData.name} ${repreData.lastName1} ${repreData.lastName2}`;
      params.rep.email = String(repreData.email).toLocaleLowerCase();
      params.rep.phone1 = repreData.mobile;
      params.rep.phone2 = repreData.phone;
      params.rep.company = company;
      templateId = 80;
      type = 'representant';
    }

    if (data.productId) {
      productData = await this.getProductData(data.productId);
      repreData = await this.getUserData(productData.uid);
      let company: string = '';
      try {
        const refCompany = productData.company as DocumentReference;
        company = (await this.afs.doc(`companies/${refCompany.id}`).ref.get()).get('name');
      } catch (error) {
        log.error(error);
      }
      params.product = {};
      params.product.name = productData.marca;
      params.product.type = productData.type;
      params.product.therapeutic_lines = `${productData.linea_terapeutica_1}, ${productData.linea_terapeutica_2}`;
      params.product.presentations = `${productData.presentacion_1}, ${productData.presentacion_2}`;
      params.product.active_substances = `${productData.producto_sustancia_activa_1}, ${productData.producto_sustancia_activa_2}`;
      params.product.company = company;
      params.rep.name = `${repreData.name} ${repreData.lastName1} ${repreData.lastName2}`;
      params.rep.email = String(repreData.email).toLocaleLowerCase();
      templateId = 79;
      type = 'product';
    }

    return params;
  }

  async getUsersBanners(id: string, date: string) {
    try {
      const stringRef: string = `advertising/${id}/clicks/${date}/users`;
      const resp = await this.firestore
        .collection(stringRef)
        .ref.orderBy('dateClick', 'desc')
        .get();
      return resp.docs.map(item => {
        let data: any = item.data();
        data.date = moment(data.dateClick.toDate()).format('YYYY-MM-DD HH:mm');
        delete data.dateClick;
        const id: string = item.id;
        return { id, ...data };
      });
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }
}
