import { FIREBASE_API, CLICK_MEETING } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { CertificatesComponent } from '../certificates/certificates.component';
const log = new Logger('ConferenceComponent');
import * as moment from 'moment';
import { Logger } from '@app/core';
import * as XLSX from 'xlsx';

const monthsES = [
  {
    name: 'enero',
    value: '01'
  },
  {
    name: 'febrero',
    value: '02'
  },
  {
    name: 'marzo',
    value: '03'
  },
  {
    name: 'abril',
    value: '04'
  },
  {
    name: 'mayo',
    value: '05'
  },
  {
    name: 'junio',
    value: '06'
  },
  {
    name: 'julio',
    value: '07'
  },
  {
    name: 'agosto',
    value: '08'
  },
  {
    name: 'septiembre',
    value: '09'
  },
  {
    name: 'octubre',
    value: '10'
  },
  {
    name: 'noviembre',
    value: '11'
  },
  {
    name: 'diciembre',
    value: '12'
  }
];

const monthsEN = [
  {
    name: 'january',
    value: '01'
  },
  {
    name: 'february',
    value: '02'
  },
  {
    name: 'march',
    value: '03'
  },
  {
    name: 'april',
    value: '04'
  },
  {
    name: 'may',
    value: '05'
  },
  {
    name: 'june',
    value: '06'
  },
  {
    name: 'july',
    value: '07'
  },
  {
    name: 'august',
    value: '08'
  },
  {
    name: 'september',
    value: '09'
  },
  {
    name: 'october',
    value: '10'
  },
  {
    name: 'november',
    value: '11'
  },
  {
    name: 'december',
    value: '12'
  }
];

@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.scss']
})
export class ConferenceComponent implements OnInit {
  @ViewChild('csvFile', { read: ElementRef, static: true }) csvFile: ElementRef;
  public item: any = {};
  public sessions: any[] = [];
  public id: string = '';
  private session: any = undefined;

  constructor(
    private http: HttpClient,
    private aRoute: ActivatedRoute,
    private utilities: UtilitiesService,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    private afs: AngularFirestore,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    if (this.aRoute && this.aRoute.snapshot && this.aRoute.snapshot.params && this.aRoute.snapshot.params.id) {
      this.id = this.aRoute.snapshot.params.id;
      this.init(this.id);
    }
  }

  async init(id: any) {
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      await this.initClickMeetingDetail(id);
      await this.initClickMeetingSessions(id);
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
  }

  async initClickMeetingDetail(id: string) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const resp: any = await this.http
        .post(`${FIREBASE_API}clickMeetingV2`, { route: `conferences/${id}` }, { headers: headers })
        .toPromise();
      let data = resp.response;
      data.conference.inicio = moment(data.conference.starts_at).format('YYYY-MM-DD HH:mm');
      data.conference.fin = moment(data.conference.ends_at).format('YYYY-MM-DD HH:mm');
      data.conference.trustHtml = this.utilities.trustHtml(data.conference.lobby_description);
      this.item = data;
    } catch (error) {
      log.error(error);
    }
  }

  async initClickMeetingSessions(id: string) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const resp: any = await this.http
        .post(`${FIREBASE_API}clickMeetingV2`, { route: `conferences/${id}/sessions` }, { headers: headers })
        .toPromise();
      let data = resp.response;
      let sessions = Array.from(data).map((e: any) => {
        let data = e;
        data.inicio = moment(data.start_date).format('YYYY-MM-DD HH:mm');
        data.fin = moment(data.end_date).format('YYYY-MM-DD HH:mm');
        return data;
      });
      for (let session of sessions) {
        const resp = await this.afs.doc(`click-meeting-conferences/${id}/sessions/${session.id}`).ref.get();
        if (resp.exists === true) {
          session.isInBQ = true;
        } else {
          session.isInBQ = false;
        }
      }
      this.sessions = sessions;
    } catch (error) {
      log.error(error);
    }
  }

  async saveToBQ(data: any) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const resp: any = await this.http
        .post(`${FIREBASE_API}clickMeetingToBQ`, { data: data }, { headers: headers })
        .toPromise();
      return resp.response;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async getAttendees(id: string): Promise<any[]> {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const resp: any = await this.http
        .post(
          `${FIREBASE_API}clickMeetingV2`,
          { route: `conferences/${this.id}/sessions/${id}/attendees` },
          { headers: headers }
        )
        .toPromise();
      let data = resp.response;
      let uniqueArray: any[] = [];
      let uniqueObj: any = {};
      for (let item of data) {
        if (uniqueObj && uniqueObj[item.email] && uniqueObj[item.email].date) {
          const start_date = new Date(item.start_date);
          const end_date = new Date(item.end_date);
          uniqueObj[item.email].date.push({ start_date: start_date, end_date: end_date });
        } else {
          let data = item;
          const start_date = new Date(item.start_date);
          const end_date = new Date(item.end_date);
          data.date = [{ start_date: start_date, end_date: end_date }];
          uniqueObj[item.email] = data;
        }
      }
      uniqueArray = Object.values(uniqueObj);
      uniqueArray = uniqueArray.filter(e => e.role === 'listener');
      uniqueArray = uniqueArray.map(e => {
        let data = {
          id: e && e.id ? String(e.id) : null,
          uid: e && e.uid ? String(e.uid) : null,
          role: e && e.role ? String(e.role) : null,
          nickname: e && e.nickname ? String(e.nickname) : null,
          email: e && e.email ? String(e.email) : null,
          device: e && e.device ? String(e.device) : null,
          start_date: e && e.start_date ? e.start_date : null,
          end_date: e && e.end_date ? e.end_date : null,
          country_iso3: e && e.country_iso3 ? String(e.country_iso3) : null,
          city: e && e.city ? String(e.city) : null,
          date: e && e.date ? e.date : null,
          conferenceId: e && e.conferenceId ? String(e.conferenceId) : null,
          sessionId: e && e.sessionId ? String(e.sessionId) : null,
          conference: e && e.conference ? String(e.conference) : null,
          conference_end_date: e && e.conference_start_date ? e.conference_start_date : null,
          conference_start_date: e && e.conference_start_date ? e.conference_start_date : null,
          duration: ''
        };
        let milliseconds: number = 0;
        for (let date of Array.from(data.date)) {
          let _date: any = date;
          const start = moment(_date.start_date);
          const end = moment(_date.end_date);
          var _duration = moment.duration(end.diff(start));
          milliseconds += _duration.asMilliseconds();
        }
        const duration = moment.duration(milliseconds);
        const d = moment.utc(moment.duration(duration, 'milliseconds').asMilliseconds()).format('HH:mm');
        data.duration = d;
        return data;
      });
      return uniqueArray;
    } catch (error) {
      log.error(error);
      return [];
    }
  }

  async getChats(item: any) {
    const id = item.id;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      let url = `${CLICK_MEETING.url}/chats/${id}?api_key=${CLICK_MEETING['X-Api-Key']}`;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const resp: any = await this.http
        .post(`${FIREBASE_API}fetchFile`, { url: url }, { headers: headers })
        .toPromise();
      if (resp && resp.response) {
        const array = await this.readOnLoadV2(resp.response) || [];
        array.shift();
        await this.mainHandler(array, item, id);
        await this.init(this.id);
        this.alertSuccess(id);
      } else {
        this.alertNotFound(id);
      }
    } catch (error) {
      log.error(error);
      this.alertError(item);
    }
    loading.dismiss();
  }

  async mainHandler(allComments: any[], item: any, id: string) {
    let attendees = await this.getAttendees(id);
    const currentDate = new Date(item.end_date);
    allComments = allComments.map(item => {
      const conference = this.item.conference.name;
      const conferenceId: string = String(this.id);
      const sessionId: string = String(id);
      const conference_end_date = moment(currentDate).toDate();
      const conference_start_date = moment(currentDate).toDate();
      return { ...item, conferenceId, sessionId, conference, conference_end_date, conference_start_date };
    });
    allComments = allComments.map(e => {
      let data = {
        date: e && e.date ? e.date : null,
        email: e && e.email ? String(e.email) : null,
        name: e && e.name ? String(e.name) : null,
        comment: e && e.comment ? String(e.comment) : null,
        conferenceId: e && e.conferenceId ? String(e.conferenceId) : null,
        sessionId: e && e.sessionId ? String(e.sessionId) : null,
        conference: e && e.conference ? String(e.conference) : null,
        conference_end_date: e && e.conference_end_date ? e.conference_end_date : null,
        conference_start_date: e && e.conference_start_date ? e.conference_start_date : null
      };
      return data;
    });
    attendees = attendees.map(item => {
      const conference = this.item.conference.name;
      const conferenceId: string = String(this.id);
      const sessionId: string = String(id);
      const conference_end_date = moment(currentDate).toDate();
      const conference_start_date = moment(currentDate).toDate();
      item.start_date = new Date(item.start_date);
      item.end_date = new Date(item.end_date);
      return { ...item, conferenceId, sessionId, conference, conference_end_date, conference_start_date };
    });
    //save to bigquery
    await this.saveToBQ({ comments: allComments, attendees: attendees });
    //success
    await this.afs.doc(`click-meeting-conferences/${this.id}/sessions/${id}`).set({
      ...item,
      conference: this.item.conference.name,
      conferenceId: Number(this.id),
      sessionId: Number(id)
    });
  }

  readOnLoadV2(text: any) {
    let all: any[] = [];
    for (let row of String(text).split('\n')) {
      let arreglo: string[] = [];
      for (let row2 of row.split('",')) {
        arreglo = arreglo
          .concat(row2.split(',"'))
          .map(item => item.replace('"', ''))
          .map(item => item.replace('"', ''));
      }
      let dateRaw = arreglo[0];
      let language = undefined;
      dateRaw = String(dateRaw)
        .toLowerCase()
        .trim();
      for (let month of monthsEN) {
        dateRaw = dateRaw.replace(month.name, month.value);
      }
      for (let month of monthsES) {
        dateRaw = dateRaw.replace(month.name, month.value);
      }
      const indexEN = dateRaw.indexOf(',');
      if (indexEN > -1) {
        language = 'english';
      }
      const indexES = dateRaw.indexOf('de');
      if (indexES > -1) {
        language = 'spanish';
      }
      dateRaw = dateRaw.replace(',', '');
      dateRaw = dateRaw.replace('de', '');
      dateRaw = dateRaw.replace(/\s+/g, ' ');
      if (language === 'english') {
        if (dateRaw.split(' ')[1].length === 1) {
          dateRaw = `0${dateRaw.split(' ')[1]}/${dateRaw.split(' ')[0]}/${dateRaw.split(' ')[2]} ${dateRaw.split(' ')[3]
            } ${dateRaw.split(' ')[4]}`;
        } else {
          dateRaw = `${dateRaw.split(' ')[1]}/${dateRaw.split(' ')[0]}/${dateRaw.split(' ')[2]} ${dateRaw.split(' ')[3]
            } ${dateRaw.split(' ')[4]}`;
        }
      } else {
        if (dateRaw.split(' ')[0].length === 1) {
          dateRaw = `0${dateRaw.split(' ')[0]}/${dateRaw.split(' ')[1]}/${dateRaw.split(' ')[2]} ${dateRaw.split(' ')[3]
            } ${dateRaw.split(' ')[4]}`;
        } else {
          dateRaw = `${dateRaw.split(' ')[0]}/${dateRaw.split(' ')[1]}/${dateRaw.split(' ')[2]} ${dateRaw.split(' ')[3]
            } ${dateRaw.split(' ')[4]}`;
        }
      }
      let data = {
        date: moment(dateRaw.toLocaleUpperCase(), 'YYYY-MM-DD HH:mm:ss').toDate(),
        email: arreglo[1],
        name: arreglo[2],
        comment: arreglo[3]
      };
      all.push(data);
    }
    all = all.filter(e => e.email !== undefined);
    return all;
  }

  async alertNotFound(id: string) {
    const alert = await this.alertController.create({
      header: `No se encontró el archivo zip`,
      message: `No se encontró el archivo zip que contiene la información correspondiente a la sesion: "${id}", por favor revise el panel de ClickMeeting.`,
      buttons: [
        {
          text: 'Entendido',
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  async alertError(item: any) {
    const alert = await this.alertController.create({
      header: `Ha ocurrido un error`,
      message: `Ha ocurrido un error al descargar la información la sesion: "${item.id}", ¿Desea subir la información manualmente?.`,
      buttons: [
        {
          text: 'Subir manualmente',
          cssClass: 'secondary',
          handler: () => {
            //Do stuff
            this.triggerInputFile(item);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancer'
        }
      ]
    });
    await alert.present();
  }

  async alertSuccess(id: string) {
    const alert = await this.alertController.create({
      header: `Correcto`,
      message: `Se ha guardo la información de la sesión: “${id}” de forma correcta. Consulte en BigQuery.`,
      buttons: [
        {
          text: 'Ok',
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  async attendees(id: string) {
    try {
      await this.getAttendees(id);
    } catch (error) {
      log.error(error);
    }
  }

  triggerInputFile(item: any) {
    this.session = item;
    this.csvFile.nativeElement.click();
  }

  async selectFile1(target: any) {
    const file: FileList = target.files;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      const id = this.session.id;
      const item = this.session;
      const buffer = await file.item(0).arrayBuffer();
      const data = XLSX.read(buffer, { type: 'array' });
      let finalObject: any = {};
      data.SheetNames.forEach(sheetName => {
        let rowObject = XLSX.utils.sheet_to_json(data.Sheets[sheetName]);
        finalObject[sheetName] = rowObject;
      });
      let array = (finalObject.Data as Array<any>).map(item => {
        return {
          date: moment(item.Time, 'YYYY-MM-DD HH:mm:ss').toDate(),
          email: item.Email,
          name: item['User nickname'],
          comment: item.Message
        }
      });
      array = array.filter(e => e.email !== undefined);
      await this.mainHandler(array, item, id);
      await this.init(this.id);
      this.csvFile.nativeElement.value = '';
      this.alertSuccess(id);
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
  }

  async getFile(file: File) {
    return await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener('load', (data: any) => {
        resolve(data.currentTarget.result);
      });
      reader.addEventListener('error', error => {
        log.error(error);
        reject(error);
      });
      reader.readAsText(file);
      this.session = undefined;
    });
  }

  async certificates(item: any, item2: any) {
    try {
      const modal = await this.modalCtrl.create({
        component: CertificatesComponent,
        componentProps: {
          item: item,
          item2: item2
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }
}
