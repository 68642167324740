<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/plans"></ion-back-button>
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Representantes en plan</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-margin ion-no-padding" color="light">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <ion-card-header [color]="plan?.status === 'active' ? 'secondary' : 'medium'">
            <ion-card-title> {{ plan?.name }} <small *ngIf="plan?.default === true">(Default)</small> </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <h2>
              <strong>
                Max. Productos:
              </strong>
              <ion-text color="tertiary">
                <strong>
                  {{ plan?.max_products_limit === true ? plan?.max_products : 'Ilimitado' }}
                </strong>
              </ion-text>
            </h2>
            <h2>
              <strong>
                Max. Contactos:
              </strong>
              <ion-text color="tertiary">
                <strong>
                  {{ plan?.max_contacts_limit === true ? plan?.max_contacts : 'Ilimitado' }}
                </strong>
              </ion-text>
            </h2>
            <h2>
              <strong>
                Estatus:
              </strong>
              <ion-text [color]="plan?.status === 'active' ? 'tertiary' : 'danger'">
                <strong>
                  {{ plan?.status === 'active' ? 'Activo' : 'Inactivo' }}
                </strong>
              </ion-text>
            </h2>
            <p>{{ plan?.description }}</p>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-list-header>
            <ion-label>Representantes:</ion-label>
          </ion-list-header>
          <ion-item *ngFor="let item of mainArray; let i = index">
            <ion-label>
              <app-user-card [index]="i + 1" [item]="item" [doNotRouting]="true"> </app-user-card>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="confirmDelete(item.id, i)" color="danger">
                <ion-icon slot="icon-only" name="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
        <p class="ion-padding" *ngIf="!(mainArray && mainArray[0])">Sin resultados</p>
        <ion-button (click)="paginate('forward')" expand="block" fill="clear" [disabled]="!cursorNext">
          Cargar más
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button
      color="tertiary"
      (click)="selectUsersFunction('representante-medico')"
      [disabled]="isLoading == true"
    >
      <ion-icon name="person"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

<!-- <ion-footer>
  <ion-toolbar color="secondary">
    <ion-buttons>
      <ion-button (click)="save()">
        Guardar
        <ion-icon slot="end" name="save"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer> -->
