<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Activar/Desactivar muestras médicas</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <ion-button expand="block" color="secondary" (click)="companiesFilter()" [disabled]="isLoading === true">
          <ion-icon slot="start" name="options"></ion-icon>
          Filtrar por empresa
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-card *ngIf="company" color="light">
          <ion-card-header color="tertiary">
            <ion-card-title>{{ company?.name }}</ion-card-title>
            <ion-card-subtitle>{{ company?.companyWebsite }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <p class="ion-padding">{{ company?.businessType }}</p>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="products && products[0]">
      <ion-col sizeXs="12" sizeMd="6" *ngFor="let item of products; let i = index">
        <ion-card color="light" class="animate__animated animate__fadeIn card-product">
          <ion-toolbar color="secondary">
            <h5 style="margin-left: 10px;" class="ion-text-center">{{ item?.title }}</h5>
          </ion-toolbar>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto" style="padding: 0px;" class="ion-text-center">
              <img *ngIf="item?.image" [src]="item?.image" class="img-product" />
              <img *ngIf="!item?.image" src="../../../../assets/img/no-product.png" class="img-product" />
            </ion-col>
            <ion-col size="" style="padding: 0px;">
              <div class="read-content">
                <p *ngIf="item?.producto_sustancia_activa_1 || item?.producto_sustancia_activa_2">
                  <b>Sustancias activas: </b>
                  <span *ngIf="item?.producto_sustancia_activa_1 as active">{{ active }}</span>
                  <span *ngIf="item?.producto_sustancia_activa_2 as active">, {{ active }}</span>
                </p>
                <p *ngIf="item?.linea_terapeutica_1 || item?.linea_terapeutica_2">
                  <b>Línea: </b>
                  <span *ngIf="item?.linea_terapeutica_1">{{ item?.linea_terapeutica_1 }}</span>
                  <span *ngIf="item?.linea_terapeutica_2">, {{ item?.linea_terapeutica_2 }}</span>
                </p>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <p>
                <strong>Muestra médica: </strong>
                <ion-text [color]="item?.allowMedicalSample === true ? 'success' : 'danger'">
                  {{ item?.allowMedicalSample === true ? 'Activa' : 'No activa' }}
                </ion-text>
              </p>
              <ion-button
                (click)="allowMedicalSample(false, item?.id)"
                expand="block"
                color="medium"
                *ngIf="item?.allowMedicalSample === true; else notAllowedMedicalSample"
                [disabled]="isLoading === true"
              >
                <ion-icon slot="start" name="close-circle"></ion-icon>
                Desactivar muestra médica
              </ion-button>
              <ng-template #notAllowedMedicalSample>
                <ion-button
                  (click)="allowMedicalSample(true, item?.id)"
                  expand="block"
                  color="tertiary"
                  [disabled]="isLoading === true"
                >
                  <ion-icon slot="start" name="checkmark-circle"></ion-icon>
                  Activar muestra médica
                </ion-button>
              </ng-template>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!(products && products[0]) && company">
      <ion-col size="12">
        <div class="ion-text-center">
          <strong>No se encontraron productos</strong>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar color="primary">
    <ion-title> </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="medicalSample(false)" [disabled]="isLoading === true">
        <ion-icon slot="end" name="eye-off"></ion-icon>
        Desactivar todas las muestras médicas
      </ion-button>
      <ion-button (click)="medicalSample(true)" [disabled]="isLoading === true">
        <ion-icon slot="end" name="eye"></ion-icon>
        Activar todas las muestras médicas
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
