<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span>Listado de Pre-registros</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item *ngFor="let registro of listado" lines="full">
            <ion-label>
              <h2>{{ registro?.nombre }} {{ registro?.apaterno }} {{ registro?.amaterno }}</h2>
              <p>{{ registro?.email }}</p>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button color="danger" *ngIf="registro?.inWP === false" (click)="presentAlertConfirm(registro.id)">
                <ion-icon slot="icon-only" name="trash"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
