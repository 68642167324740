<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center">
      Selecciona a los contactos
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <form (ngSubmit)="onSubmit()" [formGroup]="myForm" novalidate>
          <ion-list>
            <ion-item lines="inset">
              <ion-label color="medium" position="stacked" for="eventName"
                >Nombre del evento <ion-text color="danger">*</ion-text></ion-label
              >
              <ion-input type="text" placeholder="Nombre del evento" formControlName="eventName"></ion-input>
            </ion-item>
            <ion-text color="danger" [hidden]="myForm.controls.eventName.valid || myForm.controls.eventName.untouched">
              <span class="ion-padding" translate>Campo requerido</span>
            </ion-text>
            <ion-item lines="inset">
              <ion-label color="medium" position="stacked" for="eventNameContinuation"
                >Nombre del evento (Segundo renglón)</ion-label
              >
              <ion-input
                type="text"
                placeholder="Nombre del evento (Segundo renglón)"
                formControlName="eventNameContinuation"
              ></ion-input>
            </ion-item>
            <ion-item lines="inset">
              <ion-label color="medium" position="stacked" for="eventDate"
                >Fecha del evento <ion-text color="danger">*</ion-text></ion-label
              >
              <ion-datetime
                displayFormat="DD-MM-YYYY"
                placeholder="Fecha del evento"
                formControlName="eventDate"
              ></ion-datetime>
            </ion-item>
            <ion-text color="danger" [hidden]="myForm.controls.eventDate.valid || myForm.controls.eventDate.untouched">
              <span class="ion-padding" translate>Campo requerido</span>
            </ion-text>
            <ion-item lines="inset">
              <ion-label color="medium" position="stacked" for="eventDuration"
                >Duración del evento <ion-text color="danger">*</ion-text></ion-label
              >
              <ion-input type="number" placeholder="Duración del evento" formControlName="eventDuration"></ion-input>
            </ion-item>
            <ion-text
              color="danger"
              [hidden]="myForm.controls.eventDuration.valid || myForm.controls.eventDuration.untouched"
            >
              <span class="ion-padding" translate>Campo requerido</span>
            </ion-text>
            <ion-item lines="inset">
              <ion-label color="medium" position="stacked" for="eventPlatform"
                >Plataforma del evento <ion-text color="danger">*</ion-text></ion-label
              >
              <ion-input type="text" placeholder="Plataforma del evento" formControlName="eventPlatform"></ion-input>
            </ion-item>
            <ion-text
              color="danger"
              [hidden]="myForm.controls.eventPlatform.valid || myForm.controls.eventPlatform.untouched"
            >
              <span class="ion-padding" translate>Campo requerido</span>
            </ion-text>
            <ion-item lines="inset">
              <ion-label color="medium" position="stacked" for="eventCity"
                >Ciudad del evento <ion-text color="danger">*</ion-text></ion-label
              >
              <ion-input type="text" placeholder="Ciudad del evento" formControlName="eventCity"></ion-input>
            </ion-item>
            <ion-text color="danger" [hidden]="myForm.controls.eventCity.valid || myForm.controls.eventCity.untouched">
              <span class="ion-padding" translate>Campo requerido</span>
            </ion-text>
            <ion-item lines="inset">
              <ion-label color="medium" position="stacked" for="dateOfSendingCertificates"
                >Fecha de envio del evento <ion-text color="danger">*</ion-text></ion-label
              >
              <ion-datetime
                displayFormat="DD-MM-YYYY"
                placeholder="Fecha de envio del evento"
                formControlName="dateOfSendingCertificates"
              ></ion-datetime>
            </ion-item>
            <ion-text
              color="danger"
              [hidden]="
                myForm.controls.dateOfSendingCertificates.valid || myForm.controls.dateOfSendingCertificates.untouched
              "
            >
              <span class="ion-padding" translate>Campo requerido</span>
            </ion-text>
            <ion-item>
              <ion-label color="medium" position="stacked"
                >Lista de usuarios <ion-text color="danger">*</ion-text></ion-label
              >
              <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                (change)="selectFile($event)"
              />
            </ion-item>
          </ion-list>
          <div class="ion-padding-horizontal">
            <ion-button
              type="submit"
              [disabled]="myForm.invalid || isLoading === true || !file"
              color="tertiary"
              expand="block"
              translate
            >
              Continuar
            </ion-button>
          </div>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<!-- <ion-footer>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <input type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          (change)="selectFile($event)" />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <ion-button (click)="downloadFormat()" expand="block" color="danger" shape="outline" [disabled]="isLoading">
          <ion-icon slot="start" name="download"></ion-icon>
          Descargar formato
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button (click)="doUpload()" expand="block" color="secondary">
          <ion-icon slot="start" name="add"></ion-icon>
          Seleccionados
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer> -->
