import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Logger } from '@app/core';
const log = new Logger('Preregistro');

@Component({
  selector: 'app-preregistro',
  templateUrl: './preregistro.component.html',
  styleUrls: ['./preregistro.component.scss']
})
export class PreregistroComponent implements OnInit {
  listado: any;

  constructor(
    private firebaseSvc: FirebaseService,
    private alertController: AlertController,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {
    this.initPregregistros();
  }

  async initPregregistros() {
    this.firebaseSvc.getPreregistros().subscribe(async res => {
      let perregistro = res.map(elem => {
        const obj: any = elem.payload.doc.data();
        obj.id = elem.payload.doc.id;
        return obj;
      });
      this.listado = perregistro;
    });
  }

  async delete(id: string) {
    const loading = await this.loadingController.create({});
    await loading.present();
    try {
      await this.firebaseSvc.deletePreregistro(id);
    } catch (e) {
      log.error(e);
    }
    loading.dismiss();
  }

  async presentAlertConfirm(id: string) {
    const alert = await this.alertController.create({
      header: 'Eliminar pre-registro',
      message: '¿Está seguro de eliminar este pre-registro?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Eliminar',
          handler: () => {
            this.delete(id);
          }
        }
      ]
    });

    await alert.present();
  }
}
