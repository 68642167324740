import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextToLinkService {
  constructor() {}

  public replace(text: string): string {
    var Rexp = new RegExp(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/g
    );
    return text.replace(Rexp, "<a href='$1' target='_blank'>$1</a>");
  }
}
