import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { WpService } from '@app/shared/services/wp.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { CONTENT_EMAIL, SALES_EMAIL, CONTACT_EMAIL, environment } from '../../../environments/environment';

import { Logger } from '@app/core';
import { FirebaseService } from './firebase.service';
const log = new Logger('Login');

export interface EmailPreferences {
  recipient: string;
  subject?: string;
  text?: string;
  name?: string;
  cc?: Array<any> | null;
  bcc?: Array<string> | null;
  params?: any;
  templateId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(
    private credentials: CredentialsService,
    private wp: WpService,
    private uytilities: UtilitiesService,
    private firebase: FirebaseService
  ) {}

  async email(parameters: EmailPreferences) {
    try {
      if (!environment.production && !parameters.templateId) {
        parameters.text += '<h1><b>NOTA: ESTE CORREO ES GENERADO DESDE EL AMBIENTE DE PRUEBAS.</b></h1>';
      }

      if (environment.sendEmails) {
        await this.firebase.callFunctionFB('sendMail', {
          recipient: parameters.recipient,
          subject: parameters.subject,
          text: parameters.text,
          name: parameters.name,
          cc: parameters.cc,
          bcc: parameters.bcc,
          templateId: parameters.templateId,
          params: parameters.params
        });
      }
    } catch (err) {
      log.error(err);
    }
  }

  async requestCatalog() {
    try {
      const response = await this.wp.memberMe(this.credentials.credentials.token);
      const phoneData1 = await this.wp.getBpXprofileField(
        36,
        this.credentials.credentials.uid,
        this.credentials.credentials.token
      );
      const phoneData2 = await this.wp.getBpXprofileField(
        70,
        this.credentials.credentials.uid,
        this.credentials.credentials.token
      );
      const phone1 =
        phoneData1 &&
        phoneData1.body &&
        phoneData1.body[0] &&
        phoneData1.body[0].value &&
        phoneData1.body[0].value.unserialized &&
        phoneData1.body[0].value.unserialized[0]
          ? phoneData1.body[0].value.unserialized[0]
          : '';
      const phone2 =
        phoneData2 &&
        phoneData2.body &&
        phoneData2.body[0] &&
        phoneData2.body[0].value &&
        phoneData2.body[0].value.unserialized &&
        phoneData2.body[0].value.unserialized[0]
          ? phoneData2.body[0].value.unserialized[0]
          : '';
      const userData = this.uytilities.mapMemberData(response.body);
      const type: string = userData === 'representante-medico' ? 'Representante médico' : 'Médico';
      const points = await this.wp.getMemberPoints();
      const text1 = `
      <h1>El ${type} ${userData.name} solicita catálogo de productos.</h1>
      <h2><strong>Datos de contacto:</strong></h2>
      <p><strong>Puntos:</strong>  ${points}</p>
      <p><strong>Nombre:</strong>  ${userData.name}</p>
      <p><strong>Email:</strong>  ${this.credentials.credentials.email}</p>
      <p><strong>Teléfono:</strong>  ${phone1}</p>
      <p><strong>Teléfono (Secundario):</strong>  ${phone2}</p>`;
      const text2 = `
      <h1>¡Hola ${userData.name}!</h1>
      <p>Su información se ha enviado a <a href="mailto:contacto@conectimed.com">contacto@conectimed.com</a>. En breve se le hará llegar el catálogo de los productos de los que puede disponer con sus puntos <strong>CONECTIMED</strong>.</p>`;
      //Para la plataforma
      await this.wp.email(CONTACT_EMAIL, 'Solicitud de catálogo de productos', text1);
      //Para el miembro
      await this.wp.email(this.credentials.credentials.email, 'Solicitud de catálogo de productos', text2);
    } catch (error) {
      log.error(error);
    }
  }

  async contactMe() {
    try {
      const response = await this.wp.memberMe(this.credentials.credentials.token);
      const phoneData1 = await this.wp.getBpXprofileField(
        36,
        this.credentials.credentials.uid,
        this.credentials.credentials.token
      );
      const phoneData2 = await this.wp.getBpXprofileField(
        70,
        this.credentials.credentials.uid,
        this.credentials.credentials.token
      );
      const phone1 =
        phoneData1 &&
        phoneData1.body &&
        phoneData1.body[0] &&
        phoneData1.body[0].value &&
        phoneData1.body[0].value.unserialized &&
        phoneData1.body[0].value.unserialized[0]
          ? phoneData1.body[0].value.unserialized[0]
          : '';
      const phone2 =
        phoneData2 &&
        phoneData2.body &&
        phoneData2.body[0] &&
        phoneData2.body[0].value &&
        phoneData2.body[0].value.unserialized &&
        phoneData2.body[0].value.unserialized[0]
          ? phoneData2.body[0].value.unserialized[0]
          : '';
      const userData = this.uytilities.mapMemberData(response.body);
      const type: string = userData === 'representante-medico' ? 'Representante médico' : 'Médico';
      const text1 = `
      <h1>El ${type} ${userData.name} desea contactarse.</h1>
      <h2><strong>Datos de contacto:</strong></h2>
      <p><strong>Nombre:</strong>  ${userData.name}</p>
      <p><strong>Email:</strong>  ${this.credentials.credentials.email}</p>
      <p><strong>Teléfono:</strong>  ${phone1}</p>
      <p><strong>Teléfono (Secundario):</strong>  ${phone2}</p>`;
      const text2 = `
      <h1>¡Hola ${userData.name}!</h1>
      <p>Gracias por entrar en contacto con nosotros.</p>
      <h2><strong>Datos de contacto:</strong></h2>
      <p><strong>Nombre:</strong>  ${userData.name}</p>
      <p><strong>Email:</strong>  ${this.credentials.credentials.email}</p>
      <p><strong>Teléfono:</strong>  ${phone1}</p>
      <p><strong>Teléfono (Secundario):</strong>  ${phone2}</p>
      <p>A la brevedad un integrante de nuestro equipo se comunicará contigo.</p>`;
      //Para la plataforma
      await this.wp.email(SALES_EMAIL, 'Solitud de contacto', text1);
      //Para el miembro
      await this.wp.email(this.credentials.credentials.email, 'Solitud de contacto', text2);
    } catch (error) {
      log.error(error);
    }
  }

  async pendingNote(data: any) {
    try {
      const response = await this.wp.memberMe(this.credentials.credentials.token);
      const userData = this.uytilities.mapMemberData(response.body);
      const text1 = `
      <h1>El médico ${userData.name} desea publicar una nota.</h1>
      <p><strong>Nombre:</strong>  ${userData.name}</p>
      <hr>
      <h1>Nota</h1>
      <p><strong>Id:</strong>  ${data.postId}</p>
      <p><strong>Título:</strong>  ${data.title}</p>
      <p><strong>Extracto:</strong>  ${data.excerpt}</p>
      <p><strong>Artículo:</strong> <a href="${data.article}" target="_blank">${data.article}</a></p>`;
      const text2 = `
      <h1>¡Hola ${userData.name}!</h1>
      <p>Gracias por enviarnos tu artículo, por el momento tu publicación esta pendiente de revisión, te avisaremos cuando esté público.</p>
      <hr>
      <h1>Nota</h1>
      <p><strong>Id:</strong>  ${data.postId}</p>
      <p><strong>Título:</strong>  ${data.title}</p>
      <p><strong>Extracto:</strong>  ${data.excerpt}</p>`;
      //Para la plataforma
      await this.wp.email(CONTENT_EMAIL, 'Publicación de nota', text1);
      //Para el miembro
      await this.wp.email(this.credentials.credentials.email, 'Publicación de nota', text2);
    } catch (error) {
      log.error(error);
    }
  }

  async sampleRequest(message: string) {
    try {
      const response = await this.wp.memberMe(this.credentials.credentials.token);
      const userData = this.uytilities.mapMemberData(response.body);
      const text1 = `
      <h1>El médico ${userData.name} desea una muestra.</h1>
      <hr>
      ${message}`;
      const text2 = `
      <h1>¡Hola ${userData.name}!</h1>
      <p>Tu solicitud de muestra ha sido enviada, en breve te enviaremos la confirmación de esta solicitud.</p>
      <hr>${message}`;
      //Para la plataforma
      await this.wp.email(CONTACT_EMAIL, 'Solicitud de muestra', text1);
      //Para el miembro
      await this.wp.email(this.credentials.credentials.email, 'Solicitud de muestra', text2);
    } catch (error) {
      log.error(error);
    }
  }

  async userUpdatedTheirProfile() {
    try {
      const response = await this.wp.memberMe(this.credentials.credentials.token);
      const userData = this.uytilities.mapMemberData(response.body);
      const text1 = `
      <h2>Actualizaci&oacute;n de perfil</h2>
      <hr />
      <p>El m&eacute;dico ${userData.name} ha actualizado su perfil.</p>
      <p><strong>ID:</strong>&nbsp;${this.credentials.credentials.uid}</p>
      <p><strong>Email:</strong>&nbsp;${this.credentials.credentials.email}</p>`;
      //Para la plataforma
      await this.wp.email(CONTACT_EMAIL, `Actualización de perfil (${this.credentials.credentials.email})`, text1);
    } catch (error) {
      log.error(error);
    }
  }

  async registerAndWelcome() {
    try {
      const response = await this.wp.memberMe(this.credentials.credentials.token);
      const userData = this.uytilities.mapMemberData(response.body);
      let text: string = '';
      if (this.credentials.credentials.type === 'medico') {
        text = `
        <h1>¡Bienvenido ${userData.name}!</h1>
        <hr>
        <p>Has realizado tu registro a la plataforma <strong>CONECTIMED</strong>.</p>      
        <p>Para aprovechar todos los beneficios que <strong>CONECTIMED</strong> te ofrece te invitamos a ver las siguientes video guías.</p>      
        <ul>
        <li><a href="https://youtu.be/VoxbcZcy12E" target="_blank">1.- ¿Qué es CONECTI<strong>MED</strong> para médicos?</li>
        <li><a href="https://youtu.be/RK0CC6YPEuM" target="_blank">2.- ¿Qué es y que puedo encontrar en el apartado Multimedia de CONECTI<strong>MED</strong>?</li>
        <li><a href="https://youtu.be/Fdwu4bvSXjw" target="_blank">3.- ¿Cómo puedo publicar trabajos o artículos en la sección Multimedia?</li>
        <li><a href="https://youtu.be/BXXyWojdiIA" target="_blank">4.- ¿Cómo buscar representantes de la industria farmacéutica y cuál es la finalidad?</li>
        <li><a href="https://youtu.be/EStdBSfWgg4" target="_blank">5.- ¿Cómo administrar mis contactos con representantes de la industria farmacéutica?</li>
        <li><a href="https://youtu.be/FQiw7JU1VXk" target="_blank">6.- ¿Cómo puedo agendar un evento con un representante de la industria farmacéutica?</li>
        <li><a href="https://youtu.be/hpc4ldG6hNY" target="_blank">7.- ¿Cómo puedo enviar un mensaje rápidamente a un contacto activo?</li>
        <li><a href="https://youtu.be/sheAWpXSB9Y" target="_blank">8.- ¿Cómo puedo solicitar muestra médica de algún producto?</li>
        <li><a href="https://youtu.be/rUqDh97rg6U" target="_blank">9.- ¿Qué es el programa de puntos CONECTI<strong>MED</strong>?</li>
        <li><a href="https://youtu.be/TMAeeOTI_xQ" target="_blank">10.- ¿Cómo puedo revisar mi saldo de puntos CONECTI<strong>MED</strong> y en qué los puedo utilizar?</li>
        </ul>
        <p>En <strong>CONECTIMED</strong> queremos servirte de la mejor manera por ello ante cualquier duda o comentario escríbenos a <a href="mailto:contacto@conectimed.com">contacto@conectimed.com</a>.</p>`;
      } else {
        text = `
        <h1>¡Bienvenido ${userData.name}!</h1>
        <hr>
        <p>Has realizado tu registro a la plataforma <strong>CONECTIMED</strong>.</p>      
        <p>Para aprovechar todos los beneficios que <strong>CONECTIMED</strong> te ofrece te invitamos a ver las siguientes video guías.</p>      
        <ul>
        <li><a href="https://youtu.be/OKkmlMWVqpM" target="_blank">1.- ¿Qué es CONECTI<strong>MED</strong> para representantes?</li>
        <li><a href="https://youtu.be/0b9n5KgrAxg" target="_blank">2.- ¿Qué es y para qué sirve el área Multimedia?</li>
        <li><a href="https://youtu.be/eLvkBLm5_5U" target="_blank">3.- ¿Para qué y cómo debo dar de alta mis productos?</li>
        <li><a href="https://youtu.be/xMFKQpdv8WY" target="_blank">4.- ¿Cómo cargo saldo para empezar a utilizar CONECTI<strong>MED</strong>?</li>
        <li><a href="https://youtu.be/dgKLpU8DM2I" target="_blank">5.- ¿Cómo puedo buscar médicos para contactar por CONECTI<strong>MED</strong>?</li>
        <li><a href="https://youtu.be/rJ7IvhuLyTA" target="_blank">6.- ¿Cómo administrar mis contactos con profesionales de la salud?</li>
        <li><a href="https://youtu.be/BfqvogboQJk" target="_blank">7.- ¿Cómo puedo hacer comunidades de médicos con intereses comunes?</li>
        <li><a href="https://youtu.be/SoI4YnkvljQ" target="_blank">8.- ¿Cómo puedo agendar un evento con un médico?</li>
        <li><a href="https://youtu.be/K2TlSGXAJLg" target="_blank">9.- ¿Cómo puedo enviar un mensaje rápidamente a un contacto activo?</li>
        <li><a href="https://youtu.be/VyXlS1M0rJI" target="_blank">10.- ¿Cómo puedo conocer la actividad que he tenido en CONECTI<strong>MED</strong>?</li>
        <li><a href="https://youtu.be/xrv7AimTI9E" target="_blank">11.- ¿Cómo ingresar mis datos fiscales y solicitar una factura?</li>
        </ul>
        <p>En <strong>CONECTIMED</strong> queremos servirte de la mejor manera por ello ante cualquier duda o comentario escríbenos a <a href="mailto:contacto@conectimed.com">contacto@conectimed.com</a>.</p>`;
      }
      await this.wp.email(this.credentials.credentials.email, '¡Gracias por registrarte!', text);
    } catch (error) {
      log.error(error);
    }
  }

  async userRegistrationWelcome(email: string, name: string, token: string) {
    let text: string = `
    <!doctype html>
    <html lang="en">
      <head>
          <meta charset="utf-8">
          <title>¡Bienvenido a CONECTIMED!</title>
          <meta name="description" content="CONECTIMED le da la más cordial bienvenida.">
          <meta name="author" content="CONECTIMED">
      </head>

      <body>
          <div style="font-family: Arial, Helvetica, sans-serif; text-align: center;">
              <table border="0" cellspacing="1" cellpadding="10" align="center">
                  <tbody>
                      <tr>
                          <td>
                              <img src="img.png" alt="">
                              <img src="img2.png" alt="">
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <p>Estimado (a) Dr. ${name}</p>
                              <p>
                                  <br>
                              </p>
                              <p>CONECTI<strong>MED</strong>&nbsp;le da la m&aacute;s cordial bienvenida.</p>
                              <p>
                                  <br>
                              </p>
                              <p>Como miembro de la comunidad m&eacute;dica de CONECTI<strong>MED</strong>&nbsp;usted ya tiene
                                  acceso
                                  a toda la informaci&oacute;n confiable, actualizada y de gran utilidad para su
                                  pr&aacute;ctica
                                  m&eacute;dica.</p>
                              <p>
                                  <br>
                              </p>

                          </td>
                      </tr>
                      <tr>
                          <td>
                              <table border="0" cellspacing="0" cellpadding="0" width="100%" style="text-align: center;">
                                  <tbody>
                                      <tr>
                                          <td width="100%" colspan="2">
                                              <p>Para ingresar a la plataforma, utiliza el siguiente email:
                                              </p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td width="100%">
                                              <p>USUARIO:&nbsp;<a href="mailto:${email}">${email}</a></p>
                                          </td>
                                         <!--
                                         <td width="50%">
                                            
                                         </td>
                                         -->
                                         
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <p>Lo invitamos a conocer los usos y beneficios de la plataforma
                                  CONECTI<strong>MED</strong>&nbsp;y
                                  esperamos que su experiencia sea agradable.</p>
                              <p>
                                  <br>
                              </p>
                              <p>Para cualquier duda o comentario escribanos a</p>
                              <p><a href="mailto:contacto@conectimed.com"
                                      data-cke-saved-href="mailto:contacto@conectimed.com">contacto@conectimed.com</a>&nbsp;donde
                                  con
                                  gusto le atenderemos.</p>
                              <p>
                                  <br>
                              </p>
                              <p>En CONECTI<strong>MED</strong>&nbsp;trabajamos todos los d&iacute;as buscando la
                                  satisfacci&oacute;n
                                  de nuestros usuarios.</p>
                              <p>
                                  <br>
                              </p>
                              <p>Para conocer m&aacute;s acerca de nuestra plataforma, lo invitamos a visitar las siguientes
                                  videogu&iacute;as:</p>
                              <p>
                                  <br>
                              </p>
                              <p>
                                  <a href="https://youtu.be/VoxbcZcy12E">
                                      &iquest;Qu&eacute; es CONECTI<strong>MED</strong>?
                                  </a>
                              </p>
                              <p>
                                  <a href="https://youtu.be/RK0CC6YPEuM">
                                      &iquest;Qu&eacute; es multimedia para m&eacute;dicos?
                                  </a>
                              </p>
                              <p>
                                  <a href="https://youtu.be/TMAeeOTI_xQ">
                                      &iquest;Que son los puntos CONECTI<strong>MED?</strong>
                                  </a>
                              </p>
                              <p>
                                  <br><br>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <table border="0" cellspacing="0" cellpadding="0" width="100%" style="text-align: center;">
                                  <tbody>
                                      <tr>
                                          <td width="50%">
                                              <p>Firma</p>
                                              <p><em>Lic. Pablo Gorozpe</em></p>
                                              <p>Director General CONECTI<strong>MED</strong></p>
                                          </td>
                                          <td width="50%">
                                              <p>Firma</p>
                                              <p><em>Dr. Pablo Svarch</em></p>
                                              <p>Director M&eacute;dico CONECTI<strong>MED</strong></p>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </body>
    </html>`;
    await this.wp.email(email, '¡Bienvenido a CONECTIMED!', text, token);
  }

  public async sendEmailVerification({
    email,
    link,
    name = 'Rey',
    appName = 'Conectimed'
  }: {
    email: string;
    link: string;
    name?: string;
    appName?: string;
  }) {
    try {
      let text: string = `
        <p>Visita este vínculo para verificar tu dirección de correo electrónico.</p>
        <p><a href="${link}" style="display: inline-block;
        background-color: #08b078;
        color: white;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #009688;
        font-weight: bold;">Verificar mi cuenta</a></p>
        <p>Si no solicitaste la verificación de esta dirección, ignora este correo electrónico.</p>
        <p>Gracias.</p>
        <p>El equipo de ${appName}</p>`;
      return await this.email({
        recipient: email,
        subject: `Verifica el correo electrónico para ${appName}`,
        text: text,
        name: name
      });
    } catch (error) {
      log.error(error);
    }
  }

  public async sendResetPassword({
    email,
    link,
    name = 'Rey',
    appName = 'Conectimed'
  }: {
    email: string;
    link: string;
    name?: string;
    appName?: string;
  }) {
    try {
      let text: string = `
        <table width="100%" border="0" cellpadding="0" cellspacing="0" class="rnb-columns-container">
          <tbody>
              <tr>
                  <td>
                      <table width="100%" border="0" cellpadding="0" cellspacing="0" class="rnb-columns-container">
                          <tbody>
                              <tr>
                                  <th class="rnb-force-col" style="text-align: left; font-weight: normal; padding-right: 0px;"
                                      valign="top">
      
                                      <table border="0" valign="top" cellspacing="0" cellpadding="0" width="100%" align="left"
                                          class="rnb-col-1">
      
                                          <tbody>
                                              <tr>
                                                  <td
                                                      style="font-size:14px; font-family:Arial,Helvetica,sans-serif, sans-serif; color:#3c4858;">
                                                      <div>
                                                          <div style="text-align: center;">
                                                              <strong>RECUPERACION
                                                                  DE
                                                                  CONTRASEÑA</strong>
                                                          </div>
      
                                                          <div>&nbsp;
                                                          </div>      
                                                          <div><br>
                                                              Si
                                                              olvidó
                                                              su
                                                              contraseña
                                                              al dar
                                                              click en
                                                              siguiente
                                                              botón
                                                              podrá
                                                              realizar
                                                              el
                                                              cambio
                                                              de la
                                                              misma:<span
                                                                  style="background-color: transparent; text-align: center;">&nbsp;</span>
                                                          </div>
                                                      </div>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
      
                                  </th>
                              </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table border="0" valign="top" cellspacing="0" cellpadding="0" width="550" align="center"
                          class="rnb-col-1">
      
                          <tbody>
                              <tr>
                                  <td valign="top">
                                      <table cellpadding="0" border="0" align="center" cellspacing="0"
                                          class="rnb-btn-col-content" style="margin:auto; border-collapse: separate;">
                                          <tbody>
                                              <tr>
                                                  <td width="auto" valign="middle" bgcolor="#509e10" align="center"
                                                      height="40"
                                                      style="font-size:18px; font-family:Arial,Helvetica,sans-serif; color:#ffffff; font-weight:normal; padding-left:20px; padding-right:20px; vertical-align: middle; background-color:#509e10;border-radius:4px;border-top:0px None #000;border-right:0px None #000;border-bottom:0px None #000;border-left:0px None #000;">
                                                      <span style="color:#ffffff; font-weight:normal;">
                                                          <a style="text-decoration:none; color:#ffffff; font-weight:normal;"
                                                              target="_blank" href="${link}">Dé
                                                              click
                                                              aquí</a>
                                                      </span>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table border="0" valign="top" cellspacing="0" cellpadding="0" width="100%" align="left"
                          class="rnb-col-1">
      
                          <tbody>
                              <tr>
                                  <td
                                      style="font-size:14px; font-family:Arial,Helvetica,sans-serif, sans-serif; color:#3c4858;">
                                      <div style="text-align: center;">
                                          <span style="background-color: transparent;">Le
                                              recomendamos
                                              seleccionar
                                              una
                                              combinación
                                              de
                                              letras,
                                              números
                                              y algún
                                              carácter
                                              especial
                                              para
                                              procurar
                                              un nivel
                                              de
                                              seguridad
                                              óptimo.</span>
                                      </div>
      
                                      <div>&nbsp;
                                      </div>
      
                                      <div>Si no
                                          solicitó el
                                          restablecimiento
                                          de su
                                          contraseña
                                          haga caso
                                          omiso de
                                          este
                                          mensaje.
                                      </div>
      
                                      <div>&nbsp;
                                      </div>
      
                                      <div style="text-align: center;">
                                          Para
                                          cualquier
                                          duda o
                                          comentario
                                          escríbanos a
                                      </div>
      
                                      <div style="text-align: center;">
                                          &nbsp;</div>
      
                                      <div style="text-align: center;">
                                          <a href="mailto:contacto@conectimed.com"
                                              style="text-decoration: underline; color: rgb(0, 146, 255);">contacto@conectimed.com</a>
                                      </div>
      
                                      <div style="text-align: center;">
                                          &nbsp;</div>
      
                                      <div style="text-align: center;">
                                          donde con
                                          gusto le
                                          atenderemos.
                                      </div>
      
                                      <div style="text-align: center;">
                                          &nbsp;</div>
      
                                      <div><br>
                                          &nbsp;</div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
          </tbody>
      </table>`;
      return await this.email({
        recipient: email,
        subject: `Restablece tu contraseña de ${appName}`,
        text: text,
        name: name
      });
    } catch (error) {
      log.error(error);
    }
  }
}
