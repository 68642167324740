<ion-app>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="modalController.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Funciones en base de datos</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-button (click)="openConstants()" expand="block" fill="solid">
            Constantes
          </ion-button>
          <ion-button (click)="databaseAlert('updateSearch')" expand="block" fill="solid">
            Actualizar parametros de busqueda de productos
          </ion-button>
          <ion-button (click)="databaseAlert('updateAlphabeticalCounters')" expand="block" fill="solid">
            Actualizar contadores alfabéticos
          </ion-button>
          <ion-button (click)="databaseAlert('updateFilterCounters')" expand="block" fill="solid">
            Actualizar contadores de filtros
          </ion-button>
          <ion-button (click)="databaseAlert('updateFriendsObject')" expand="block" fill="solid">
            Actualizar amigos (List and counters)
          </ion-button>
          <ion-button (click)="databaseAlert('updateBroadcastList')" expand="block" fill="solid">
            Actualizar orden de usuarios en grupos
          </ion-button>
          <ion-button (click)="databaseAlert('updateUUIDUsers')" expand="block" fill="solid">
            Actualizar uuid de usuarios
          </ion-button>
          <ion-button (click)="databaseAlert('assignType')" expand="block" fill="solid">
            Asignar atributo type a productos que no lo tienen
          </ion-button>
          <!-- <ion-button (click)="databaseAlert('fakeLikes')" expand="block" fill="solid">
            Asignar Likes genericos a publicaciones de foro
          </ion-button> -->
          <ion-button (click)="databaseAlert('productMuestraToNumber')" expand="block" fill="solid">
            Convertir a numero el atributo: "Se cuenta con muestra"
          </ion-button>
          <!-- <ion-button (click)="databaseAlert('delteContacts')" expand="block" fill="solid">
            Borrar mis usuarios
          </ion-button> -->
          <!-- <ion-button (click)="databaseAlert('allEmails')" expand="block" fill="solid">
            Obtener todos los emails
          </ion-button> -->
          <ion-button (click)="databaseAlert('chatFields')" expand="block" fill="solid">
            Modificar objeto 'last_message' en chats
          </ion-button>
          <ion-button (click)="databaseAlert('liveChatsDoc')" expand="block" fill="solid">
            Modificar documento 'live-chats' en live chats
          </ion-button>
          <ion-button (click)="databaseAlert('allRepsToDefaultPlan')" expand="block" fill="solid">
            Asignar a todos los Representantes a Plan Default
          </ion-button>
          <ion-button (click)="databaseAlert('updateSearchV2')" expand="block" fill="solid">
            Actualizar parametros de busqueda de usuarios (V2)
          </ion-button>
          <ion-button (click)="databaseAlert('updatePostsSearch')" expand="block" fill="solid">
            Actualizar parametros de busqueda de posts
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ion-app>
