import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core/logger.service';
import { LoadingController } from '@ionic/angular';
const log = new Logger('PostsReviewComponent');
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-posts-review',
  templateUrl: './posts-review.component.html',
  styleUrls: ['./posts-review.component.scss'],
})
export class PostsReviewComponent implements OnInit, OnDestroy {

  public posts: any[] = [];
  public isLoading: boolean = false;
  collection: AngularFirestoreCollection<any>;
  subs: Subscription;
  onlyFailed: boolean = false;

  constructor(
    private afs: AngularFirestore,
    private loadingController: LoadingController
  ) { }

  viewChange(event: any) {
    this.onlyFailed = event.detail.checked;
    this.init();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    try {
      if (this.subs) {
        this.subs.unsubscribe();
        log.debug("unsubscribed...");
      }
    } catch (error) {
      log.error(error);
    }
  }

  async init() {
    try {
      this.collection = this.afs.collection('posts', ref => ref.orderBy('publication_date', 'desc').limit(200));
      this.subs = this.collection.snapshotChanges().subscribe(snap => {
        this.posts = snap.map(item => {
          let data: any = item.payload.doc.data();
          data.dateStr = moment(data.publication_date.toDate()).format('YYYY-MM-DD HH:mm');
          const id: string = item.payload.doc.id;
          return { ...data, id }
        });
        if (this.onlyFailed === true) {
          this.posts = this.posts.filter(item => {
            try {
              if (item.search.length > 0) {
                return false;
              } else {
                return true;
              }
            } catch (error) {
              log.error(error);
              return true;
            }
          });
        }
      });
    } catch (error) {
      log.error(error);
    }
  }

  async refreshSearch(id: string, title: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      await this.afs.doc(`posts/${id}`).update({ title: 'updating' });
      await this.afs.doc(`posts/${id}`).update({ title: title });
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  later(delay: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });
  }

}
