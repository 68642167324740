import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { Logger } from '@app/core';
import { from } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('AddPlanComponent');
import * as moment from 'moment';
import { ValidateUrl } from '@app/shared/validators/url.validator';

@Component({
  selector: 'app-add-survey',
  templateUrl: './add-survey.component.html',
  styleUrls: ['./add-survey.component.scss']
})
export class AddSurveyComponent implements OnInit {
  public id: string;
  public dataForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private afs: AngularFirestore,
    private utilities: UtilitiesService
  ) {
    this.init();
  }

  init() {
    this.initForm();
  }

  async ngOnInit() {
    if (this.id) {
      this.isLoading = true;
      const loadingOverlay = await this.loadingController.create({});
      from(loadingOverlay.present());
      try {
        const RESP = await this.afs
          .collection('surveys')
          .doc(this.id)
          .ref.get();
        const DATA: any = RESP.data();
        const TOPICS: string[] = DATA && DATA.topics ? Array.from(DATA.topics) : [];
        const INDEX1: number = TOPICS.indexOf('representante-medico');
        const INDEX2: number = TOPICS.indexOf('medico');
        let target: string = 'allDevices';
        if (INDEX1 > -1) {
          target = 'representante-medico';
        } else if (INDEX2 > -1) {
          target = 'medico';
        }

        if (DATA && DATA.buttons && DATA.buttons.cancel && DATA.buttons.cancel.show === true) {
          this.showButtonCancel({
            detail: {
              checked: true
            }
          });
        }

        if (DATA && DATA.buttons && DATA.buttons['remember-later'] && DATA.buttons['remember-later'].show === true) {
          this.showButtonRememberLater({
            detail: {
              checked: true
            }
          });
        }

        if (DATA && DATA.buttons && DATA.buttons.acept && DATA.buttons.acept.show === true) {
          this.showButtonAccept({
            detail: {
              checked: true
            }
          });
        }

        this.dataForm.setValue({
          title: DATA && DATA.title ? String(DATA.title) : '',
          text: DATA && DATA.text ? String(DATA.text) : '',
          url: DATA && DATA.url ? String(DATA.url) : '',
          target: target,
          show_button_cancel:
            DATA && DATA.buttons && DATA.buttons.cancel && DATA.buttons.cancel.show === true ? true : false,
          text_button_cancel:
            DATA && DATA.buttons && DATA.buttons.cancel && DATA.buttons.cancel.text
              ? String(DATA.buttons.cancel.text)
              : '',
          show_button_remember_later:
            DATA && DATA.buttons && DATA.buttons['remember-later'] && DATA.buttons['remember-later'].show === true
              ? true
              : false,
          text_button_remember_later:
            DATA && DATA.buttons && DATA.buttons['remember-later'] && DATA.buttons['remember-later'].text
              ? String(DATA.buttons['remember-later'].text)
              : '',
          show_button_accept:
            DATA && DATA.buttons && DATA.buttons.acept && DATA.buttons.acept.show === true ? true : false,
          text_button_accept:
            DATA && DATA.buttons && DATA.buttons.acept && DATA.buttons.acept.text ? String(DATA.buttons.acept.text) : ''
        });
        this.dataForm.updateValueAndValidity();
      } catch (e) {
        log.error(e);
      }
      loadingOverlay.dismiss();
      this.isLoading = false;
    }
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      title: ['', Validators.required],
      text: ['', Validators.required],
      url: ['', [Validators.required, ValidateUrl.isValid]],
      target: ['allDevices', Validators.required],
      show_button_cancel: [true, Validators.required],
      text_button_cancel: ['Cancelar', Validators.required],
      show_button_remember_later: [true, Validators.required],
      text_button_remember_later: ['Recordarme después', Validators.required],
      show_button_accept: [true, Validators.required],
      text_button_accept: ['Aceptar', Validators.required]
    });
  }

  public showButtonCancel(event: any) {
    if (event && event.detail && event.detail.checked === true) {
      this.dataForm.controls['text_button_cancel'].setValidators([Validators.required]);
      this.dataForm.controls['text_button_cancel'].updateValueAndValidity();
    } else {
      this.dataForm.controls['text_button_cancel'].setValue(null);
      this.dataForm.controls['text_button_cancel'].clearValidators();
      this.dataForm.controls['text_button_cancel'].updateValueAndValidity();
    }
  }

  public showButtonRememberLater(event: any) {
    if (event && event.detail && event.detail.checked === true) {
      this.dataForm.controls['text_button_remember_later'].setValidators([Validators.required]);
      this.dataForm.controls['text_button_remember_later'].updateValueAndValidity();
    } else {
      this.dataForm.controls['text_button_remember_later'].setValue(null);
      this.dataForm.controls['text_button_remember_later'].clearValidators();
      this.dataForm.controls['text_button_remember_later'].updateValueAndValidity();
    }
  }

  public showButtonAccept(event: any) {
    if (event && event.detail && event.detail.checked === true) {
      this.dataForm.controls['text_button_accept'].setValidators([Validators.required]);
      this.dataForm.controls['text_button_accept'].updateValueAndValidity();
    } else {
      this.dataForm.controls['text_button_accept'].setValue(null);
      this.dataForm.controls['text_button_accept'].clearValidators();
      this.dataForm.controls['text_button_accept'].updateValueAndValidity();
    }
  }

  public async save() {
    try {
      if (!this.id) {
        await this.add();
      } else {
        await this.update(this.id);
      }
    } catch (e) {
      log.error(e);
    }
  }

  public async add() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    from(loadingOverlay.present());
    try {
      let topics: string[] = ['allDevices'];
      if (this.dataForm && this.dataForm.value && this.dataForm.value.target) {
        topics = [String(this.dataForm.value.target)];
      }
      const data: any = {
        date: moment().toDate(),
        status: 'inactive',
        text: this.dataForm && this.dataForm.value && this.dataForm.value.text ? String(this.dataForm.value.text) : '',
        topics: topics,
        title:
          this.dataForm && this.dataForm.value && this.dataForm.value.title ? String(this.dataForm.value.title) : '',
        url: this.dataForm && this.dataForm.value && this.dataForm.value.url ? String(this.dataForm.value.url) : '',
        buttons: {
          'remember-later': {
            show:
              this.dataForm && this.dataForm.value && this.dataForm.value.show_button_remember_later
                ? Boolean(this.dataForm.value.show_button_remember_later)
                : false,
            text:
              this.dataForm && this.dataForm.value && this.dataForm.value.text_button_remember_later
                ? String(this.dataForm.value.text_button_remember_later)
                : ''
          },
          cancel: {
            show:
              this.dataForm && this.dataForm.value && this.dataForm.value.show_button_cancel
                ? Boolean(this.dataForm.value.show_button_cancel)
                : false,
            text:
              this.dataForm && this.dataForm.value && this.dataForm.value.text_button_cancel
                ? String(this.dataForm.value.text_button_cancel)
                : ''
          },
          acept: {
            show:
              this.dataForm && this.dataForm.value && this.dataForm.value.show_button_accept
                ? Boolean(this.dataForm.value.show_button_accept)
                : false,
            text:
              this.dataForm && this.dataForm.value && this.dataForm.value.text_button_accept
                ? String(this.dataForm.value.text_button_accept)
                : ''
          }
        }
      };
      await this.afs.collection('surveys').add(data);
      this.closeModal('db');
      this.utilities.toast('¡Alerta agregada correctamente!');
    } catch (e) {
      log.error(e);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  public async update(id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    from(loadingOverlay.present());
    try {
      let topics: string[] = ['allDevices'];
      if (this.dataForm && this.dataForm.value && this.dataForm.value.target) {
        topics = [String(this.dataForm.value.target)];
      }
      const data: any = {
        status: 'inactive',
        text: this.dataForm && this.dataForm.value && this.dataForm.value.text ? String(this.dataForm.value.text) : '',
        topics: topics,
        title:
          this.dataForm && this.dataForm.value && this.dataForm.value.title ? String(this.dataForm.value.title) : '',
        url: this.dataForm && this.dataForm.value && this.dataForm.value.url ? String(this.dataForm.value.url) : '',
        buttons: {
          'remember-later': {
            show:
              this.dataForm && this.dataForm.value && this.dataForm.value.show_button_remember_later
                ? Boolean(this.dataForm.value.show_button_remember_later)
                : false,
            text:
              this.dataForm && this.dataForm.value && this.dataForm.value.text_button_remember_later
                ? String(this.dataForm.value.text_button_remember_later)
                : ''
          },
          cancel: {
            show:
              this.dataForm && this.dataForm.value && this.dataForm.value.show_button_cancel
                ? Boolean(this.dataForm.value.show_button_cancel)
                : false,
            text:
              this.dataForm && this.dataForm.value && this.dataForm.value.text_button_cancel
                ? String(this.dataForm.value.text_button_cancel)
                : ''
          },
          acept: {
            show:
              this.dataForm && this.dataForm.value && this.dataForm.value.show_button_accept
                ? Boolean(this.dataForm.value.show_button_accept)
                : false,
            text:
              this.dataForm && this.dataForm.value && this.dataForm.value.text_button_accept
                ? String(this.dataForm.value.text_button_accept)
                : ''
          }
        }
      };
      await this.afs
        .collection('surveys')
        .doc(id)
        .update(data);
      this.closeModal('db');
      this.utilities.toast('¡Alerta actualizada correctamente!');
    } catch (e) {
      log.error(e);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  public closeModal(role?: string) {
    this.modalCtrl.dismiss({ modification: false }, role);
  }
}
