import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss']
})
export class SearchProductComponent implements OnInit {
  @Input() callback: any;
  @Input() product: any;

  search: string = null;

  products: any[] = [];

  constructor(public modalC: ModalController, private afs: AngularFirestore) { }

  ngOnInit() {
    if (!this.product) {
      this.product = {
        linea_terapeutica_2: 'linea 2',
        linea_terapeutica_1: 'linea 1',
        marca: 'Nombre del producto'
      };
    }
  }

  async buscar() {
    this.products = [];
    const prod = await this.afs
      .collection('products')
      .ref.where('status', '==', 'active')
      .where('search', 'array-contains', String(this.search).trim())
      .get();
    for (const doc of prod.docs) {
      this.products.push({ ...doc.data() as any, id: doc.id });
    }
  }

  generateData() {
    if (this.product.marca !== 'Nombre del producto') {
      const data = {
        repreId: this.product.uid,
        productId: this.product.id,
        image: this.product.image,
        repre: this.product
      };
      this.callback(data);
      this.modalC.dismiss();
    }
  }
}
