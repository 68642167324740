import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ActionSheetController, AlertController, Platform, ModalController } from '@ionic/angular';
import { ActionSheetButton, ActionSheetOptions } from '@ionic/core';
import { AuthenticationService, CredentialsService } from '@app/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { DatabaseModalComponent } from '@app/shared/components/database-modal/database-modal.component';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Logger } from '@app/core/logger.service';
const log = new Logger('AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public routes: any[] = [];
  public showButtons: boolean = false;
  appVersion :string = environment.version;

  constructor(
    private router: Router,
    private platform: Platform,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController,
    private authenticationService: AuthenticationService,
    public credentialsService: CredentialsService,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private credentials: CredentialsService
  ) {
    this.setMenu();
  }

  private setMenu(): void {
    if (
      this.credentialsService &&
      this.credentialsService.credentials
    ) {
      switch (this.credentialsService.credentials.type) {
        case 'administrador':
          this.showButtons = true;
          this.routes = [
            {
              active: true,
              title: 'Inicio',
              route: '/home'
            },
            {
              active: true,
              title: 'Planes Conectimed',
              route: '/plans'
            },
            {
              active: true,
              title: 'Importar Posts',
              route: '/import-posts'
            },
            {
              active: true,
              title: 'Alertas Conectimed',
              route: '/surveys'
            },
            {
              active: false,
              title: 'Activar Usuarios',
              route: '/massive-user-activation'
            },
            {
              active: false,
              title: 'Asignar Contactos',
              route: '/assign-contacts'
            },
            {
              active: true,
              title: 'Importar Contactos',
              route: '/import'
            },
            {
              active: true,
              title: 'Pre-registros',
              route: '/preregistro'
            },
            {
              active: false,
              title: 'Importar Contactos(V2)',
              route: '/import-v2'
            },
            {
              active: true,
              title: 'Asignar contactos',
              route: '/assign-contacts-v2'
            },
            {
              active: true,
              title: 'Activar usuarios',
              route: '/activate-members'
            },
            {
              active: true,
              title: 'Activar representantes médicos',
              route: '/activate-rep'
            },
            {
              active: true,
              title: 'Herramientas',
              route: '/tools'
            },
            {
              active: false,
              title: 'Importar products',
              route: '/import-products'
            },
            {
              active: true,
              title: 'Cargar puntos',
              route: '/points'
            },
            {
              active: true,
              title: 'Chats',
              route: '/chat'
            },
            {
              active: true,
              title: 'Activar/Desactivar muestras médicas',
              route: '/disable-medical-sample'
            },
            {
              active: true,
              title: 'Generar URL para Chat',
              route: '/generate-url'
            },
            {
              active: true,
              title: 'Publicidad',
              route: '/advertising'
            },
            {
              active: true,
              title: 'Notificaciones segmentadas',
              route: '/push-notifcations'
            },
            {
              active: true,
              title: 'Compañias',
              route: '/companies'
            },
            {
              active: true,
              title: 'Foro Médico',
              route: '/medical-forum'
            },
            {
              active: true,
              title: 'Registrar código postal',
              route: '/zipcodes-suggestion'
            },
            {
              active: true,
              title: 'Click en Banners',
              route: '/banner-click'
            },
            {
              active: true,
              title: 'Información de posts',
              route: '/posts-info'
            },
            {
              active: true,
              title: 'Modificar cuenta de usuarios',
              route: '/users-actions'
            },
            {
              active: true,
              title: 'ClickMeeting Reportes',
              route: '/click-meeting-reports'
            },
            {
              active: true,
              title: 'Encuestas y exámenes',
              route: '/surveys-and-tests'
            },
            {
              active: true,
              title: 'Validación de información',
              route: '/validation-of-information'
            },
            {
              active: true,
              title: 'Posts y búsquedas',
              route: '/posts-review'
            }
          ];
          break;
        case 'editor':
          this.showButtons = false;
          this.routes = [
            {
              active: true,
              title: 'Inicio',
              route: '/home'
            },
            {
              active: true,
              title: 'Foro Médico',
              route: '/medical-forum'
            }
          ];
          break;
        case 'encuestas':
          this.showButtons = false;
          this.routes = [
            {
              active: true,
              title: 'Inicio',
              route: '/home'
            },
            {
              active: true,
              title: 'Encuestas y exámenes',
              route: '/surveys-and-tests'
            }
          ];
          break;
        case 'validador':
          this.showButtons = false;
          this.routes = [
            {
              active: true,
              title: 'Inicio',
              route: '/home'
            },
            {
              active: true,
              title: 'Validación de información',
              route: '/validation-of-information'
            }
          ];
          break;
        case 'validadorAdmin':
          this.showButtons = false;
          this.routes = [
            {
              active: true,
              title: 'Inicio',
              route: '/home'
            },
            {
              active: true,
              title: 'Validación de información',
              route: '/validation-of-information'
            }
          ];
          break;
      }
    }
  }

  async databaseModal() {
    const modal = await this.modalController.create({
      component: DatabaseModalComponent
    });
    await modal.present();
  }

  public async showProfileActions() {
    let createdActionSheet: any;
    const buttons: ActionSheetButton[] = [
      {
        text: 'Cerrar sesión',
        icon: this.platform.is('ios') ? undefined : 'log-out',
        role: 'destructive',
        handler: () => this.logout()
      },
      {
        text: 'Cancelar',
        icon: this.platform.is('ios') ? undefined : 'close',
        role: 'cancel'
      }
    ];

    // On Cordova platform language is set to the device language
    if (this.platform.is('cordova')) {
      buttons.splice(1, 1);
    }

    const actionSheetOptions: ActionSheetOptions = {
      header: this.username || undefined,
      buttons
    };

    createdActionSheet = await this.actionSheetController.create(actionSheetOptions);
    createdActionSheet.present();
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.email : null;
  }

  private async logout() {
    await this.authenticationService.logout();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  get isWeb(): boolean {
    return !this.platform.is('cordova');
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations


    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(title);
        }
      });
  }
}
