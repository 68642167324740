<ion-header *ngIf="viewType === 'modal'">
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center">
      Selecciona a los contactos
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="viewType === 'modal'">
  <div>
    <div
      *ngIf="
        searchProducts === false &&
          (memberType === 'representante-medico' || (memberType === 'medico' && searchMemberType === 'medico'));
        else medicoBlock
      "
    >
      <ion-grid fixed>
        <ion-row>
          <ion-col>
            <ion-searchbar
              class="ion-margin-top"
              type="text"
              debounce="1000"
              (ionChange)="doSearch($event)"
              (ionCancel)="clearSearch()"
              (ionClear)="clearSearch()"
              enterkeyhint="send"
              placeholder="Buscar"
              animated
              [disabled]="isLoading"
            >
            </ion-searchbar>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="12">
            <ion-button
              fill="solid"
              expand="block"
              color="tertiary"
              (click)="specialtiesFilter()"
              [disabled]="isLoading"
              class="ion-text-wrap"
            >
              <ion-icon name="school" slot="start"></ion-icon>
              Segmentar por especialidad
            </ion-button>
          </ion-col>
          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="12">
            <ion-button
              fill="solid"
              expand="block"
              color="tertiary"
              (click)="statesFilter()"
              [disabled]="isLoading"
              class="ion-text-wrap"
            >
              <ion-icon name="pin" slot="start"></ion-icon>
              Segmentar por zona geográfica
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button
              shape="round"
              color="primary"
              size="small"
              *ngFor="let item of filterData2; let i = index"
              (click)="removeEspecialtiesFilter()"
              [disabled]="isLoading"
            >
              {{ item.label }}
              <ion-icon slot="end" name="close"></ion-icon>
            </ion-button>
            <ion-button
              shape="round"
              color="primary"
              size="small"
              *ngFor="let item of filterData1; let i = index"
              (click)="removeStatesFilter()"
              [disabled]="isLoading"
            >
              {{ item.label }}
              <ion-icon slot="end" name="close"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div *ngIf="arrayDocs && arrayDocs[0]">
        <ion-grid fixed>
          <ion-row>
            <ion-col>
              <ion-list>
                <ion-item *ngFor="let item of arrayDocs; let i = index" lines="none" (click)="goChat(item.id)">
                  <ion-avatar slot="start">
                    <img
                      [src]="
                        item?.avatar?.list?.url ? item.avatar.list.url : 'assets/img/default-' + item.type + '.jpg'
                      "
                    />
                  </ion-avatar>
                  <ion-label slot="start">
                    <app-select-user-card [item]="item"> </app-select-user-card>
                  </ion-label>
                  <ion-checkbox
                    *ngIf="viewType === 'modal'"
                    slot="end"
                    [(ngModel)]="item.isChecked"
                    (click)="selectUser(item)"
                  ></ion-checkbox>
                </ion-item>
              </ion-list>
              <ion-button
                *ngIf="forward === true && isLoading === false"
                (click)="nextPage()"
                expand="block"
                fill="clear"
                shape="round"
              >
                Cargar mas resultados
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <ng-template #medicoBlock>
      <div *ngIf="searchProducts === false">
        <ion-grid fixed>
          <ion-row>
            <ion-col>
              <ion-searchbar
                class="ion-margin-top"
                type="text"
                debounce="1000"
                (ionChange)="doSearch($event)"
                (ionCancel)="clearSearch()"
                (ionClear)="clearSearch()"
                enterkeyhint="send"
                placeholder="Buscar"
                animated
                [disabled]="isLoading"
                *ngIf="searchMemberType"
              >
              </ion-searchbar>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div *ngIf="searchMemberType === 'representante-medico'">
          <ion-grid fixed>
            <ion-row>
              <ion-col>
                <ion-button
                  fill="solid"
                  expand="block"
                  color="tertiary"
                  (click)="companiesFilter()"
                  [disabled]="isLoading"
                  class="ion-text-wrap"
                >
                  <ion-icon name="briefcase" slot="start"></ion-icon>
                  Empresas
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-button
                  shape="round"
                  color="primary"
                  size="small"
                  *ngFor="let item of filterData3; let i = index"
                  (click)="removeCompaniesFilter()"
                  [disabled]="isLoading"
                >
                  {{ item.label }}
                  <ion-icon slot="end" name="close"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div *ngIf="arrayDocs && arrayDocs[0]">
          <ion-grid fixed>
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item *ngFor="let item of arrayDocs; let i = index" lines="none" (click)="goChat(item.id)">
                    <ion-avatar slot="start">
                      <img
                        [src]="
                          item?.avatar?.list?.url ? item.avatar.list.url : 'assets/img/default-' + item.type + '.jpg'
                        "
                      />
                    </ion-avatar>
                    <ion-label slot="start">
                      <app-select-user-card [item]="item"> </app-select-user-card>
                    </ion-label>
                    <ion-checkbox
                      *ngIf="viewType === 'modal' && multiselection === true"
                      slot="end"
                      [(ngModel)]="item.isChecked"
                      (click)="selectUser(item)"
                    ></ion-checkbox>
                    <ion-buttons slot="end" *ngIf="viewType === 'modal' && multiselection === false">
                      <ion-button (click)="goChatGroup(item)">
                        <ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                  </ion-item>
                </ion-list>
                <ion-button
                  *ngIf="forward === true && isLoading === false"
                  (click)="nextPage()"
                  expand="block"
                  fill="clear"
                  shape="round"
                >
                  Cargar mas resultados
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ng-template>
  </div>
</ion-content>

<ion-footer *ngIf="viewType === 'modal' && multiselection === true">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="6">
        <ion-button (click)="clear()" expand="block" color="danger" shape="outline" [disabled]="isLoading">
          <ion-icon slot="start" name="refresh"></ion-icon>
          Limpiar
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button
          (click)="goChatGroup()"
          expand="block"
          color="secondary"
          [disabled]="isLoading || !(selectedUsers.length > 0)"
        >
          <ion-icon slot="start" name="add"></ion-icon>
          Seleccionados ({{ selectedUsers.length }})
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
