import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../services/database.service';
import { ModalController, AlertController, ToastController, LoadingController } from '@ionic/angular';
import { FirebaseConstantsComponent } from '../firebase-constants/firebase-constants.component';
import { Logger } from '@app/core/logger.service';
const log = new Logger('DatabaseModalComponent');
@Component({
  selector: 'app-database-modal',
  templateUrl: './database-modal.component.html',
  styleUrls: ['./database-modal.component.scss']
})
export class DatabaseModalComponent implements OnInit {
  constructor(
    private databaseService: DatabaseService,
    public modalController: ModalController,
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {}

  async databaseAlert(code: string) {
    const alert = await this.alertController.create({
      header: 'Alerta!',
      message: 'Esta función genera cambios en la base de datos, ¿desea continuar?',
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel'
        },
        {
          text: 'Cotinuar',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Espera por favor...'
            });
            loading.present();
            if (code === 'assignType') {
              this.databaseService.productsAssignTypeAttribute();
            } else if (code === 'updateSearch') {
              this.databaseService.updateProductsSearch();
            } else if (code === 'updateBroadcastList') {
              try {
                await this.databaseService.updateBroadcastListUserOrder();
              } catch (error) {
                log.error(error);
              }
            } else if (code === 'updateAlphabeticalCounters') {
              try {
                await this.databaseService.updateAlphabeticalCounters();
              } catch (error) {
                log.error(error);
              }
            } else if (code === 'updateFilterCounters') {
              try {
                await this.databaseService.updateFilterCounters();
              } catch (error) {
                log.error(error);
              }
            } else if (code === 'updateUUIDUsers') {
              try {
                await this.databaseService.updateUUIDUsers();
              } catch (error) {
                log.error(error);
              }
            } else if (code === 'updateFriendsObject') {
              try {
                await this.databaseService.updateFriendsObject();
                await this.databaseService.updateFriendsCountersAlphabet();
                await this.databaseService.updateFriendsCountersFilters();
                await this.databaseService.updateMyFriendsCounter();
              } catch (error) {
                log.error(error);
              }
            } else if (code === 'fakeLikes') {
              this.fakeLikesAlert();
            } else if (code === 'productMuestraToNumber') {
              this.databaseService.productMuestraToNumber();
            } else if (code === 'delteContacts') {
              this.databaseService.delteContacts();
            } else if (code === 'allEmails') {
              this.databaseService.allEmails();
            } else if (code === 'chatFields') {
              await this.databaseService.updateChatsObject();
            } else if (code === 'liveChatsDoc') {
              await this.databaseService.updateLiveChatsDoc();
            } else if (code === 'allRepsToDefaultPlan') {
              await this.databaseService.allRepsToDefaultPlan();
            } else if (code === 'updateSearchV2') {
              await this.databaseService.updateSearchV2();
            } else if (code === 'updatePostsSearch') {
              await this.databaseService.updatePostsSearch();
            }
            loading.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  async fakeLikesAlert() {
    const alert = await this.alertController.create({
      header: 'Asignar Likes Genericos',
      message: 'Los likes asignados iran de 1 a: ',
      inputs: [
        {
          type: 'number',
          name: 'max'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Continuar',
          handler: data => {
            if (data.max < 1) {
              this.basicToast('El valor ingresado debe ser mayor de 1');
            } else {
              this.databaseService.asignFakeLikesToForoPosts(data.max);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async basicToast(text: string) {
    const toast = await this.toastController.create({ message: text, duration: 2000, position: 'middle' });
    await toast.present();
  }

  async openConstants() {
    const modal = await this.modalController.create({
      component: FirebaseConstantsComponent
    });
    await modal.present();
  }
}
