import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../assign-contacts/search.service';
import { IonInfiniteScroll, LoadingController } from '@ionic/angular';
import { CredentialsService } from '@app/core';
import { SpecialitesComponent } from '../assign-contacts/specialites/specialites.component';
import { LocationComponent } from '../assign-contacts/location/location.component';
import { SearchByCompanyComponent } from '../shared/search-by-company/search-by-company.component';
import { ModalController } from '@ionic/angular';
import { WpService } from '@app/shared/services/wp.service';
import { UserDetailViewComponent } from '@app/shared/user-detail-view/user-detail-view.component';
import { Logger } from '@app/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('Delete contacts');

@Component({
  selector: 'app-delete-contacts',
  templateUrl: './delete-contacts.component.html',
  styleUrls: ['./delete-contacts.component.scss']
})
export class DeleteContactsComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { read: {}, static: true }) infiniteScroll: IonInfiniteScroll;
  public posts: any[] = [];
  public memberType: string;
  private search: string;
  private search2: string;
  private perPage: number = 40;
  public members: any[];
  public members2: any[];
  public searchByLocation: boolean;
  public searchBySpecialty: boolean;
  filterData1: any[] = [];
  filterData2: any[] = [];
  filterData3: any[] = [];
  searchMemberType: string;
  newMembers: boolean = false;
  myMembers: number[];
  isLoading: boolean;
  private currentPage: number;
  private totalPages: number;
  private currentPage2: number;
  private totalPages2: number;
  representantes: number[] = [];
  allMedicos: any[] = [];
  allMedicosToDelete: any[] = [];
  allUniqueMedicosToDelete: any[] = [];
  uniqueMedicosToDelete: any[] = [];
  medicos: number[] = [];
  especialidades: string[] = [];
  estados: string[] = [];
  especialidadesObj: any[] = [];
  estadosObj: any[] = [];
  step: number = 1;
  allocationType: string = 'specialty';
  myForm!: FormGroup;
  connCreated: number = 0;
  connNoCreated: number = 0;
  notAcceptedUsers: any[] = [];
  relations: any[] = [];
  output: any[] = [];
  conexionesEliminadas: number[] = [];
  conexionesNoEliminadas: number[] = [];

  constructor(
    private credential: CredentialsService,
    private modalController: ModalController,
    private searchService: SearchService,
    private wpService: WpService,
    private loadingController: LoadingController,
    private formBuilder: FormBuilder,
    private utilities: UtilitiesService
  ) {
    this.memberType = 'medico';
    this.searchMemberType = this.memberType === 'medico' ? 'representante-medico' : 'medico';
    this.isLoading = true;
    this.initMembers().finally(() => {
      this.isLoading = false;
    });
    this.createForm();
  }

  ngOnInit() {}

  private createForm() {
    this.myForm = this.formBuilder.group({
      ids: ['', Validators.required]
    });
  }

  public async loadData(event: any): Promise<void> {
    if (this.currentPage < this.totalPages) {
      try {
        await this.initMembers(this.currentPage + 1);
      } catch (error) {
        log.error(error);
      }
    }
    try {
      event.target.complete();
    } catch (error) {
      log.error(error);
    }
  }

  public async loadData2(event: any): Promise<void> {
    if (this.currentPage2 < this.totalPages2) {
      try {
        await this.initMembers2(this.currentPage2 + 1);
      } catch (error) {
        log.error(error);
      }
    }
    try {
      event.target.complete();
    } catch (error) {
      log.error(error);
    }
  }

  async userDetail(id: number): Promise<void> {
    const modal = await this.modalController.create({
      component: UserDetailViewComponent,
      componentProps: { userid: id }
    });
    return await modal.present();
  }

  pushEstado(estado: string) {
    const index: number = this.estados.indexOf(estado);
    if (!(index > -1)) {
      this.estados.push(estado);
    }
  }

  pushEspecialidad(especialidad: string) {
    const index: number = this.especialidades.indexOf(especialidad);
    if (!(index > -1)) {
      this.especialidades.push(especialidad);
    }
  }

  async filter1() {
    this.search2 = '';
    const modal = await this.modalController.create({
      component: SpecialitesComponent,
      componentProps: { arrayData: this.filterData2 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      this.filterData2 = data.data.arrayData;
      this.medicos = [];
      await this.initMembers2();
      this.isLoading = false;
    });
    return await modal.present();
  }

  async filter2() {
    this.search2 = '';
    const modal = await this.modalController.create({
      component: LocationComponent,
      componentProps: { arrayData: this.filterData1 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      this.filterData1 = data.data.arrayData;
      this.medicos = [];
      await this.initMembers2();
      this.isLoading = false;
    });
    return await modal.present();
  }

  async filter3() {
    const modal = await this.modalController.create({
      component: SearchByCompanyComponent,
      componentProps: { arrayData: this.filterData3 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      this.filterData3 = data.data.arrayData;
      this.representantes = [];
      await this.initMembers();
      this.isLoading = false;
    });
    return await modal.present();
  }

  public async removeFilter1(index: number): Promise<void> {
    this.filterData1.splice(index, 1);
    this.isLoading = true;
    await this.initMembers2();
    this.isLoading = false;
  }

  public async removeFilter2(index: number): Promise<void> {
    this.filterData2.splice(index, 1);
    this.isLoading = true;
    await this.initMembers2();
    this.isLoading = false;
  }

  public async removeFilter3(index: number): Promise<void> {
    this.filterData3.splice(index, 1);
    this.isLoading = true;
    await this.initMembers();
    this.isLoading = false;
  }

  private async initMembers(page?: number): Promise<void> {
    this.isLoading = true;
    try {
      const response = await this.searchService.getMembersV2(
        this.searchMemberType,
        this.perPage,
        page,
        this.search,
        this.filterData1[0] && this.filterData1[0].id ? this.filterData1[0].id : null,
        this.filterData2[0] && this.filterData2[0].id ? this.filterData2[0].id : null,
        this.filterData3[0] && this.filterData3[0].id ? this.filterData3[0].id : null
      );
      this.currentPage = page ? page : 1;
      this.totalPages = Number(response.totalPages);
      let temp: any[] = Array.from(response.results).map(element => {
        return { color: '', ...element };
      });
      if (page && page > 1) {
        this.members = this.members.concat(temp);
      } else {
        this.members = temp;
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  private async initMembers2(page?: number): Promise<void> {
    this.isLoading = true;
    try {
      const response = await this.searchService.getMembersV2(
        'medico',
        this.perPage,
        page,
        this.search2,
        this.filterData1[0] && this.filterData1[0].id ? this.filterData1[0].id : null,
        this.filterData2[0] && this.filterData2[0].id ? this.filterData2[0].id : null
      );
      this.currentPage2 = page ? page : 1;
      this.totalPages2 = Number(response.totalPages);
      let temp: any[] = Array.from(response.results).map(element => {
        return { color: '', ...element };
      });
      if (page && page > 1) {
        this.members2 = this.members2.concat(temp);
      } else {
        this.members2 = temp;
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  private async getNewMembers(): Promise<void> {
    const response = await this.searchService.getNewMembers('', this.myMembers);
    this.members = response.posts;
  }

  private async getmyMembers(): Promise<void> {
    const response = await this.wpService.members(100, 1, '', 'alphabetical', String(this.credential.credentials.uid));
    this.myMembers = Array.from(response.body).map((e: any) => e.id);
  }

  public async newest() {
    this.isLoading = true;
    this.newMembers = !this.newMembers;
    this.members = [];
    if (this.newMembers == true) {
      await this.getmyMembers();
      await this.getNewMembers();
    } else {
      await this.initMembers();
    }
    this.isLoading = false;
  }

  public async getStringSearch(ev: any): Promise<void> {
    const val = ev.target.value;
    this.search = String(val);
    this.isLoading = true;
    if (this.search.length >= 3 || this.search.length == 0) {
      await this.initMembers();
    }
    this.isLoading = false;
  }

  public async getStringSearch2(ev: any): Promise<void> {
    const val = ev.target.value;
    this.search2 = String(val);
    this.isLoading = true;
    if (this.search2.length >= 3 || this.search2.length == 0) {
      await this.initMembers2();
    }
    this.isLoading = false;
  }

  public async clearSearch(): Promise<void> {
    this.search = '';
    this.isLoading = true;
    await this.initMembers();
    this.isLoading = false;
  }

  public async clearSearch2(): Promise<void> {
    this.search2 = '';
    this.isLoading = true;
    await this.initMembers2();
    this.isLoading = false;
  }

  selectUser(id: number) {
    const index: number = this.representantes.indexOf(id);
    if (index > -1) {
      this.representantes.splice(index, 1);
    } else {
      this.representantes.push(id);
    }
  }

  selectUser2(id: number) {
    const index: number = this.medicos.indexOf(id);
    if (index > -1) {
      this.medicos.splice(index, 1);
    } else {
      this.medicos.push(id);
    }
  }

  public async step1() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      let usersArray: {
        user: number;
        friends: any[];
      }[] = [];
      for (let i = 0; i < this.representantes.length; i++) {
        let tempArray: any[] = [];
        const id: string = String(this.representantes[i]);
        let membersInitial = await this.wpService.members(100, 1, 'medico', 'alphabetical', id);
        const totalPages: number = Number(membersInitial.headers.get('x-wp-totalpages'));
        for (let i = 0; i < totalPages; i++) {
          const members = await this.wpService.members(100, i, 'medico', 'alphabetical', id);
          Array.from(members.body).forEach(item => {
            tempArray.push(this.utilities.mapMemberData(item));
          });
        }
        usersArray.push({ user: Number(id), friends: tempArray });
      }
      this.allMedicos = usersArray;
      for (let i = 0; i < usersArray.length; i++) {
        for (let ii = 0; ii < usersArray[i].friends.length; ii++) {
          this.pushEspecialidad(String(usersArray[i].friends[ii].especialidad1));
          if (usersArray[i].friends[ii].especialidad2 !== '') {
            this.pushEspecialidad(String(usersArray[i].friends[ii].especialidad2));
          }
          this.pushEstado(String(usersArray[i].friends[ii].estado1));
          if (usersArray[i].friends[ii].estado2 !== '') {
            this.pushEstado(String(usersArray[i].friends[ii].estado2));
          }
        }
      }
      this.estados.sort(function(a, b) {
        var n = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
        return n === 0 && a !== b ? b.localeCompare(a) : n;
      });
      this.especialidades.sort(function(a, b) {
        var n = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
        return n === 0 && a !== b ? b.localeCompare(a) : n;
      });
    } catch (error) {
      log.error(error);
    }
    this.step = 2;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  public step2() {
    this.step = 3;
    this.estadosObj = this.estados.map(element => {
      return {
        label: element,
        title: element,
        isChecked: false
      };
    });
    this.especialidadesObj = this.especialidades.map(element => {
      return {
        label: element,
        title: element,
        isChecked: false
      };
    });
  }

  public step3_1() {
    this.estadosObj = this.estadosObj.filter(item => {
      if (item.isChecked == true) {
        return item;
      }
    });
    for (let i = 0; i < this.allMedicos.length; i++) {
      for (let ii = 0; ii < this.allMedicos[i].friends.length; ii++) {
        const arrayString: string[] = this.estadosObj.map(element => String(element.label));
        const index1: number = arrayString.indexOf(this.allMedicos[i].friends[ii].estado1);
        const index2: number = arrayString.indexOf(this.allMedicos[i].friends[ii].estado2);
        if (index1 > -1 || index2 > -1) {
          const data: any = this.allMedicos[i].friends[ii];
          const user: number = Number(this.allMedicos[i].user);
          this.allMedicosToDelete.push({ user, ...data });
        }
      }
    }
    this.allMedicosToDelete.forEach(element => {
      const index: number = this.allUniqueMedicosToDelete.map(e => e.id).indexOf(element.id);
      if (!(index > -1)) {
        this.allUniqueMedicosToDelete.push(element);
      }
    });
    this.step = 4;
  }

  public step3_2() {
    this.especialidadesObj = this.especialidadesObj.filter(item => {
      if (item.isChecked == true) {
        return item;
      }
    });
    for (let i = 0; i < this.allMedicos.length; i++) {
      for (let ii = 0; ii < this.allMedicos[i].friends.length; ii++) {
        const arrayString: string[] = this.especialidadesObj.map(element => String(element.label));
        const index1: number = arrayString.indexOf(this.allMedicos[i].friends[ii].especialidad1);
        const index2: number = arrayString.indexOf(this.allMedicos[i].friends[ii].especialidad2);
        if (index1 > -1 || index2 > -1) {
          const data: any = this.allMedicos[i].friends[ii];
          const user: number = Number(this.allMedicos[i].user);
          this.allMedicosToDelete.push({ user, ...data });
        }
      }
    }
    this.allMedicosToDelete.forEach(element => {
      const index: number = this.allUniqueMedicosToDelete.map(e => e.id).indexOf(element.id);
      if (!(index > -1)) {
        this.allUniqueMedicosToDelete.push(element);
      }
    });
    this.step = 4;
  }

  public selectUserLast(element: any) {
    const index: number = this.uniqueMedicosToDelete.map(e => e.id).indexOf(element.id);
    if (index > -1) {
      this.uniqueMedicosToDelete.splice(index, 1);
    } else {
      this.uniqueMedicosToDelete.push(element);
    }
  }

  filterLastMedicos() {
    let tempArray: any[] = [];
    this.allMedicosToDelete.forEach(element => {
      const index: number = this.uniqueMedicosToDelete.map(e => e.id).indexOf(element.id);
      if (index > -1) {
        tempArray.push(element);
      }
    });
    this.allMedicosToDelete = [];
    this.allMedicosToDelete = tempArray;
    this.step = 5;
  }

  public proofEstados(): boolean {
    const index: number = this.estadosObj.map(element => element.isChecked).indexOf(true);
    if (index > -1) {
      return true;
    }
    return false;
  }

  public proofEspecialidades(): boolean {
    const index: number = this.especialidadesObj.map(element => element.isChecked).indexOf(true);
    if (index > -1) {
      return true;
    }
    return false;
  }

  private stringArray(): string[] {
    var str = String(this.myForm.get('ids').value);
    var res = str.replace(/\n/gi, ',');
    res = res.replace(';', ',');
    res = res.replace(/ /g, ',');
    let idsString: string[] = res.split(',');
    let idsRes: string[];
    idsRes = idsString.map((item: string) => {
      return String(item);
    });
    idsRes = idsRes.filter(item => {
      const re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(item);
      if (typeof item === 'string' && re == true) {
        return item;
      }
      return undefined;
    });
    idsRes = idsRes.filter((valor, index, arreglo) => arreglo.indexOf(valor) === index);
    return idsRes;
  }

  public async onSubmit() {
    for (let i = 0; i < this.allMedicos.length; i++) {
      for (let ii = 0; ii < this.allMedicos[i].friends.length; ii++) {
        const arrayString: string[] = this.stringArray();
        const index: number = arrayString.indexOf(this.allMedicos[i].friends[ii].email);
        if (index > -1) {
          const data: any = this.allMedicos[i].friends[ii];
          const user: number = Number(this.allMedicos[i].user);
          this.allMedicosToDelete.push({ user, ...data });
        }
      }
    }
    this.step = 5;
  }

  async initConn() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      for (let i = 0; i < this.allMedicosToDelete.length; i++) {
        const isFriend = await this.wpService.isMyFriend(
          this.allMedicosToDelete[i].user,
          this.allMedicosToDelete[i].id
        );
        this.relations.push(isFriend.body.response);
      }
      let promises: Promise<any>[] = [];
      for (let i = 0; i < this.relations.length; i++) {
        const initiator1: number = this.relations[i].friend_id;
        const initiator2: number = this.relations[i].user_id;
        const relation: number = this.relations[i].relation_id;
        promises.push(
          this.wpService.deleteFriendShipV2(initiator1, relation, 'cancel').then((resp: any) => {
            let data = {
              relation: relation,
              deleted: false
            };
            if (resp.response === '1 Updated Rows') {
              data.deleted = true;
            }
            return data;
          })
        );
        promises.push(
          this.wpService.deleteFriendShipV2(initiator2, relation, 'cancel').then((resp: any) => {
            let data = {
              relation: relation,
              deleted: false
            };
            if (resp.response === '1 Updated Rows') {
              data.deleted = true;
            }
            return data;
          })
        );
      }
      const response = await Promise.all(promises);
      let responseOB: any[] = [];
      response.forEach(element => {
        const index: number = responseOB.map(e => e.relation).indexOf(element.relation);
        if (!(index > -1)) {
          responseOB.push({
            relation: element.relation,
            response: [element.deleted]
          });
        } else {
          responseOB[index].response.push(element.deleted);
        }
      });
      responseOB.forEach(element => {
        const index: number = element.response.indexOf(true);
        if (index > -1) {
          this.conexionesEliminadas.push(Number(element.relation));
        } else {
          this.conexionesNoEliminadas.push(Number(element.relation));
        }
      });
      this.step = 6;
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    await loadingOverlay.dismiss();
  }

  async getUsers(uid: number) {
    const dataUser = await this.wpService.member(uid);
    this.notAcceptedUsers.push(this.utilities.mapMemberData(dataUser.body));
  }

  async sendRequest(init_user: number, userid: number) {
    const response = await this.wpService.sendFriendShip(init_user, userid);
    if (response.success == true) {
      const id = String(String(response.response).substr(String(response.response).lastIndexOf(' ') + 1)).toLowerCase();
      return { success: true, id: id };
    }
    return response;
  }
}
