import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { SearchCompanyComponent } from '@app/shared/search-company/search-company.component';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { Subscription } from 'rxjs';
const log = new Logger('Desactivar muestra médica');

@Component({
  selector: 'app-disable-medical-sample',
  templateUrl: './disable-medical-sample.component.html',
  styleUrls: ['./disable-medical-sample.component.scss']
})
export class DisableMedicalSampleComponent implements OnInit {
  public products: any[] = [];
  public isLoading: boolean = false;
  public company: any;
  productsCollection: AngularFirestoreCollection<any>;
  mainSubscribe: Subscription;

  constructor(
    private afs: AngularFirestore,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private modalController: ModalController,
    private utilities: UtilitiesService
  ) {}

  ngOnInit() {}

  public async showEnableMSLoading(enable: boolean): Promise<void> {
    const loading = await this.loadingController.create({
      message: 'Espera por favor...'
    });
    loading.present();
    try {
      await this.disableOrEnableMedicalSample(enable);
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
    return;
  }

  private async disableOrEnableMedicalSample(enable: boolean): Promise<void> {
    try {
      const condition: number = enable === true ? 0 : 1;
      const updateData: number = enable === true ? 1 : 0;
      const resp = await this.afs
        .collection('products')
        .ref.where('se_cuenta_con_muestra', '==', condition)
        .get();
      let newArray: any[] = [];
      const originalArray: any[] = resp.docs.map(element => {
        const ref: DocumentReference = this.afs.collection('products').doc(element.id).ref;
        return ref;
      });
      const LONG_ARRAY = 500;
      for (let i = 0; i < originalArray.length; i += LONG_ARRAY) {
        let piece = originalArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      let count1: number = 1;
      for (const array of newArray) {
        const batch = this.afs.firestore.batch();
        for (const element of array) {
          batch.update(element, { se_cuenta_con_muestra: updateData, allowMedicalSample: enable });
          log.debug(`${count1}.- ${element}`);
        }
        await batch.commit();
        log.debug(`${count1++}.- batch commit`);
      }
      log.debug(`********* batch commit end *********`);
    } catch (error) {
      log.error(error);
    }
  }

  async medicalSample(enable: boolean): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Alerta!',
      message: 'Esta función genera cambios en la base de datos, ¿desea continuar?',
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel'
        },
        {
          text: 'Cotinuar',
          handler: () => {
            this.showEnableMSLoading(enable);
          }
        }
      ]
    });
    await alert.present();
    return;
  }

  async companiesFilter() {
    const modal = await this.modalController.create({
      component: SearchCompanyComponent
    });
    modal.onDidDismiss().then(async data => {
      if (data && data.data && data.data.arrayData && data.data.arrayData[0] && data.data.arrayData[0].id) {
        this.initProducts(data.data.arrayData[0].id);
        this.company = data.data.arrayData[0];
      }
    });
    return await modal.present();
  }

  private initProducts(companyId: string): void {
    this.productsCollection = this.afs.collection('products', ref =>
      ref.where('company', '==', this.afs.collection('companies').doc(companyId).ref).orderBy('nameStr', 'asc')
    );
    this.mainSubscribe = this.productsCollection.snapshotChanges().subscribe(
      snapshot => {
        if (snapshot.length <= 0) {
          this.products = [];
          this.noProductsAlert();
        } else {
          this.products = snapshot.map(item => {
            const data: any = item.payload.doc.data();
            const id: string = item.payload.doc.id;
            return { id, ...data };
          });
        }
      },
      error => {
        log.error(error);
      }
    );
    return;
  }

  private async noProductsAlert(): Promise<void> {
    try {
      const alert = await this.alertController.create({
        header: 'Alerta!',
        message: '¡El filtro utilizado no genero ningun resultado!',
        buttons: [
          {
            text: 'Enterado',
            role: 'cancel'
          }
        ]
      });
      await alert.present();
    } catch (error) {
      log.error(error);
    }
    return;
  }

  public async allowMedicalSample(allow: boolean, id: string): Promise<void> {
    try {
      this.allowMedicalSampleAlert(allow, id);
    } catch (error) {
      log.error(error);
    }
    return;
  }

  private async allowMedicalSampleAlert(allow: boolean, id: string) {
    const header: string = allow === true ? 'Activar muestra médica' : 'Desactivar muestra médica';
    const message: string =
      allow === true ? '¿Está seguro de activar la muestra médica?' : 'Está seguro de desactivar la muestra médica';
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Continuar',
          handler: () => {
            this.allowMedicalSampleHandler(allow, id);
          }
        }
      ]
    });
    await alert.present();
  }

  public async allowMedicalSampleHandler(allow: boolean, id: string): Promise<void> {
    this.isLoading = true;
    const loading = await this.loadingController.create({
      message: 'Espera por favor...'
    });
    loading.present();
    try {
      const updateData: number = allow === true ? 1 : 0;
      await this.afs
        .collection('products')
        .doc(id)
        .update({ se_cuenta_con_muestra: updateData, allowMedicalSample: allow });
      this.utilities.toast('¡Producto actualizado correctamente!');
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    loading.dismiss();
    return;
  }

  ionViewWillLeave() {
    try {
      if (this.mainSubscribe) {
        this.mainSubscribe.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
  }
}
