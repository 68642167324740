import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { CredentialsService } from '@app/core';
import { SpecialitesComponent } from '@app/shared/components/specialites/specialites.component';
import { LocationComponent } from '@app/shared/components/location/location.component';
import { SearchCompanyComponent } from '@app/shared/search-company/search-company.component';
import { ModalController } from '@ionic/angular';
import { Logger } from '@app/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { FirebaseService } from '@app/shared/services/firebase.service';
const log = new Logger('Search');
import { DEFAULT_USERS_CONNECTIONS } from '../../environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { read: {}, static: true }) infiniteScroll: IonInfiniteScroll;
  filterData1: any[] = [];
  filterData2: any[] = [];
  filterData3: any[] = [];
  filter1: string = '';
  filter2: string = '';
  filter3: string = '';
  finalFilter: string = '';
  default_user = false;
  selectedUsers: any[] = [];
  notUseStatusCondition: boolean;
  public orderBy: string = 'nameStr';
  public orderByDirection: any = 'asc';
  public arrayDocs: any[] = [];
  public forward: boolean = false;
  public searchMemberType: string;
  public isLoading: boolean = false;
  public memberType: string;
  public products: any[] = [];
  public searchProducts: boolean = false;
  public viewType: 'modal' | 'component' | 'chat' = 'component';
  public grupdID: string;
  private mainCollection: string = 'users';
  private startAfter: any;
  private subscription: Subscription;
  private searchStr: string;
  private perPage: number = 35;
  public multiselection: boolean = true;

  constructor(
    private credential: CredentialsService,
    private modalController: ModalController,
    private afs: AngularFirestore,
    private firebase: FirebaseService,
    private modalCtrl: ModalController
  ) {
    const index = DEFAULT_USERS_CONNECTIONS.map(e => e.email).indexOf(this.credential.credentials.email);
    this.default_user = index > -1 ? true : false;
    this.memberType = this.credential.credentials.type;
    if (this.memberType == 'representante-medico' && this.default_user === false) {
      this.searchMemberType = 'medico';
    }
    if (this.default_user === true) {
      this.memberType = 'medico';
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  resetSearch() {
    this.arrayDocs = [];
    this.filterData1 = [];
    this.filterData2 = [];
    this.filterData3 = [];
    this.filter1 = '';
    this.filter2 = '';
    this.filter3 = '';
    this.finalFilter = '';
    this.searchMemberType = undefined;
  }

  async removeEspecialtiesFilter(loadData?: boolean) {
    this.isLoading = true;
    this.filterData2 = [];
    const search: string = this.filterData2.map(e => String(e.id))[0];
    this.filter2 = search ? search : '';
    this.finalFilter = this.filter1 + this.filter2;
    if (!(loadData === false)) {
      if (this.finalFilter === '') {
        this.arrayDocs = [];
      } else {
        this.arrayDocs = [];
        await this.loadData();
      }
    }
    this.isLoading = false;
  }

  async removeStatesFilter(loadData?: boolean) {
    this.isLoading = true;
    this.filterData1 = [];
    const search: string = this.filterData1.map(e => String(e.id))[0];
    this.filter1 = search ? search : '';
    this.finalFilter = this.filter1 + this.filter2;
    if (!(loadData === false)) {
      if (this.finalFilter === '') {
        this.arrayDocs = [];
      } else {
        this.arrayDocs = [];
        await this.loadData();
      }
    }
    this.isLoading = false;
  }

  async removeCompaniesFilter(loadData?: boolean) {
    this.isLoading = true;
    this.filterData3 = [];
    const search: string = this.filterData3.map(e => String(e.id))[0];
    this.filter3 = search ? search : '';
    this.finalFilter = this.filter3;
    if (!(loadData === false)) {
      if (this.finalFilter === '') {
        this.arrayDocs = [];
      } else {
        this.arrayDocs = [];
        await this.loadData();
      }
    }
    this.isLoading = false;
  }

  async specialtiesFilter() {
    const modal = await this.modalController.create({
      component: SpecialitesComponent,
      componentProps: { arrayData: this.filterData2 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        this.filterData2 = Array.from(data.data.arrayData);
        const search: string = this.filterData2.map(e => String(e.id))[0];
        this.filter2 = search ? search : '';
        this.finalFilter = this.filter1 + this.filter2;
        if (this.finalFilter === '') {
          this.arrayDocs = [];
        } else {
          this.arrayDocs = [];
          await this.loadData();
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    return await modal.present();
  }

  async statesFilter() {
    const modal = await this.modalController.create({
      component: LocationComponent,
      componentProps: { arrayData: this.filterData1 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        this.filterData1 = Array.from(data.data.arrayData);
        const search: string = this.filterData1.map(e => String(e.id))[0];
        this.filter1 = search ? search : '';
        this.finalFilter = this.filter1 + this.filter2;
        if (this.finalFilter === '') {
          this.arrayDocs = [];
        } else {
          this.arrayDocs = [];
          await this.loadData();
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    return await modal.present();
  }

  async companiesFilter() {
    const modal = await this.modalController.create({
      component: SearchCompanyComponent,
      componentProps: { arrayData: this.filterData3 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        this.filterData3 = Array.from(data.data.arrayData);
        const search: string = this.filterData3.map(e => String(e.id))[0];
        this.filter3 = search ? search : '';
        this.finalFilter = this.filter3;
        if (this.finalFilter === '') {
          this.arrayDocs = [];
        } else {
          this.arrayDocs = [];
          await this.loadData();
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    return await modal.present();
  }

  async productos() {
    this.searchProducts = !this.searchProducts;
  }

  ngOnInit() {
    if (this.viewType === 'modal' && this.grupdID) {
      this.afs.collection('broadcast-list').doc(this.grupdID);
    }
  }

  goChat(id: any) {
    if (this.viewType === 'chat') {
      this.modalCtrl.dismiss({ users: [id] });
    }
  }

  async doSearch(ev: any) {
    try {
      this.search(ev);
    } catch (error) {
      log.error(error);
    }
  }

  ionViewDidLeave() {
    this.closeSubscriptions();
  }

  closeSubscriptions() {
    try {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
  }

  async search(ev: any) {
    this.isLoading = true;
    const searchStr: string = String(ev.target.value).toLocaleLowerCase();
    if (searchStr.length >= 3) {
      try {
        this.removeEspecialtiesFilter(false);
        this.removeStatesFilter(false);
        this.searchStr = searchStr;
        await this.initData();
      } catch (error) {
        log.error(error);
      }
    }
    this.isLoading = false;
  }

  async loadData() {
    this.searchStr = '';
    this.isLoading = true;
    try {
      await this.initData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async nextPage() {
    this.searchStr = '';
    this.isLoading = true;
    try {
      await this.initData(true);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  clearSearch() {
    this.searchStr = '';
    this.arrayDocs = [];
  }

  async initData(next?: boolean) {
    try {
      let collRef: CollectionReference = this.afs.collection(this.mainCollection).ref;
      let query: Query;
      if (this.notUseStatusCondition === true) {
        query = collRef.orderBy(this.orderBy, this.orderByDirection);
      } else {
        query = collRef.where('status', '==', 'active').orderBy(this.orderBy, this.orderByDirection);
      }
      if (this.searchMemberType) {
        query = query.where('type', '==', this.searchMemberType);
      }
      if (this.searchStr) {
        query = query.where('search', 'array-contains', this.searchStr);
      }
      if (this.finalFilter !== '') {
        query = query.where('filter-meta-data', 'array-contains', this.finalFilter);
      }
      if (this.startAfter && next === true) {
        query = query.startAfter(this.startAfter);
      }
      const snap = await query.limit(this.perPage).get();
      this.startAfter = snap.empty == false ? snap.docs[snap.docs.length - 1] : undefined;
      if (this.startAfter) {
        query = collRef.where('status', '==', 'active').orderBy(this.orderBy, this.orderByDirection);
        if (this.searchMemberType) {
          query = query.where('type', '==', this.searchMemberType);
        }
        if (this.searchStr) {
          query = query.where('search', 'array-contains', this.searchStr);
        }
        if (this.finalFilter !== '') {
          query = query.where('filter-meta-data', 'array-contains', this.finalFilter);
        }
        if (this.startAfter) {
          query = query.startAfter(this.startAfter);
        }
        const snap2 = await query.limit(this.perPage).get();
        this.forward = !snap2.empty;
      } else {
        this.forward = false;
      }
      let myArrayData: any[] = [];
      myArrayData = snap.docs.map(element => {
        const data: any = element.data();
        const id: string = element.id;
        if (data.type === 'medico') {
          data.metaData = this.firebase.getDoctorData(id);
        } else {
          data.metaData = this.firebase.getRepData(id);
        }
        data.isFriend = this.firebase.related(id);
        return { id, ...data };
      });
      const index: number = myArrayData.map(e => e.id).indexOf(this.credential.credentials.uid);
      if (index > -1) {
        myArrayData.splice(index, 1);
      }
      myArrayData = myArrayData.map(element => {
        const mapped = this.selectedUsers.map(e => e.id);
        const index = mapped.indexOf(element.id);
        if (index > -1) {
          element.isChecked = true;
        }
        return element;
      });
      if (!(next === true)) {
        this.arrayDocs = [];
      }
      myArrayData = myArrayData.map(element => {
        let data = element;
        var str: string = String(
          element && element.avatar && element.avatar.list && element.avatar.list.url ? element.avatar.list.url : ''
        );
        var n = str.search('www.gravatar.com');
        if (n > -1) {
          element.avatar = undefined;
        }
        return data;
      });
      this.arrayDocs = this.arrayDocs.concat(myArrayData);
    } catch (error) {
      log.error(error);
    }
  }

  goChatGroup(item?: any) {
    if (item) {
      this.selectUser(item);
    }
    this.modalCtrl.dismiss({ users: this.selectedUsers });
  }

  clear() {
    this.selectedUsers = [];
  }

  selectUser(item: any) {
    const index: number = this.selectedUsers.map(e => e.id).indexOf(item.id);
    if (index > -1) {
      this.selectedUsers.splice(index, 1);
    } else {
      this.selectedUsers.push(item);
    }
  }
}
