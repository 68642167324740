import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController, AlertController } from '@ionic/angular';
import { CompanyDetailModalComponent } from './company-detail-modal/company-detail-modal.component';
import { FirebaseService } from '../shared/services/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  companies: any[] = [];
  startAfter: any = null;
  onEnd: boolean = false;
  loading: boolean = true;
  search: string = '';
  realSearch: string = null;

  constructor(
    private firestore: AngularFirestore,
    private modalC: ModalController,
    private firebaseS: FirebaseService,
    private utilities: UtilitiesService,
    private storage: AngularFireStorage,
    private alertC: AlertController
  ) { }

  async ngOnInit() {
    this.getCompanies();
  }

  doSearch() {
    if (this.search && this.search !== '' && this.search.length >= 3) {
      this.realSearch = this.search.toLowerCase();
      this.search = '';
      this.companies = [];
      this.startAfter = null;
      this.getCompanies();
    }
  }

  cancelSearch() {
    if (this.realSearch) {
      this.startAfter = null;
      this.realSearch = null;
      this.companies = [];
      this.getCompanies();
    }
  }

  async getCompanies() {
    this.loading = true;
    let snaps;
    if (this.startAfter) {
      if (this.realSearch) {
        snaps = await this.firestore
          .collection('companies')
          .ref.limit(20)
          .startAfter(this.startAfter)
          .where('search', 'array-contains', this.realSearch)
          .get();
      } else {
        snaps = await this.firestore
          .collection('companies')
          .ref.limit(20)
          .startAfter(this.startAfter)
          .get();
      }
    } else {
      if (this.realSearch) {
        snaps = await this.firestore
          .collection('companies')
          .ref.limit(20)
          .where('search', 'array-contains', this.realSearch)
          .get();
      } else {
        snaps = await this.firestore
          .collection('companies')
          .ref.limit(20)
          .get();
      }
    }
    if (!snaps.empty) {
      this.startAfter = snaps.docs[snaps.docs.length - 1];
    } else {
      this.onEnd = true;
      return;
    }
    for (const doc of snaps.docs) {
      this.companies.push({ ...doc.data() as any, id: doc.id });
    }
    this.loading = false;
  }

  async openCompany(id?: string, justView?: boolean) {
    const modal = await this.modalC.create({
      component: CompanyDetailModalComponent,
      componentProps: { id: id, justView: justView }
    });
    await modal.present();
  }

  async deleteCompany(company: any) {
    const alert = await this.alertC.create({
      header: '¡Alerta!',
      message: `Seguro que desea borrar a la compañia: "${company.name}"`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Borrar',
          handler: async () => {
            const users = await this.firebaseS.getUsersFromCampanyId(company.id);
            if (users) {
              this.utilities.toast(
                `La empresa ${company.name} no se puede borrar porque hay representantes que pertenecen a esta`
              );
            } else {
              const ref = await this.firestore.doc(`companies/${company.id}`).ref;
              const data: any = (await ref.get()).data();
              if (data.logo) {
                this.storage.ref(`companies/${company.id}`).delete();
              }
              ref.delete();
              this.companies.splice(this.companies.indexOf(company), -1);
            }
          }
        }
      ]
    });
    await alert.present();
  }
}
